import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../../_services/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { IkeService } from 'src/app/ike-content/services/ike.service';


@Component({
  selector: 'app-solicitud-firma',
  templateUrl: './solicitud-firma.component.html',
  styleUrls: ['./solicitud-firma.component.css']
})
export class SolicitudFirmaComponent implements OnInit {
  
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;
  
  solicitud: any;
  modal_solicitud: any;
  analistas: any;
  asignarAnalista: FormGroup;
  Mensaje: any;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private service: ApiService,
    private router: Router,
    private _ikeService: IkeService
  ) {
    localStorage.getItem("detalles_solicitud") != null 
      ?
        localStorage.removeItem("detalles_solicitud")
      :
        ""
    this.getAnalista(this.contenidoDelModal)
  }

  ngOnInit(): void {
    this.asignarAnalista = this.formBuilder.group({
      solicitud: ['', Validators.required],
    });
    setTimeout(() => {
      this.getSolicitudes(this.contenidoDelModal)  
      this.getAnalista(this.contenidoDelModal)
      this.actualizarSolicitud(this.contenidoDelModal)
      }, 500);
  }
  
  get f() {
    return this.asignarAnalista.controls;
  }

  getSolicitudes(DefaultModalContent){
    this.service.filtroSolicitudes({"estatus": "Firma"}).subscribe(
      data => { this.solicitud = data },
      err => { console.log("Error al consumir peticion", err)
      this.Mensaje = "Error al consumir petición"
      this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' }) }
    )
  }

  getAnalista(DefaultModalContent){

    this.service.getAnalistas( { "estatus": 1 } ).subscribe(
      data => {
        this.analistas = data;
        this.getSolicitudes(this.contenidoDelModal)
      },
      err => { console.log("error al consumir el servicio", err)
      this.Mensaje = "Error al consumir servicio"
      this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' }) 
     }
    );
  }

  asignar(AsignarFormContent,data) {
    this.modal_solicitud = data;
    this.modalService.open(AsignarFormContent, { windowClass: 'animated fadeInDown' });
  }

  actualizarSolicitud(DefaultModalContent){

    const data = {
      "id_solicitud": this.modal_solicitud["item_id"],
      "id_abogado": parseInt(this.f.solicitud.value)
    }
    
    this.service.asignaAbogado(data).subscribe(
      data => {
        this.modalService.dismissAll()
      },
      err => {
        console.log("Error al consumir la peticion", err);
        this.Mensaje = "Error al consumir petición"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' }) 
      }
    )
  }
  createExcel() {
    this.solicitud.forEach(element => {
      if(!element['abogado']){
        element["abogado"] = {
          item_id: null,
          title: ""
        }
      }
    });
    const headers = ["Folio","Fecha Folio","Tipo","Cliente","Empresa","Comercial","Estatus","Analista Jurídico" ]
    const excelObj = {
      title:'Solicitudes',
      header: headers,
      data: this.solicitud,
      hasLawyer: true
    }
    this._ikeService.createExcel(excelObj)
  }
  
  detalles(e){
   
    if (e.type == 'click'){
      this.router.navigate(["/legal/tabs"])
      localStorage.setItem("detalles_solicitud",JSON.stringify(e.row));
    }
  }

}
