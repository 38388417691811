import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ApiService } from '../../../_services/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IkeService } from '../../services/ike.service';
@Component({
  selector: 'app-solicitudes-legal',
  templateUrl: './solicitudes-legal.component.html',
  styleUrls: ['./solicitudes-legal.component.css']
})
export class SolicitudesLegalComponent implements OnInit {

  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  
  @ViewChild('DefaultModelContent') contenidoDelModal;

  BasictableData: any;
  solicitud: any = {
    total: 0,
    filtered: 0,
    items: []
  };
  items: any;
  countries: any;
  modal_solicitud: any;
  analistas: any;
  asignarAnalista: FormGroup;
  Mensaje: any;

  page: any = 1;
  limit: any = 20;
  elements: any = 20;
  paginations: any = 0;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private service: ApiService,
    private router: Router,
    private _ikeService: IkeService
  ) {
    localStorage.getItem("detalles_solicitud")!=null ? localStorage.removeItem("detalles_solicitud") : "";
    
  }
    
  ngOnInit(): void {
    this.asignarAnalista = this.formBuilder.group({
      solicitud: ['', Validators.required],
    });
    setTimeout(() => {
      this.getAnalista(this.contenidoDelModal)
      }, 500);
   }

   detalles(e){
    localStorage.setItem("detalles_solicitud",JSON.stringify(e));
    this.router.navigate(["/legal/tabs"])
  }
  
  getTabledata() {
    this.countries = this.BasictableData.rows;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.asignarAnalista.controls;
  }

  actualizarSolicitud(DefaultModelContent){

    const data = {
      "id_solicitud": this.modal_solicitud["item_id"],
      "id_abogado": parseInt(this.f.solicitud.value)
    }
    
    this.service.asignaAbogado(data).subscribe(
      data => {
        this.modalService.dismissAll()
        window.location.reload()
      },
      err => {
        this.Mensaje = "Error en la petición"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })

      }
    )

  }

  getAnalista(DefaultModelContent){

    this.service.getAnalistas( { "estatus": 1 } ).subscribe(
      data => {
        this.analistas = data;
        this.getSolicitudes(this.contenidoDelModal)
      },
      err => {
        this.Mensaje = "Error al cosumir el servicio"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    );
  }

  getSolicitudes(DefaultModelContent){
    const offset = (this.page-1)*this.elements;
    this.service.solicitudes(this.elements,offset).subscribe(
      (data: any) =>{
        this.solicitud = data;
        this.items = data.items;
        this.paginations = data.filtered / this.elements;
      },
      err => {
        this.Mensaje = "Error al cosumir el servicio"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }

  changePage(event){
    this.getSolicitudes(this.contenidoDelModal);
  }
  
  changeSizePage(event){
    this.page = 1;
    this.limit = event.target.value;
    this.getSolicitudes(this.contenidoDelModal);
  }

  asignar(AsignarFormContent, data) {
    this.modal_solicitud = data;
    this.modalService.open(AsignarFormContent, { windowClass: 'animated fadeInDown' });
  }

  createExcel() {
    
    this.items.forEach(element => {
      if(!element['abogado']){
        element["abogado"] = {
          item_id: null,
          title: ""
        }
      }
    });
    
    const headers = ["Folio","Fecha Folio","Tipo","Cliente","Empresa","Comercial","Estatus","Analista Jurídico" ]
    const excelObj = {
      title:'Solicitudes',
      header: headers,
      data: this.items,
      hasLawyer: true
    }
    this._ikeService.createExcel(excelObj)
  }
}
