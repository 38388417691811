import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ValidatorFn, FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
@Component({
  selector: 'app-admin-tabs',
  templateUrl: './admin-tabs.component.html',
  styleUrls: ['./admin-tabs.component.css']
})
export class AdminTabsComponent implements OnInit {
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;
  submitRechazar=false;

  detalles_solicitud:any
  formularioRevision: FormGroup;
  formularioMotivo: FormGroup;
  editarFirmante: Boolean;
  motivo="";
  indice: any;
  firmCheck1 = false
  firmCheck2= false
  isUpload = false
  archivo: any;
  doc_final: any;
  conversacion: any;
  isSaved = false;
  estatusDocumentacion: any;
  comercial: any;
  archivoFirmado: any;
  linkDocusing: any;
  Mensaje: any;
  archivoFirma: any;
  agregarFirmantes: any;
  check = false;
  listaFirmantes: any;
  allowed: boolean
  user: any;
  newRevisionForm: FormGroup;
  submitted: any
  constructor(private formBuilder: FormBuilder, private service: ApiService, private router: Router, private modalService: NgbModal,
    private renderer: Renderer2,
    ) {
      this.doc_final = {
        "archivo":"",
        "nombre-archivo":"",
        "fecha": ""
      }
    localStorage.getItem("detalles_solicitud") == null ? router.navigate(["/administrador/dashboard"]) : "";
    this.detalles_solicitud = JSON.parse(localStorage.getItem("detalles_solicitud"));
    if(this.detalles_solicitud['motivo-rechazo']!=null && this.detalles_solicitud['motivo-rechazo']!=''){
      this.motivo=this.detalles_solicitud['motivo-rechazo'].replace(/<[^>]+>/g, '');
    }
  }

  ngOnInit(): void {
    this.user = localStorage.getItem('nombre')
    this.formularioRevision = this.formBuilder.group({
      archivo: ['', Validators.required ],
      comentario: ['', Validators.required],
    });
    this.newRevisionForm = this.formBuilder.group({
      comment: ['',Validators.required],
      file: ['',Validators.required]
    });
    this.formularioMotivo = this.formBuilder.group({
      motivo: ['',Validators.required]
    });      
    setTimeout(() => {

      if(
        (this.detalles_solicitud['tipo-de-solicitud-2']=='Contrato' && this.detalles_solicitud['accion']=='Aprobar Solicitud')
          || 
        (this.detalles_solicitud['tipo-de-solicitud-2']!='Contrato' && this.detalles_solicitud['abogado']!=undefined)){
        }
        this.getRevisiones(this.contenidoDelModal)
      }, 500);
  }
  showSidebar(event){}
  showCompose(event){
    const toggleIcon = document.getElementById('compose-sidebar');
    const toggleOverlay = document.getElementById('app-content-overlay');
    if (event.currentTarget.className === 'close close-icon' || 'app-content-overlay') {
      this.renderer.removeClass(toggleIcon, 'show');
      this.renderer.removeClass(toggleOverlay, 'show');
    }
  }
  showComposeSidebar(event){
    const toggleIcon = document.getElementById('compose-sidebar');
    const toggleSidebar = document.getElementById('sidebar-left');
    const toggleOverlay = document.getElementById('app-content-overlay');
    if (event.currentTarget.className === 'btn btn-info btn-glow btn-block my-2 compose-btn') {
      this.renderer.addClass(toggleIcon, 'show');
     this.renderer.removeClass(toggleSidebar, 'show');
      this.renderer.addClass(toggleOverlay, 'show');
    } else if (event.currentTarget.className === 'btn btn-danger btn-glow btn-block my-2 compose-btn show') {
      this.renderer.removeClass(toggleIcon, 'show');
     }
  }
  showEmailMenu(id, emailMenu){}
  validatorExt(arrayExt): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const result = this.validateExt(control.value, arrayExt)
      return result?{ext: {value: control.value}}:null
    }
  }
  get formMotivo() {
    return this.formularioMotivo.controls
  }
  get fNewComment() {
    return this.newRevisionForm.controls
  }
  validateExt(fileName, ext) {
    console.log(fileName)
    const availableExt = ext
    for(let ext of availableExt) {
      const validate = fileName.includes(ext)
      if (validate) {
        return  true;  
      }
    }
    return false
  }

  get f() {
    return this.formularioRevision.controls;
  }

  getRevisiones(DefaultModalContent){
    this.comercial = this.detalles_solicitud["usuario"]
    const data ={
      "id_solicitud": this.detalles_solicitud["item_id"]
    }
    this.service.getRevision(data).subscribe(
      (data: any) =>{
        this.conversacion = data;
        data.forEach(
          element => {
            if(element["archivo"]!=undefined){
              this.doc_final = element;
            }
        });
        if(this.doc_final==null || this.doc_final==undefined){
          this.doc_final = {
            "archivo":"",
            "nombre-archivo":"",
            "fecha": ""
          }
        }
        console.log(data)
        this.service.getFirmantes({"item_id": this.detalles_solicitud["item_id"]}).subscribe(
          (data: any) => {
            if(data.length>0){
              this.agregarFirmantes = data
              this.listaFirmantes = data
            }
          },
          err =>{
            this.Mensaje = "Error en la petición"
            this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
          }
        )
      },
      err => {
        console.log("error al consumir peticion", err);
        this.Mensaje = "Error al consumir servicio"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }
  consultaFirma(){
    this.detalles_solicitud["abogado-firma"] == "Si" 
      ? 
        ((<HTMLInputElement>document.getElementById("abogado")).checked = true)
      :
      ((<HTMLInputElement>document.getElementById("abogado")).checked = false)
    
      this.detalles_solicitud["comercial-firma"] == "Si"
      ? 
        ((<HTMLInputElement>document.getElementById("comercial")).checked = true)
      :
      ((<HTMLInputElement>document.getElementById("comercial")).checked = false)
  }

  consulta(){
    this.detalles_solicitud["comercial"] == "Si" 
      ? 
        ((<HTMLInputElement>document.getElementById("colorCheck1")).checked = true)
      :
      ((<HTMLInputElement>document.getElementById("colorCheck1")).checked = false)
    
      this.detalles_solicitud["abogado-2"] == "Si" 
      ? 
        ((<HTMLInputElement>document.getElementById("colorCheck2")).checked = true)
        
      :
      ((<HTMLInputElement>document.getElementById("colorCheck2")).checked = false);

    ((<HTMLInputElement>document.getElementById("colorCheck1")).disabled = true)

  }


  actualizar(option: any){
    if(option=="Rechazar" && !this.submitRechazar){
      this.submitRechazar=true;
          
    }
    let data={}
    if(option=="Aprobar"){
      this.submitRechazar=false;
      data = {
        "id_solicitud": this.detalles_solicitud["item_id"],
        "accion": option
      }

      this.service.aprobarDocumentacion(data).subscribe(
        data => {
          this.router.navigate(['/administrador/dashboard'])
        },
        err => {
          console.log("Error al consumir la peticion",err)
        }
      )
            
    }
    if(option=="Rechazar" && this.submitRechazar){
      if(this.formularioMotivo.get("motivo").value!=null && this.formularioMotivo.get("motivo").value!=''){

        data = {
          "id_solicitud": this.detalles_solicitud["item_id"],
          "accion": option,
          "motivo":this.formularioMotivo.get("motivo").value
        }
      
        this.service.aprobarDocumentacion(data).subscribe(
          data => {
            this.router.navigate(['/administrador/dashboard'])
          },
          err => {
            console.log("Error al consumir la peticion",err)
          }
        )

      }

    }
    
    if(option=="Cancelar" && this.submitRechazar){

      this.submitRechazar=false;

      
    }    
  }

  validar(DefaultModelContent){
    this.check = !this.check
    this.detalles_solicitud["abogado-2"] == "Si"
      ? 
        this.detalles_solicitud["abogado-2"] = "No"
      :
      this.detalles_solicitud["abogado-2"] = "Si"
    
      localStorage.setItem("detalles_solicitud",JSON.stringify(this.detalles_solicitud))
      const data ={
        "id_solicitud": this.detalles_solicitud["item_id"],
        "accion": this.detalles_solicitud["abogado-2"],
        "usuario": this.detalles_solicitud["comercial"]
      }
      console.log(data)
    this.service.aprobarBorrador(data).subscribe(
      data => {
        console.log("validar", data);
      },
      err => {
        this.detalles_solicitud["abogado-2"] == "Si"
      ? 
        this.detalles_solicitud["abogado-2"] = "No"
      :
      this.detalles_solicitud["abogado-2"] = "Si"

      localStorage.setItem("detalles_solicitud",JSON.stringify(this.detalles_solicitud))
        this.Mensaje = "Error en la petición"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.archivo = event.target.files[0]
    }else {
      this.archivo = null;
    }
  }

  CrearRevisiones(RevisionContent, LoaderEnviarContent) {
    this.submitted = true
    
    if( this.archivo== null ){
      //this.modalService.open(RevisionContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      const formData = new FormData();
      formData.append('mensaje', this.fNewComment.comment.value)
      formData.append('id_solicitud', this.detalles_solicitud["item_id"])
      this.service.crearRevision(formData).subscribe(
        data => {
          window.location.reload()
        },
        err => {
          console.log("Error al petir peticion", err);
        }
      )      
    } else {
      const formData = new FormData();
      formData.append('archivo', this.archivo);
      formData.append('mensaje', this.fNewComment.comment.value)
      formData.append('id_solicitud', this.detalles_solicitud["item_id"])
      this.service.crearRevision(formData).subscribe(
        data => {
          window.location.reload()
        },
        err => {
          console.log("Error al petir peticion", err);
        }
      )
    }
  }

    onFileFirma(event) {
      if (event.target.files.length > 0) {
        this.archivoFirma = event.target.files[0]
      }else {
        this.archivoFirma = null;
      }
    }

    AgregarFirmantes(FirmantesContent){
      this.modalService.open(FirmantesContent, { windowClass: 'animated fadeInDown', size: 'sm' })
    }

    guardarFirmantes(DefaultModelContent){
      this.service.createFirmantes(this.agregarFirmantes).subscribe(
        data => {
          this.listaFirmantes = data
          this.agregarFirmantes = data
        },
        err => {
          this.Mensaje = "Error en la petición"
          this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
        }
      )
    }

    uploadClick(DefaultModelContent): any {
      if(this.archivoFirma!=null){
        const data = new FormData()
        if(this.firmCheck1){
          data.append("id_item",this.detalles_solicitud["item_id"])
          data.append("contrato",this.archivoFirma)
          
          this.service.marcaAgua(data).subscribe(
            data => {
              this.cargarDatos(data);
            },
            err => {
              this.Mensaje = "Error en la petición"
              this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
            }
          )
        }else{
          data.append("id_item",this.detalles_solicitud["item_id"])
          data.append("file",this.archivoFirma);
          this.service.firmaElectronica(data).subscribe(
            data => {
              if(data["msg"]=="Invalid access token"){
                this.linkDocusing = data;
                console.log(this.linkDocusing)
              }
              else {
                this.cargarDatos(data);
              }
            },
            err => {
              this.Mensaje = "Error en la petición"
              this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
            }
          )
        }
      }else{
        this.Mensaje = "Falta documento"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    }

    Borrar(i){
      let arreglo = new Array();
      if(this.listaFirmantes==null){
        this.agregarFirmantes.forEach(element => {
          arreglo.push(element);
        });
        arreglo.splice(i,1);
        this.agregarFirmantes = arreglo;
        if(this.agregarFirmantes.length == 0){
          this.agregarFirmantes = null;
          this.listaFirmantes = null
        }
      }else{
        this.service.borrarFirmantes({"item_id":this.agregarFirmantes[i]["item_id"]}).subscribe(
          data => {
            this.agregarFirmantes.forEach(element => {
              arreglo.push(element);
            });
            arreglo.splice(i,1);
            this.agregarFirmantes = arreglo;
            this.listaFirmantes = this.agregarFirmantes
            if(this.agregarFirmantes.length == 0){
              this.agregarFirmantes = null;
              this.listaFirmantes = null
            }
          },
          err => {
            this.agregarFirmantes = this.listaFirmantes
            this.Mensaje = "Error en la petición"
          this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' })
          }
        )
      }
    }

    EditarFirmantes(EditarFirmantesContent,data, index){
      this.editarFirmante = true;
      this.modalService.open(EditarFirmantesContent, { windowClass: 'animated fadeInDown', size: 'sm' });
      setTimeout(() => {
        if((<HTMLInputElement>document.getElementById("nombreFirmante"))) {
          this.indice = index;
          (<HTMLInputElement>document.getElementById("nombreFirmante")).value = data["titulo"];
          (<HTMLInputElement>document.getElementById("correoFirmante")).value = data["correo"];
  
        }
      }, 500);
  
    }

    save () {
      if(this.listaFirmantes==null){
        if(this.editarFirmante==null || this.editarFirmante==false){
          let result = '';
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          const charactersLength = characters.length;
          for (let i = 0; i < 8; i++) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
          const data = {
              "titulo":(<HTMLInputElement>document.getElementById("nombreFirmante")).value,
              "correo": (<HTMLInputElement>document.getElementById("correoFirmante")).value,
              "id": "ike-"+result,
              "solicitud": this.detalles_solicitud["item_id"]
            }
          let arreglo = new Array();
          if(this.agregarFirmantes){
            this.agregarFirmantes.forEach(element => {
              arreglo.push(element)
            });
          }
          arreglo.push(data)
          this.agregarFirmantes= arreglo;
          this.isSaved = true
        }else{
          this.agregarFirmantes[this.indice]["titulo"] = (<HTMLInputElement>document.getElementById("nombreFirmante")).value
          this.agregarFirmantes[this.indice]["correo"] = (<HTMLInputElement>document.getElementById("correoFirmante")).value
        }
      }else{
        this.agregarFirmantes[this.indice]["titulo"] = (<HTMLInputElement>document.getElementById("nombreFirmante")).value
        this.agregarFirmantes[this.indice]["correo"] = (<HTMLInputElement>document.getElementById("correoFirmante")).value
        this.service.editarFirmantes(this.agregarFirmantes[this.indice]).subscribe(
          data => {
            this.listaFirmantes = this.agregarFirmantes
            console.log("editado")
          },
          err => {
            this.agregarFirmantes = this.listaFirmantes
            this.Mensaje = "Error en la petición"
            this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' })
          }
        )
      }
      this.modalService.dismissAll()
      this.editarFirmante = false;
    }

    validarFirma(DefaultModelContent){
      this.check = !this.check
      console.log("Es aqui")
      this.detalles_solicitud["abogado-firma"] == "Si"
        ? 
          this.detalles_solicitud["abogado-firma"] = "No"
        :
        this.detalles_solicitud["abogado-firma"] = "Si"
      
        localStorage.setItem("detalles_solicitud",JSON.stringify(this.detalles_solicitud))
        const data ={
          "id_item": this.detalles_solicitud["item_id"],
          "checkbox": this.detalles_solicitud["abogado-firma"]
        }
      this.service.checkAbogadoComercial(data).subscribe(
        data => {
        },
        err => {
          this.detalles_solicitud["abogado-firma"] == "Si"
        ? 
          this.detalles_solicitud["abogado-firma"] = "No"
        :
        this.detalles_solicitud["abogado-firma"] = "Si"
  
        localStorage.setItem("detalles_solicitud",JSON.stringify(this.detalles_solicitud))
          this.Mensaje = "Error en la petición"
          this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
        }
      )
    }

    onDocumentLast(event) {
      if (event.target.files.length > 0) {
        this.archivoFirmado = event.target.files[0]
      }else {
        this.archivoFirmado = null;
      }
    }

    subirDocumentoFinal(){
      if(this.archivoFirmado){
        const data = new FormData()
        data.append("item_id",this.detalles_solicitud["item_id"])
        data.append("file",this.archivoFirmado);
        this.service.subirDocumentoFinal(data).subscribe(
          data=> {
            this.cargarDatosFinales(data)
          },
          err => {
            console.log("Error en la peticion",err);
          }
        )
  
      }
    }

    resguardarDocumento(){
      this.service.reguardo({"item_id": this.detalles_solicitud["item_id"]}).subscribe(
        data => {
          console.log(data)
          this.router.navigate(["/abogado/dashboard"])
        },
        err => {
          this.Mensaje = "Error en la petición"
          this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' })
        }
      )
    }

    cargarDatos(data){
      const detalle = {
        "abogado": this.detalles_solicitud["abogado"],
        "abogado-2": this.detalles_solicitud["abogado-2"],
        "accion": this.detalles_solicitud["accion"],
        "acta-constitutiva": this.detalles_solicitud["acta-constitutiva"]!=undefined ? this.detalles_solicitud["acta-constitutiva"] : "",
        "ciiente-o-razon-social": this.detalles_solicitud["ciiente-o-razon-social"],
        "comercial": this.detalles_solicitud["comercial"],
        "comprobante-de-domicilio": this.detalles_solicitud["comprobante-de-domicilio"]!=undefined?this.detalles_solicitud["comprobante-de-domicilio"]:"",
        "con-documentacion": this.detalles_solicitud["con-documentacion"],
        "contraprestacion-2": this.detalles_solicitud["contraprestacion-2"],
        "estatus-2": this.detalles_solicitud["estatus-2"],
        "fecha": this.detalles_solicitud["fecha"],
        "folio": this.detalles_solicitud["folio"],
        "identificacion-oficial": this.detalles_solicitud["identificacion-oficial"]!=undefined?this.detalles_solicitud["identificacion-oficial"]:"",
        "instrucciones-de-solicitud": this.detalles_solicitud["instrucciones-de-solicitud"],
        "item_id": this.detalles_solicitud["item_id"],
        "pais": this.detalles_solicitud["pais"],
        "poder-del-rep-legal": this.detalles_solicitud["poder-del-rep-legal"]!=undefined?this.detalles_solicitud["poder-del-rep-legal"]:"",
        "porcentaje-de-comision": this.detalles_solicitud["porcentaje-de-comision"],
        "proceso": this.detalles_solicitud["proceso"],
        "rfc": this.detalles_solicitud["rfc"]!=undefined?this.detalles_solicitud["rfc"]:"",
        "terminos-y-condiciones": this.detalles_solicitud["terminos-y-condiciones"]!=undefined?this.detalles_solicitud["terminos-y-condiciones"]:"",
        "tipo": this.detalles_solicitud["tipo"]!=undefined?this.detalles_solicitud["tipo"]:"",
        "tipo-de-solicitud-2": this.detalles_solicitud["tipo-de-solicitud-2"],
        "usuario": this.detalles_solicitud["usuario"],
        "vigencia-2": this.detalles_solicitud["vigencia-2"],
        "nombre-archivo-firma": data["name"],
        "archivo-firma": data["url"],
        "abogado-firma": this.detalles_solicitud["abogado-firma"],
        "comercial-firma": this.detalles_solicitud["comercial-firma"]
      }
      localStorage.setItem("detalles_solicitud",JSON.stringify(detalle));
      this.detalles_solicitud = detalle;
    }
  
    cargarDatosFinales(data){
      const detalle = {
        "abogado": this.detalles_solicitud["abogado"],
        "abogado-2": this.detalles_solicitud["abogado-2"],
        "accion": this.detalles_solicitud["accion"],
        "acta-constitutiva": this.detalles_solicitud["acta-constitutiva"]!=undefined ? this.detalles_solicitud["acta-constitutiva"] : "",
        "ciiente-o-razon-social": this.detalles_solicitud["ciiente-o-razon-social"],
        "comercial": this.detalles_solicitud["comercial"],
        "comprobante-de-domicilio": this.detalles_solicitud["comprobante-de-domicilio"]!=undefined?this.detalles_solicitud["comprobante-de-domicilio"]:"",
        "con-documentacion": this.detalles_solicitud["con-documentacion"],
        "contraprestacion-2": this.detalles_solicitud["contraprestacion-2"],
        "estatus-2": this.detalles_solicitud["estatus-2"],
        "fecha": this.detalles_solicitud["fecha"],
        "folio": this.detalles_solicitud["folio"],
        "identificacion-oficial": this.detalles_solicitud["identificacion-oficial"]!=undefined?this.detalles_solicitud["identificacion-oficial"]:"",
        "instrucciones-de-solicitud": this.detalles_solicitud["instrucciones-de-solicitud"],
        "item_id": this.detalles_solicitud["item_id"],
        "pais": this.detalles_solicitud["pais"],
        "poder-del-rep-legal": this.detalles_solicitud["poder-del-rep-legal"]!=undefined?this.detalles_solicitud["poder-del-rep-legal"]:"",
        "porcentaje-de-comision": this.detalles_solicitud["porcentaje-de-comision"],
        "proceso": this.detalles_solicitud["proceso"],
        "rfc": this.detalles_solicitud["rfc"]!=undefined?this.detalles_solicitud["rfc"]:"",
        "terminos-y-condiciones": this.detalles_solicitud["terminos-y-condiciones"]!=undefined?this.detalles_solicitud["terminos-y-condiciones"]:"",
        "tipo": this.detalles_solicitud["tipo"]!=undefined?this.detalles_solicitud["tipo"]:"",
        "tipo-de-solicitud-2": this.detalles_solicitud["tipo-de-solicitud-2"],
        "usuario": this.detalles_solicitud["usuario"],
        "vigencia-2": this.detalles_solicitud["vigencia-2"],
        "nombre-archivo-firma": this.detalles_solicitud["nombre-archivo-firma"],
        "archivo-firma": this.detalles_solicitud["archivo-firma"],
        "nombre-archivo-final": data["name"],
        "archivo-final": data["url"],
        "fecha-archivo-final-2": data["fecha"],
        "abogado-firma": this.detalles_solicitud["abogado-firma"],
        "comercial-firma": this.detalles_solicitud["comercial-firma"]
      }
      localStorage.setItem("detalles_solicitud",JSON.stringify(detalle));
      this.detalles_solicitud = detalle;
    }

    removeSimbolo(contraprestacion){
      return contraprestacion.replace("$","")
    }
}
