<!-- main app container -->
<ngx-loading-bar [includeSpinner]='false' height='3px' color="#FF4961"></ngx-loading-bar>
<app-alert></app-alert>
<router-outlet *ngIf="showContent"></router-outlet>
<ngx-spinner 
class="overlay" 
bdColor = "rgba(0, 0, 0, 0.3)" 
size = "medium"
fullScreen = "true"
color = "#5098f7"
type = "ball-spin-clockwise">
<p style="color: white" > Cargando... </p>
</ngx-spinner>