import { Component, OnInit, ViewChild } from '@angular/core';
import { TableApiService } from 'src/app/_services/table-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/_services/api.service';
import { Router } from '@angular/router';
import { IkeService } from 'src/app/ike-content/services/ike.service';


@Component({
  selector: 'app-solicitud-proceso',
  templateUrl: './solicitud-proceso.component.html',
  styleUrls: ['./solicitud-proceso.component.css']
})
export class SolicitudProcesoComponent implements OnInit {
  
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;

  
  BasictableData: any;
  countries: any;
  solicitud: any;
  Mensaje: any;


  constructor(private _ikeService: IkeService, private tableApiservice: TableApiService, private modalService: NgbModal,private service: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.tableApiservice.getBasicTableData().subscribe(Response => {
      this.BasictableData = Response;
      this.getTabledata();
      });
      setTimeout(() => {
        this.getSolicitudes(this.contenidoDelModal) 
        }, 500);
   }

   getSolicitudes(DefaultModalContent){
    this.service.filtroSolicitudes({"estatus": "Draft"}).subscribe(
      data => { this.solicitud = data },
      err => { console.log("Error al consumir peticion", err)
      this.Mensaje = "Error al consumi petición"
      this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
     }
    )
  }
  createExcel() {

    this.solicitud.forEach(element => {
      if(!element['abogado']){
        element["abogado"] = {
          item_id: null,
          title: ""
        }
      }
    });
    
    const headers = ["Folio","Fecha Folio","Tipo","Cliente","Empresa","Comercial","Estatus","Analista Jurídico" ]
    const excelObj = {
      title:'Solicitudes',
      header: headers,
      data: this.solicitud,
      hasLawyer: true
    }
    this._ikeService.createExcel(excelObj)
  }
  getTabledata() {
    this.countries = this.BasictableData.rows;
  }

  asignar(AsignarFormContent) {
    this.modalService.open(AsignarFormContent, { windowClass: 'animated fadeInDown' });
  }

  detalles(e){
   
    if (e.type == 'click'){
      this.router.navigate(["/legal/tabs"])
      localStorage.setItem("detalles_solicitud",JSON.stringify(e.row));
    }
  }
  
}
