// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AutenticacionService  implements CanActivate{

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.isAuthenticated()) {

      return true;

    } else {

      this.router.navigate(['login']);

      return false;

    }

  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token!=null ? true : false;
  }

  public getToken(): string {
    return localStorage.getItem('token')!=null ? localStorage.getItem('token') : null;
  }
}