import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../_services/api.service'
import { ValidatorFn, FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';


  
  const spreadArr = (arr)=>arr.slice()

  @Component({
    selector: 'app-email-chat',
    templateUrl: './email-chat.component.html',
    styleUrls: ['./email-chat.component.css']
  })
  export class EmailChatComponent implements OnInit {
    user2: any = true;
    newRevisionForm: FormGroup;
    submitted: any

    constructor(
      private service: ApiService,
      private formBuilder: FormBuilder,
      private renderer: Renderer2,
      private modalService: NgbModal
    ) {}

    ngOnInit() {
      this.newRevisionForm = this.formBuilder.group({
        comment: new FormControl('',[Validators.required]),
        file: new FormControl('',[Validators.required, this.validatorExt(['pdf','doc','docx'])])
      })
    }

    showSidebar(event){}
    showCompose(event){
      const toggleIcon = document.getElementById('compose-sidebar');
      const toggleOverlay = document.getElementById('app-content-overlay');
      if (event.currentTarget.className === 'close close-icon' || 'app-content-overlay') {
        this.renderer.removeClass(toggleIcon, 'show');
        this.renderer.removeClass(toggleOverlay, 'show');
      }
    }
    showComposeSidebar(event){
      const toggleIcon = document.getElementById('compose-sidebar');
      const toggleSidebar = document.getElementById('sidebar-left');
      const toggleOverlay = document.getElementById('app-content-overlay');
      if (event.currentTarget.className === 'btn btn-info btn-glow btn-block my-2 compose-btn') {
        this.renderer.addClass(toggleIcon, 'show');
       this.renderer.removeClass(toggleSidebar, 'show');
        this.renderer.addClass(toggleOverlay, 'show');
      } else if (event.currentTarget.className === 'btn btn-danger btn-glow btn-block my-2 compose-btn show') {
        this.renderer.removeClass(toggleIcon, 'show');
       }
    }
    showEmailMenu(id, emailMenu){}
    validatorExt(arrayExt): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        const result = this.validateExt(control.value, arrayExt)
        return result?{ext: {value: control.value}}:null
      }
    }
    get fNewComment() {
      return this.newRevisionForm.controls
    }
    validateExt(fileName, ext) {
      console.log(fileName)
      const availableExt = ext
      for(let ext of availableExt) {
        const validate = fileName.includes(ext)
        if (validate) {
          return  true;  
        }
      }
      return false
    }

    onSubmit(){
      this.submitted = true
    }
    
  }
  
