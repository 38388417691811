import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { ApiService } from '../_services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { validateRol } from '../_services/role-service';


@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('SmallModelContent') modalError: ElementRef
  loginForm: FormGroup;
  rememberForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  componenteModal: any;
  Mensaje: any;
  validate: any;

  regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public authService: AuthService,
    private modalService: NgbModal,
    private service: ApiService

  ) {
    const sesion = validateRol();
    if(sesion){
      router.navigate([sesion]);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.regex)
      ]),
      password: new FormControl('', [
        Validators.required
      ])
    });
    this.rememberForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.regex)
        ])
      });
      this.isPageLoaded = true;
    
  }

  SmallModel(SmallModelContent) {
    this.componenteModal = SmallModelContent;
  }

  SmallMode(SmallModelContent) {
    this.componenteModal = SmallModelContent;
  }
  openDefaultModel(DefaultModelContent) {
    this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown' });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get remember() {
    return this.rememberForm.controls;
  }

  tryLogin() {
    console.log('log 71: ' ,this.loginForm)
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid ) {
      return;
    }

    this.service.login(
      {
        "email": this.f.email.value,
        "password": this.f.password.value
      }
    ).subscribe(
      data =>{
        if(data['msg'] == 'Contraseña Incorrecta' || data['msg'] == 'El Usuario No Existe' ){
        this.modalService.open(this.modalError, { windowClass: 'animated fadeInDown', size: 'sm' }); 
        }
        if (data["access_token"]!=undefined) {
          localStorage.setItem('token', data["access_token"]);
          localStorage.setItem('perfil', data["perfil"])
          localStorage.setItem('nombre', data["nombre"])
          localStorage.setItem('general', JSON.stringify(data))
          
          if(localStorage.getItem("perfil")=="Administrador"){
            this.router.navigate(["/administrador/dashboard"]);
          }else if(localStorage.getItem("perfil")=="Legal"){
            this.router.navigate(["/legal/dashboard"]);
          }else if(localStorage.getItem("perfil")=="Comercial"){
            this.router.navigate(["/comercial/dashboard"]);
          } else {
            this.router.navigate(["/abogado/dashboard"]);
          }
        } else {
          this.Mensaje = data["msg"]
        }
      },
      err => {
        this.submitted = false;
        this.Mensaje = "Error al cargar la información"
        this.modalService.open(this.componenteModal, { windowClass: 'animated fadeInDown', size: 'sm' });
      }
    );
  }

  recuperar(){
    this.validate = true
    console.log('log: 129', this.remember.email.errors)
    if (this.rememberForm.invalid) {
      return
    }
    if((<HTMLInputElement>document.getElementById("mail")).value){
      const data = {
        "email": (<HTMLInputElement>document.getElementById("mail")).value
      }
      this.service.recuperarContrasenia(data).subscribe(
        data => {
          console.log(data)
          this.modalService.dismissAll()
        },
        err => {
          console.log("Error en la peticion",err)
        }
      )
    }
  }
}
