<div class="app-content content">
    <div class="content-wrapper p-0">
        <div class="content-body px-1">
            <section id="minimal-statistics">
                <!-- Aqui inicia el div blanco(general)-->
                <div class="container-lg py-2">
                    <section>
                        <div class="col-12 d-flex justify-content-between container">
                            <article class="card col-8" >
                              <div class="mt-1" *ngIf="grafica!=null">
                                <ng-container mCardHeaderTitle >
                                 <div class="mrg text-center">
                                  <h5 class="card-title pt-2 font-weight-bold text-center text-uppercase">Estatus Solicitudes</h5>
                                 </div>
                                </ng-container>
                                <ng-container mCardBody>
                                  <div class="z">
                                    <canvas class="barchart lter" height="400"
                                      baseChart [datasets]="grafica.datasets"
                                      [labels]="grafica.labels"
                                      [options]="grafica.options"
                                      [colors]="grafica.color"
                                      [legend]="grafica.legend"
                                      [chartType]="grafica.type">
                                    </canvas>
                                  </div>
                                </ng-container>
                              </div>
                            </article>

                            <article id="top3" class="col-4 p-0">
                              <div class="card">
                                <h5 class="card-title pt-2 font-weight-bold text-center text-uppercase" style="margin-top: 1em;">Número de solicitudes</h5>
                                <div class="card-body d-flex flex-column top">
                                  <Label class="colortxt" style="margin-top: 1em;"><b>Analista Jurídico</b></Label>
                                  <div class="d-flex align-items-center top3__item pb-1 pt-1 border-bottom-primary space" *ngFor="let data of abogado">
                                    <div class="top3__copy">
                                      <p class="top3__name text-uppercase">{{data["nombre"]["title"]}}</p>
                                    </div>
                                    <p class="top3__profit font-weight-bold mr-1">{{data["cantidad"]}}</p>
                                  </div>
                                  <Label class="colortxt mt-1"><b>Comercial</b></Label>
                                  <div class="d-flex align-items-center top3__item pb-1 pt-1 border-bottom-primary space" *ngFor="let data of comercial">
                                    <div class="top3__copy">
                                      <p class="top3__name text-uppercase">{{data["nombre"]["title"]}}</p>
                                    </div>
                                    <p class="top3__profit font-weight-bold mr-1">{{data["cantidad"]}}</p>
                                  </div>
                                </div>
                              </div>
                            </article>
                        </div>
                      </section>
                </div>
            </section>
        </div>
    </div>
</div>



      <!-- Modal Faltan datos generales -->
      <ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
        <div class="modal-header">
          <h4 class="modal-title" >Algo salió mal, inténtalo nuevamente</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="modal-body icon-alert mt-3">
            <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60" >
        </div>
        <h4 class="icon-alert mb-3">{{Mensaje}}</h4>
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info"(click)="d('Close modal')">Aceptar</button>
        </div>
      </ng-template>
 