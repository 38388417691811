import { Injectable } from '@angular/core';


@Injectable()
export class AuthService {
  constructor() {}

  // Github login

  // Twitter login
  

  // Google login
  
}
