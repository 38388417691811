import { Component, OnInit, Renderer2, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-ike-layout',
  templateUrl: './ike-layout.component.html',
  styleUrls: ['./ike-layout.component.css']
})
export class IkeLayoutComponent implements OnInit {


  isSuccessClosed9= true;
  isDangerClosed9 = true;

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  }


}
