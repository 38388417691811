<!-- <div (window:resize)="onResize($event)"></div> -->

<app-navigation></app-navigation>

<router-outlet></router-outlet> 
 
<app-footer></app-footer>


<ngb-alert *ngIf="!isSuccessClosed9" [type]="''" (close)="isSuccessClosed9=true" class="alert-icon-left alert-arrow-left bg-success mb-2 spec" role="alert">
    <span class="alert-icon">
        <i class="la la-thumbs-o-up"></i>
    </span>
    {{ errorMsg }}
</ngb-alert>
