import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../_services/api.service';
import { Router } from '@angular/router';
import { IkeService } from '../../services/ike.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes-finalizadas-admin.component.html',
  styleUrls: ['./solicitudes-finalizadas-admin.component.css']
})
export class SolicitudesFinalizadasAdminComponent implements OnInit {
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;

  Mensaje:any;
  
  solicitudes: any;
  constructor(private service: ApiService,
    private router: Router,
    private _ikeService: IkeService,
    private modalService: NgbModal
  ) {
    localStorage.getItem("detalles_solicitud")!=null ? localStorage.removeItem("detalles_solicitud") : "";
  }

  ngOnInit(): void {

     setTimeout(() => {
    this.getSolicitudes(this.contenidoDelModal) 
    }, 500);
  }
  detalles(row){
    localStorage.setItem("detalles_solicitud",JSON.stringify(row));
    this.router.navigate(["/administrador/tabs"])
  }

  createExcel() {
    this.solicitudes.forEach(element => {
      if(!element['abogado']){
        element["abogado"] = {
          item_id: null,
          title: ""
        }
      }
    });
    const headers = ["Folio","Fecha Folio","Tipo","Cliente","Empresa","País","Comercial","Estatus","Analista Jurídico" ]
    const excelObj = {
      title:'Solicitudes',
      header: headers,
      data: this.solicitudes,
      hasLawyer: true
    }
    this._ikeService.createExcel(excelObj)
  }

  getSolicitudes(DefaultModalContent){
    this.service.filtroSolicitudes({"estatus": "Resguardo"}).subscribe(
      data => {
        this.solicitudes = data
      },
      err => {
        this.Mensaje = "Error al consumir servicio"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }

}

