import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { CurrencyPipe } from '@angular/common';
import moment from 'moment';
import 'moment/min/locales';

@Injectable({
  providedIn: 'root'
})
export class IkeService {
  workbook: any
  sheet: any
  data: any
  color_head = "004A81"
  headerStyle = {
    fill:  { 
      type: 'pattern',
      pattern: 'solid',
      fgColor:{
          argb:this.color_head
      },
      bgColor:{
          argb:this.color_head
      }
    },
    font:  {'size': 12,'color': {argb: 'FFFFFFFF'}, 'name': 'Calibri','family': 2, bold: true}
  }

  bodyStyleEven = {
    fill: {
      type: 'pattern',
      pattern:'darkVertical',
      fgColor:{argb:'FFFFFF'},
      bgColor:{argb:'FFFFFF'}
    },
    font:  {'size': 12, 'name': 'Calibri','family': 2}
  };
  bodyStyleOdd = {
    fill: {
      type: 'pattern',
      pattern:'darkVertical',
      fgColor:{argb:'FFFFFF'},
      bgColor:{argb:'FFFFFF'}
    },
    font:  {'size': 12, 'name': 'Calibri','family': 2}
  };
  perfil
  constructor(
    private _currency: CurrencyPipe
  ) { 
    this.perfil = localStorage.getItem('perfil').toLowerCase()
  }

  private createRow(data, hasLawyer=false) {

    const perfiles = {
      administrador: [
          data.folio,
          data.fecha,
          data['tipo-de-solicitud-2'],
          data['ciiente-o-razon-social']['title'],
          data.cliente,
          data.pais,
          data['usuario']['title'],
          data['estatus-2'],
          data.abogado.title
      ],
      comercial: [
          data.folio,
          data.fecha,
          data['tipo-de-solicitud-2'],
          data['ciiente-o-razon-social']['title'],
          data.cliente,
          data['usuario']['title'],
          data['estatus-2'],
          data.abogado.title
      ],
      legal: [
          data.folio,
          data.fecha,
          data['tipo-de-solicitud-2'],
          data['ciiente-o-razon-social']['title'],
          data.cliente,
          data['usuario']['title'],
          data['estatus-2'],
          data.abogado.title
      ],
      abogado: [
          data.folio,
          data.fecha,
          data['tipo-de-solicitud-2'],
          data['ciiente-o-razon-social']['title'],
          data.cliente,
          data['usuario']['title'],
          data['estatus-2'],
      ],
  }
    return perfiles[this.perfil]
  }
  createExcel({title: title, header: header, data: data, hasLawyer: hasLawyer}) {
    this.initSheet({title: title, header: header, data: data})
    this.addImage(title,hasLawyer)
  }
  createTable({title: title, header: header, data: data, isClient: isClient}){
    this.initSheet({title: title, header: header, data: data})
    this.addImage2(title, isClient)
  }

  createTableData({headers: headers, data: data}) {
   const tableObj = {
      header: headers,
      data: data
    }
    return tableObj
  }
  initSheet(obj) {
    this.workbook = new Workbook();
    this.sheet = this.workbook.addWorksheet("Tabla-Ike");
    this.data = this.createTableData({headers: obj.header, data: obj.data})
    //console.log("🚀 ~ file: ike.service.ts ~ line 81 ~ IkeService ~ initSheet ~ this.data ", this.data )
  }

  saveExcel(title) {
    this.workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      fs.saveAs(blob, `${title}.xlsx`);
    });
  }

  createHeader(len) {
    const end = (('A'.charCodeAt(0)) + (len-1))
    console.log("🚀 ~ file: ike.service.ts ~ line 104 ~ IkeService ~ createHeader ~ end", end)
    this.sheet.mergeCells("A1:A5");
    this.sheet.getCell("A1").fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor: {argb: '004A81'},
      bgColor:{
        argb:'004A81'
      }
    }
    this.sheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' };
    console.log('string:', `B1:${String.fromCharCode(end-1)}5`)
    this.sheet.mergeCells(`B1:${String.fromCharCode(end-1)}5`)
    this.sheet.getCell("B1").value = 'Control de Documentación Legal'
    this.sheet.getCell("B1").fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor: {argb:'004A81'},
      bgColor:{ argb:'004A81' }
    };
    this.sheet.getCell("B1").alignment = { vertical: 'middle', horizontal: 'center' };
    this.sheet.getCell("B1").font = {'size': len>6?26:18,'color': {argb: 'FFFFFFFF'}, 'name': 'Calibri','family': 2, bold: true}
    console.log('string:', `${String.fromCharCode(end)}1:${String.fromCharCode(end)}5`)
    this.sheet.mergeCells(`${String.fromCharCode(end
      )}1:${String.fromCharCode(end)}5`)
    this.sheet.getCell(`${String.fromCharCode(end)}1`).value = moment().locale('es').format(" D [de] MMMM [del] YYYY").toString();
    this.sheet.getCell(`${String.fromCharCode(end)}1`).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor: {argb: '004A81'},
      bgColor:{ argb:'004A81' }
    }
    this.sheet.getCell(`${String.fromCharCode(end)}1`).alignment = { vertical: 'middle', horizontal: 'center' };
    this.sheet.getCell(`${String.fromCharCode(end)}1`).font = {'size': 18,'color': {argb: 'FFFFFFFF'}, 'name': 'Calibri','family': 2, bold: true}
  }

  printTable( isClient ) {
    this.sheet.addRow([])
    const table = this.data
    console.log('2',table)
    this.sheet.addRow(table.header)
    if (isClient) {
      this.styleRowClient( {array: table.header, startCell: ['A',7], style: this.headerStyle})
    }
    else{
      this.styleRow( {array: table.header, startCell: ['A',7], style: this.headerStyle})
    }
    table.data.forEach ((row, i) => {
      this.sheet.addRow(row)
  //    if(i%2==0)
  //      {this.styleRow({array: row, startCell: ['A',8+i], style: this.bodyStyleEven})}
  //      else {this.styleRow({array: row, startCell: ['A',8+i], style: this.bodyStyleOdd})}
    })

  }

  printTable2( hasLawyer ) {
    this.sheet.addRow([])
    const table = this.data
    this.sheet.addRow(table.header)
    this.styleRow( {array: table.header, startCell: ['A',7], style: this.headerStyle})
    table.data.forEach ((row, i) => {
        const rowExcel = this.createRow(row,hasLawyer)
        this.sheet.addRow(rowExcel)
      if(i%2==0)
        {console.log('even',i)
          this.styleRow({array: row, startCell: ['A',8+i], style: this.bodyStyleEven})}
        else{this.styleRow({array: row, startCell: ['A',8+i], style: this.bodyStyleOdd})}
    })

  }

  addImage(title,hasLawyer){
    this.getBase64ImageFromUrl("/assets/custom/ike-excel.png").then(
      (result:any) => {  
      const imageId2 = this.workbook.addImage({
          base64: result,
          extension: 'png',
      });
      
      var size = { width: 250, height: 100 }
      var position = { col: 0, row: 0 }
              
      this.sheet.addImage(imageId2, {
          tl: position,
          ext: size
      });
      console.log('len:', this.data.header.length)
      this.createHeader(this.data.header.length)
      this.printTable2( hasLawyer)
      this.saveExcel(title)
      }
    )
  }

  addImage2(title, isClient){
    this.getBase64ImageFromUrl("/assets/custom/ike-excel.png").then(
      (result:any) => {  
      const imageId2 = this.workbook.addImage({
          base64: result,
          extension: 'png',
      });
      
      var size = { width: 220, height: 100 }
      var position = { col: 0, row: 0 }
              
      this.sheet.addImage(imageId2, {
          tl: position,
          ext: size
      });
      console.log('len:', this.data.header.length)
      this.createHeader(this.data.header.length)
      this.printTable( isClient )
      this.saveExcel(title)
      }
    )
  }


  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  styleRow( rowConfig ) {
    const len = rowConfig.array.length
    const row = rowConfig.startCell[1]
    const col = rowConfig.startCell[0].charCodeAt(0)
    const style = rowConfig.style
    for(let i=0; i<len; i++){
      const cell = `${String.fromCharCode(col+i)}${row}`
      const width = (i==0 || i== 5 || i==4)?20:33
      this.sheet.getColumn(String.fromCharCode(col+i)).width = width;
      this.sheet.getCell(cell).fill = style.fill
      this.sheet.getCell(cell).font = style.font
    }
  }

  styleRowClient( rowConfig ) {
    const len = rowConfig.array.length
    const row = rowConfig.startCell[1]
    const col = rowConfig.startCell[0].charCodeAt(0)
    const style = rowConfig.style
    for(let i=0; i<len; i++){
      const cell = `${String.fromCharCode(col+i)}${row}`
      const width = (i==0 || i== 1)?60:33
      this.sheet.getColumn(String.fromCharCode(col+i)).width = width;
      this.sheet.getCell(cell).fill = style.fill
      this.sheet.getCell(cell).font = style.font
    }
  }
}
