<div class="app-content content">
    <div class="content-wrapper p-0">
        <div class="content-body">
            <section id="minimal-statistics">
                <!-- Aqui inicia el div blanco(general)-->
                <div class="container-lg py-2 px-2">
                    <!-- Basic tabs start -->
                        <section id="basic-tabs-components">
                            <div class="row">
                            <div class="col-xl-12 col-lg-12">
                                <div class="card">
                                <div class="card-content col-12">
                                    <div class="card-body">
                                    <ngb-tabset>
                                        <!-- <div class="nav nav-tabs"> -->
                                        <ngb-tab >
                                          <ng-template ngbTabTitle>
                                            <span class="tab-title">Detalles</span>
                                          </ng-template>
                                        <ng-template ngbTabContent>
                                          <div class="content-body scroll height-450">
                                            <div class="">
                                               <div class="col-lg-12 col-md-12 border-grey"style="margin-top: 1.5em">
                                                  <label class="padding" ><strong>Folio: </strong>{{ detalles_solicitud['folio'] }}</label>
                                               </div>
                                            </div>
                                            <div class="">
                                               <div class="col-lg-12 col-md-12 border-grey">
                                                  <label class="padding" ><strong>Fecha Folio: </strong>{{ detalles_solicitud['fecha'] }}</label>
                                               </div>
                                            </div>
                                            <div class="">
                                               <div class="col-lg-12 col-md-12 border-grey">
                                                  <label class="padding" ><strong>Tipo: </strong>{{ detalles_solicitud['tipo-de-solicitud-2'] }}</label>
                                               </div>
                                            </div>
                                            <div class="">
                                             <div class=" col-lg-12 col-md-12 border-grey">
                                                <label class="padding" ><strong>Nombre de la Empresa: </strong>{{ detalles_solicitud['cliente'] }}</label>
                                             </div>
                                          </div>
                                            <div class="">
                                               <div class="col-lg-12 col-md-12 border-grey">
                                                  <label class="padding" ><strong>Razón Social: </strong>{{ detalles_solicitud['ciiente-o-razon-social']['title'] }}</label>
                                               </div>
                                            </div>
                                            <div class="">
                                               <div class="col-lg-12 col-md-12 border-grey">
                                                  <label class="padding"><strong>Contraprestación: </strong>{{ removeSimbolo(detalles_solicitud['contraprestacion-2'] |
                                                    currency:'CAD':'symbol-narrow':'.2-2')}} {{detalles_solicitud['contraprestacion-2-currency'] }}</label>
                                               </div>
                                            </div>
                                            <div class="">
                                               <div class="col-lg-12 col-md-12 border-grey">
                                                  <label class="padding" ><strong>Vigencia: </strong>{{ detalles_solicitud['vigencia-2'] }}</label>
                                               </div>
                                            </div>
                                            <div class="">
                                               <div class=" col-lg-12 col-md-12 border-grey">
                                                  <label class="padding" ><strong>Instrucciones: </strong>{{ detalles_solicitud['instrucciones-de-solicitud'] }}</label>
                                               </div>
                                            </div>
                                            <div *ngIf="detalles_solicitud['terminos-y-condiciones']!=undefined">
                                              <div class=" col-lg-12 col-md-12 border-grey">
                                                 <label class="padding" ><strong>Archivo de Términos y Condiciones: </strong>
                                                  <a target="_blank" download [href]="detalles_solicitud['terminos-y-condiciones']">
                                                    {{ detalles_solicitud['terminos-y-condiciones'].slice(57) }}
                                                  </a>
                                                </label>
                                              </div>
                                           </div>
                                          <div class="">
                                             <div class=" col-lg-12 col-md-12 border-grey">
                                                <label class="padding" ><strong>Revenue Share: </strong>{{ detalles_solicitud['revenue-share'] }}</label>
                                             </div>
                                          </div>
                                          <div *ngIf="detalles_solicitud['revenue-share'] == 'Si'">
                                             <div class="">
                                                <div class=" col-lg-12 col-md-12 border-grey">
                                                   <label class="padding" ><strong> Porcentaje de la Empresa: </strong>{{ detalles_solicitud['porcentaje-empresa'] }}</label>
                                                </div>
                                             </div>
                                             <div class="">
                                                <div class=" col-lg-12 col-md-12 border-grey">
                                                   <label class="padding" ><strong>Porcentaje del Cliente: </strong>{{ detalles_solicitud['porcentaje-revenue-share'] }}</label>
                                                </div>
                                             </div>
                                          </div>
                                        
                                         </div>

                                        </ng-template>
                                        </ngb-tab>
                                        <ngb-tab *ngIf="detalles_solicitud['tipo-de-solicitud-2']=='Contrato' || 
                                        (detalles_solicitud['tipo-de-solicitud-2']=='Convenio de Confidencialidad')">
                                        <ng-template ngbTabTitle>
                                          <span class="tab-title">Documentación</span>
                                        </ng-template>
                                        <ng-template ngbTabContent>
                                            <!--borderless table start-->
                                                <div class="row">
                                                    <div class="col-12" *blockUI="'borderlessTable'; message: 'Loading'">
                                                        <div class="card mt-2">
                                                            <div class="card-content">
                                                              <div class="table-responsive">
                                                                <table class="table table-borderless mb-0">
                                                                  <tbody>
                                                                    <tr class="border-grey">
                                                                      <td class="padding">Acta constitutiva<span class="red">*</span> : <u><a
                                                                            href="{{detalles_solicitud['acta-constitutiva']}}">
                                                                            {{detalles_solicitud['acta-constitutiva'].slice(57)}}</a></u> </td>
                                                                      <td class="doc padding"></td>
                                                                    </tr>
                                                                    <tr class="border-grey">
                                                                      <td class="padding">Poder del Rep. Legal<span class="red">*</span>: <u><a
                                                                            href="{{detalles_solicitud['poder-del-rep-legal']}}">
                                                                            {{detalles_solicitud['poder-del-rep-legal'].slice(57)}}</a></u></td>
                                                                      <td class="doc padding"></td>
                                                                    </tr>
                                                                    <tr class="border-grey">
                                                                      <td class="padding">INE/Pasaporte (identificación oficial)<span
                                                                          class="red">*</span>: <u><a
                                                                            href="{{detalles_solicitud['identificacion-oficial']}}">
                                                                            {{detalles_solicitud['identificacion-oficial'].slice(57)}}</a></u></td>
                                                                      <td class="doc padding"></td>
                                                                    </tr>
                                                                    <tr class="border-grey">
                                                                      <td class="padding">Comprobante de domicilio<span class="red">*</span>:
                                                                        <u><a href="{{detalles_solicitud['comprobante-de-domicilio']}}">
                                                                          {{detalles_solicitud['comprobante-de-domicilio'].slice(57)}}</a></u></td>
                                                                      <td class="doc padding"></td>
                                                                    </tr>
                                                                    <tr class="border-grey">
                                                                      <td class="padding">R.F.C.<span class="red">*</span>: <u><a
                                                                            href="{{detalles_solicitud['rfc']}}">{{detalles_solicitud['rfc'].slice(57)}}</a></u></td>
                                                                      <td class="doc padding"></td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Borderless table end -->
                                        </ng-template>
                                        </ngb-tab>
                                        <ngb-tab *ngIf="( detalles_solicitud['tipo-de-solicitud-2']=='Contrato' || 
                                        (detalles_solicitud['tipo-de-solicitud-2']=='Convenio de Confidencialidad') && 
                                                          detalles_solicitud['accion']=='Aprobar Solicitud')     || 
                                                          (detalles_solicitud['tipo-de-solicitud-2']!='Contrato' || 
                                                          (detalles_solicitud['tipo-de-solicitud-2']=='Convenio de Confidencialidad')&& 
                                                          detalles_solicitud['abogado']!=undefined)"
                                        >
                                        <ng-template ngbTabTitle>
                                          <span class="tab-title">Revisiones</span>
                                        </ng-template>
                                        <ng-template ngbTabContent>
                                          <section id="basic-form-layouts">
                                             <div class="row h-100 p-2 email-application">
                                                 <div class="w-100">
                                         <div class="app-content content w-100">
                                             <div class="sidebar-left" id="sidebar-left">
                                               <div class="sidebar">
                                                 <div class="sidebar-content email-app-sidebar d-flex">
                                                   <!-- sidebar close icon -->
                                                   <span class="sidebar-close-icon" (click)="showSidebar($event)">
                                                     <i class="ficon feather ft-x"></i>
                                                   </span>
                                                   <!-- sidebar close icon -->
                                                   <div class="email-app-menu">
                                                     <div class="sidebar-menu-list" fxFlex="auto">
                                                       <!-- sidebar menu  -->
                                                       <article class="col-12 px-0">
                                                        <div class="row">
                                                          <div class="col-12 p-0">
                                                            <div class="container d-flex flex-column mt-2">
                                                              <div class="d-flex flex-column justify-content-center text-center align-items-center ">
                                                                                                      
                                                                <div class="documento">
                                                                  <img src="../../../../assets/archivo-azul.svg" alt="documento " height="40" width="40" >
                                                                </div>
                                                                <div class="ml-1 col-12 px-0">
                                                                  <p class="mb-0" ><a download href="{{doc_final['archivo']}}">{{ doc_final['nombre-archivo']}}</a></p>
                                                                  <label class="mb-0">{{doc_final['fecha']}}</label>
                                                                </div>                                                         
                                                             </div>
                                                            </div>
                                                            <div class="list-group-messages mt-4">
                                                                <div class="documento">
                                                                 <p class="text-center text-black">VoBo </p>
                                                               </div>
                                                               <ul class="list-group">
                                                                   
                                                                   <li class="list-group-item">
                                                                    <input type="checkbox" id="colorCheck2"
                                                                    [ngClass]="detalles_solicitud['abogado-2']=='Si'?'check':''" (change)="validar(DefaultModelContent)"><label
                                                                    for="colorCheck2">Analista Jurídico{{consulta()}}</label>                           </li>
                                                                   <li class="list-group-item">
                                                                    <input type="checkbox" id="colorCheck1"><label
                                                                    for="colorCheck1">Comercial</label>                        </li>
                                                                 </ul><!-- aqui va el nuevo chat -->
                                                            </div>
                                                            
                                                          </div>
                                                        </div>
                                                      </article>
                                                     </div>
                                                   </div>
                                                 </div>
                                                 <!-- User new mail right area -->
                                                 <!--/ User Chat profile right area -->
                                               </div>
                                             </div>
                                             <div class="content-right">
                                               <div class="content-header row">
                                               </div>
                                               <div class="content-overlay"></div>
                                               <div class="content-wrapper">
                                                 <div class="content-body">
                                                   <!-- email app overlay -->
                                                   <div class="app-content-overlay" id="app-content-overlay" (click)="showCompose($event)"
                                                     (click)="showSidebar($event)"></div>
                                                   <div class="email-app-area">
                                                     <!-- Email list Area -->
                                                     <div class="email-app-list-wrapper">
                                                       <div class="email-app-list">
                                                         <div class="email-action">
                                                           <h3 class="text-center w-100">Historial de Revisiones</h3>
                                                         </div>
                                                         <div class="email-user-list scroll list-group">
                                                           <ul class="media-list">
                                                             <li *ngFor="let data of conversacion; index as i" [ngClass]="data['usuario']['title']!=user?'bg-gray':''" class="media align-items-center py-2">
                                                               <div [ngClass]="data['usuario']['title']!=user?'flex-row-reverse':''" class="media-body d-flex align-items-center">
                                                                 <div class="chat-item__avatar d-inline-block col-2">
                                                                   <div class="d-flex justify-content-center">
                                                                     <div class="avatar">
                                                                       <img src="../../../../assets/usuario-chat.svg" alt="avtar img holder">
                                                                     </div>
                                                                   </div>
                                                                   <div class=" text-center">
                                                                     <div class="mail-items">
                                                                       <span class="list-group-item-text">{{data['usuario']['title']}}</span>
                                                                     </div>
                                                                   </div>
                                                                   <div class=" text-center">
                                                                     <p class="list-group-item-text truncate mb-0">
                                                                         {{ data['fecha']}}
                                                                     </p>
                                                                   </div>
                                                                 </div>
                                                                 <div class="col-8 chat-item__comment d-inline-block">
                                                                     {{ data['titulo']}}
                                                                 </div>
                                                                 <div class="chat-item__file col-2 px-0">
                                                                   <div class="col-md-12 col-sd-12 d-flex justify-content-center">
                                                                     <img src="../../../../assets/archivo-azul.svg" alt="documento " height="45" width="43">
                                                                   </div>
                                                                   <div class="col-md-12 col-sd-12">
                                                                     <p><a download href="{{data['archivo']}}" class="text-info">{{data['nombre-archivo']}}</a></p>
                                                                   </div>
                                                                 </div>
                                                               </div>
                                                             </li>
                                                           </ul>
                                                           <!-- email user list end -->
                                           
                                                           <!-- no result when nothing to show on list -->
                                                           <div class="no-results">
                                                             <i class="ficon feather ft-info font-large-2"></i>
                                                             <h5>No Items Found</h5>
                                                           </div>
                                                         </div>
                                                       </div>
                                                     </div>
                                                   </div>
                                                 </div>
                                               </div>
                                             </div>
                                           </div>
                                         
                                                 </div>
                                             </div>
                                          </section>
                                         </ng-template>
                                        </ngb-tab>
                                        <ngb-tab *ngIf="detalles_solicitud['comercial']=='Si' && detalles_solicitud['abogado-2']=='Si'">
                                          <ng-template ngbTabTitle>
                                            <span class="tab-title">Firmas</span>
                                          </ng-template>
                                          <ng-template ngbTabContent>
                                            <section id="basic-form-layouts">
                                              <div class="row" >
                                              <div class="col-md-12 mt-2">
                                              <div class="borde">
                                              <label class="mt-1 mb-2 titulo"> Archivo a Firmar</label>
                                              <img  class="note1 mb-2  img-fluid rounded mx-auto d-block" src="../../../../assets/images/logo/firmado.svg" alt="documento" height="40" width="40" >
                                              <div class="row text-center custom-control custom-checkbox">
                                              <label class="doc"><a href="{{detalles_solicitud['archivo-firma']}}">{{detalles_solicitud['nombre-archivo-firma']}}</a></label>
                                              <div class="row justify-content-center pl-2 mb-2">
                                              <div class="d-inline-block mx-3">
                                              <input type="checkbox" id="comercial" disabled><label for="comercial">Comercial{{consultaFirma()}}</label>
                                              </div>
                                              <div class="d-inline-block mx-3">
                                              <input type="checkbox" id="abogado" disabled ><label for="abogado">Analista Jurídico{{consultaFirma()}}</label>
                                              </div>
                                              </div>
                                              </div>
                                              </div>
                                              </div>
                                              </div>       
                                              </section> 

                                            <!--<div *ngIf="detalles_solicitud['archivo-firma']!=undefined" class="mt-1 col-12 border size firm">
                                              <label class="mt-1 mb-2 titulo"> Archivo a firmar</label>
                                              <img  class="note1 mb-2 img-fluid rounded mx-auto d-block" src="../../../../assets/images/logo/firmado.svg" alt="documento " height="40" width="40" >
                                              <div class="row documento mt-1">
                                                <label class="doc"><a href="{{detalles_solicitud['archivo-firma']}}">{{detalles_solicitud['nombre-archivo-firma']}}</a></label>
                                              </div>

                                              <div class="row justify-content-center pl-2 mb-3 ">
                                                <div class="d-inline-block mx-3 ">
                                                  <input disabled type="checkbox" class="" 
                                                  name="comercial" id="comercial">
                                                  <label class="" for="comercial">Director Comercial{{consultaFirma()}}</label>

                                                </div>
                                                <div class="d-inline-block mx-3">
                                                  <input
                                                    disabled
                                                    type="checkbox" 
                                                    class="" 
                                                    id="abogado" 
                                                    (change)="validarFirma()">
                                                  <label class="" for="abogado">Analista Jurídico{{consultaFirma()}}</label>
                                                
                                              </div>
                                            </div>
                                          </div>-->
                                          </ng-template>
                                      </ngb-tab>

                                      <ngb-tab *ngIf="detalles_solicitud['comercial-firma']=='Si' && detalles_solicitud['abogado-firma']=='Si'">
                                        <ng-template ngbTabTitle> <span class="tab-title">Documentación Final</span></ng-template>
                                        <ng-template ngbTabContent>
                                        <!-- Basic form layout section start -->
                                        <section id="basic-form-layouts">
                                          <div class="row" >
                                            <div class="col-md-12 mt-2" *blockUI="'projectInfo'; message: 'Loading'">
                                              <ng-container mCardBody>
                                                <div class="borde size">
                                                    <label class="mt-1 mb-2 titulo"> Archivo Firmado</label>
                                                      <img  class="note1 mb-2  img-fluid rounded mx-auto d-block" src="../../../../assets/images/logo/final.svg" alt="documento " height="40" width="40">
                                                    <label class="informacion ">{{detalles_solicitud['fecha-archivo-final-2']}}</label>
                                                  <div class="row documento mb-1">
                                                    <label class="informacion doc"><a href="{{detalles_solicitud['archivo-final']}}">{{detalles_solicitud['nombre-archivo-final']}}</a></label>
                                                  </div>
                                                </div>
                                              </ng-container>
                                            </div>
                                          </div>       
                                        </section>
                                        </ng-template>
                                        </ngb-tab>

                                      <!--
                                        <ngb-tab *ngIf="detalles_solicitud['comercial-firma']=='Si' && detalles_solicitud['abogado-firma']=='Si'">
                                        <ng-template ngbTabTitle><span class="tab-title">Documentación Final</span></ng-template>
                                        <ng-template ngbTabContent>
                                        
                                          <div class="border mt-2">
                                            <label class="mt-1 mb-2 titulo"> Archivo Firmado</label>
                                            <img  class="note1 mb-2 img-fluid rounded mx-auto d-block" src="../../../../assets/images/logo/final.svg" alt="documento " height="40" width="40" >
                                            <div class="row documento mt-1">
                                              <label class="doc"><a href="{{detalles_solicitud['archivo-final']}}">{{detalles_solicitud['nombre-archivo-final']}}</a></label>
                                            </div>
  
                                            <div class="row justify-content-center pl-2 mb-1 ">
                                            <div class="d-inline-block mx-3 ">
                                                <input disabled type="checkbox" class="" 
                                                name="comercial" id="comercial">
                                                <label class="" for="comercial">Director Comercial{{consultaFirma()}}</label>
  
                                              </div>
                                              <div class="d-inline-block mx-3">
                                                <input
                                                  disabled
                                                  type="checkbox" 
                                                  class="" 
                                                  id="abogado" 
                                                  (change)="validarFirma()">
                                                <label class="" for="abogado">Analista Jurídico{{consultaFirma()}}</label>
                                              
                                              </div>
                                            </div> 
                                          
                                          
                                            </div>
                                          </div>
                                          
                                        </ng-template>
                                        
                                        </ngb-tab>
                                        *--> 
                                       
                                    </ngb-tabset>
                                    </div>
                                </div>
                                </div>
                                <div class="alert alert-info text-white text-center text-align-items-center" *ngIf="submitted">
                                  <i class="la la-coffee"></i> Cargando Archivos, este proceso puede tardar varios minutos... <i class="la la-coffee"></i>
                              </div>
                            </div>

                            </div>
                        </section>
                </div>
            </section>
        </div>
    </div>
</div>


    <!-- Modal errores -->
    <ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
      <div class="modal-header">
        <h4 class="modal-title" >Algo salió mal, inténtalo nuevamente</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="modal-body icon-alert mt-3">
          <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60" >
      </div>
      <h4 class="icon-alert mb-3">{{Mensaje}}</h4>
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info"(click)="d('Close modal')">Aceptar</button>
      </div>
    </ng-template>

