export const validateRol = () =>{
    const token = localStorage.getItem("token");
    const perfil = localStorage.getItem("perfil");

    switch (perfil){
        case "Administrador":
            if (token){
                return "/administrador/solicitud"
            } else {
                return null
            }
        case "Legal":
            if (token){
                return "/legal/solicitudes"
            } else {
                return null
            }
        case "Comercial":
            if (token){
                return "/comercial/dashboard"
            } else {
                return null
            }
        case "Abogado":
            if (token){
                return "/abogado/dashboard"
            } else {
                return null
            }
    }
    return null
}