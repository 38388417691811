import { Component, OnInit, ViewChild } from '@angular/core';
import * as chartsData from '../../../content/charts-maps/chartjs/charts/chartjs';
import { ApiService } from '../../../_services/api.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-dashboard-legal',
  templateUrl: './dashboard-legal.component.html',
  styleUrls: ['./dashboard-legal.component.css']
})
export class DashboardLegalComponent implements OnInit {

  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;
  
  grafica: any;
  comercial: any;
  abogado: any;
  Mensaje: any;

  constructor(private service: ApiService,private modalService: NgbModal,) {

  }

  
  getData(DefaultModelContent){
    this.service.dashboard().subscribe(
      data => {
        
        this.comercial = data["usuarios-comercial"]
        this.abogado = data["usuarios-abogados"]
        this.grafica ={
          
          type: 'bar',
          labels: data["label"] ,
          legend: false,
          datasets: [
            {
              data: data["estatus"],
              backgroundColor: data["color"],
              borderColor: data["color"],
              borderWidth: 1
            }
          ],
          color:[{
            pointBackgroundColor: data["color"],
            pointBorderColor: data["color"],
            pointHoverBackgroundColor: data["color"],
            pointHoverBorderColor: data["color"],
          }],
          options: {
            maintainAspectRatio: false,
            responsive: true,
            scaleShowVerticalLines: false,
            xAxes: [{categoryPercentage: 1}],
            'scales': {
              'yAxes': [
                  {
                  'ticks': {
                      'suggestedMin': 0,
                  },
                  },
              ],
              'xAxes': [
                {
                'ticks': {
                    'suggestedMin': 0,
                    'maxRotation': 45,
                    'minRotation': 45
                },
                },
            ],
            }
          }
        }

        console.log(this.grafica)

        
      },
      err => {
        console.log("Error al pedir peticion",err)
        this.Mensaje = "Error en la petición"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )    
  }
    

  ngOnInit(): void {
    setTimeout(() => {
      this.getData(this.contenidoDelModal)
      }, 500);
  }

}
