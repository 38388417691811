import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IkeService } from 'src/app/ike-content/services/ike.service';

@Component({
  selector: 'app-solicitud-proceso-admin',
  templateUrl: './solicitud-proceso-admin.component.html',
  styleUrls: ['./solicitud-proceso-admin.component.css']
})
export class SolicitudProcesoAdminComponent implements OnInit {
  solicitud: any;
  modal_solicitud: any;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private _ikeService: IkeService
  ) { }

  ngOnInit(): void {
  }
  detalles(e){
    localStorage.setItem("detalles_solicitud",JSON.stringify(e));
    this.router.navigate(["/administrador/tabs"])
  }
  createExcel() {
    this.solicitud.forEach(element => {
      if(!element['abogado']){
        element["abogado"] = {
          item_id: null,
          title: ""
        }
      }
    });
    const headers = ["Folio","Fecha Folio","Tipo","Cliente","Empresa","País","Comercial","Estatus","Analista Jurídico" ]
    const excelObj = {
      title:'Solicitudes',
      header: headers,
      data: this.solicitud,
      hasLawyer: true
    }
    this._ikeService.createExcel(excelObj)
  }
  asignar(AsignarFormContent, data) {
    this.modal_solicitud = data;
    this.modalService.open(AsignarFormContent, { windowClass: 'animated fadeInDown' });
  }
}
