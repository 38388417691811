import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { AutenticacionService } from './_services/autenticacion'
import { LoginComponent } from './login';
import { DashboardAbogadoComponent } from './ike-content/abogado/dashboard-abogado/dashboard-abogado.component'
import { IkeLayoutComponent } from './_layout/ike-layout/ike-layout.component';
import { TabsAbogadoComponent } from './ike-content/abogado/tabs-abogado/tabs-abogado.component';

//PERFIL COMERCIAL
import { DashboardComercialComponent } from './ike-content/comercial/dashboard/dashboard.component'
import { TabComercialComponent } from './ike-content/comercial/tab/tab.component'
import { SolicitudeComercialComponent } from './ike-content/comercial/solicitude/solicitude.component'

//PERFIL DIR. LEGAL
import { DashboardLegalComponent } from './ike-content/dirlegal/dashboard-legal/dashboard-legal.component';
import { SolicitudesLegalComponent } from './ike-content/dirlegal/solicitudes-legal/solicitudes-legal.component';
import { SolicitudProcesoComponent } from './ike-content/dirlegal/solicitudes-pendientes/solicitud-proceso/solicitud-proceso.component';
import { SolicitudFirmaComponent } from './ike-content/dirlegal/solicitudes-pendientes/solicitud-firma/solicitud-firma.component';
import { SolicitudDraftComponent } from './ike-content/dirlegal/solicitudes-pendientes/solicitud-draft/solicitud-draft.component';
import { SolicitudesFinalizadasComponent } from './ike-content/dirlegal/solicitudes-finalizadas/solicitudes-finalizadas.component';
import { CatalogoAnalistasComponent } from './ike-content/dirlegal/catalogo-analistas/catalogo-analistas.component';
import { TabsLegalComponent } from './ike-content/dirlegal/tabs-legal/tabs-legal.component';

//PERFIL ADMINISTRADOR  
import { SolicitudesAdminComponent } from './ike-content/administrador/solicitudes-admin/solicitudes-admin.component';
import { ClientesComponent } from './ike-content/administrador/catalogos/clientes/clientes.component';
import { AnalistasComponent } from './ike-content/administrador/catalogos/analistas/analistas.component';
import { DashboardAdminComponent } from './ike-content/administrador/dashboard-admin/dashboard-admin.component';
import { SolicitudesFinalizadasAdminComponent } from './ike-content/administrador/solicitudes-finalizadas-admin/solicitudes-finalizadas-admin.component';
import { SolicitudProcesoAdminComponent } from './ike-content/administrador/solicitudes-pendientes/solicitud-proceso-admin/solicitud-proceso-admin.component';
import { SolicitudFirmaAdminComponent } from './ike-content/administrador/solicitudes-pendientes/solicitud-firma-admin/solicitud-firma-admin.component';
import { SolicitudDraftAdminComponent } from './ike-content/administrador/solicitudes-pendientes/solicitud-draft-admin/solicitud-draft-admin.component';
import { AdminLayoutComponent } from './ike-content/administrador/admin-layout/admin-layout.component';
import { AdminTabsComponent } from './ike-content/administrador/admin-tabs/admin-tabs.component';
import { CatLayoutComponent } from './ike-content/administrador/catalogos/catalogo-layout'

import { NewTabComponent } from './ike-content/new-tab/new-tab.component'

const appRoutes: Routes = [

  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: '', component: LoginComponent },

    ]
  },
  { 
    path: 'comercial', 
    component: IkeLayoutComponent,
    canActivate: [AutenticacionService],
    children: [
      {
        path: 'dashboard',
        component: DashboardComercialComponent
      },
      {
        path: 'solicitude',
        component: SolicitudeComercialComponent
      },
      {
        path: 'tabs',
        component: TabComercialComponent
      },
      {
        path: 'new-chat',
        component: NewTabComponent
      }
    ]
  },
  {
    path: 'legal',
    component: IkeLayoutComponent,
    canActivate: [AutenticacionService],
    children: [
      { 
        path:'dashboard' ,
        component: DashboardLegalComponent
      },
      {
        path:'tabs',
        component: TabsLegalComponent
      },
      { 
        path:'solicitudes' ,
        component: SolicitudesLegalComponent
      },
      { 
        path:'proceso' ,
        component: SolicitudProcesoComponent
      },
      { 
        path:'firma' ,
        component: SolicitudFirmaComponent
      },
      { 
        path:'draft' ,
        component: SolicitudDraftComponent
      },
      {
        path:'finalizadas',
        component: SolicitudesFinalizadasComponent
      },
      {
        path:'analistas',
        component: CatalogoAnalistasComponent,
      },
      {
        path: 'new-chat',
        component: NewTabComponent
      }
    ]
  },
  {
    path: 'abogado',
    component: IkeLayoutComponent,
    canActivate: [AutenticacionService],
    children: [
      { 
        path:'dashboard' ,
        component: DashboardAbogadoComponent
      },
      { 
        path:'tabs-abogado' ,
        component: TabsAbogadoComponent
      },
      {
        path: 'new-chat',
        component: NewTabComponent
      }
    ]
  },
  {
    path: 'administrador',
    component: IkeLayoutComponent,
    canActivate: [AutenticacionService],
    children: [
      { 
        path:'dashboard' ,
        component: DashboardAdminComponent
      },
      { 
        path:'solicitud' ,
        component: SolicitudesAdminComponent
      },
      {
        path: 'new-chat',
        component: NewTabComponent
      },
      { 
        path:'pendientes' ,
        component: AdminLayoutComponent,
        children: [
          { 
            path:'draft' ,
            component: SolicitudDraftAdminComponent
          },
          { 
            path:'firma' ,
            component: SolicitudFirmaAdminComponent
          },
          { 
            path:'proceso' ,
            component: SolicitudProcesoAdminComponent
          }
        ]
      },
      { 
        path:'finalizados' ,
        component: SolicitudesFinalizadasAdminComponent,
      },
      { 
        path:'catalogo' ,
        component: CatLayoutComponent,
        children: [
          { 
            path:'clientes' ,
            component: ClientesComponent
          },
          { 
            path:'analistas' ,
            component: AnalistasComponent
          }
        ]
      },
      {
        path: 'tabs',
        component: AdminTabsComponent
      }
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'login' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
