<div class="app-content content">
	<div class="content-wrapper p-0">
		<div class="content-body">
			<div class="container col-12 px-1 ">
				<section id="minimal-statistics">
					<!-- Aqui inicia el div blanco(general)-->
					<div class="container-lg">
						<!-- Striped rows start -->
						<div class="col-12">
							<div class="main-card col-sm-12 my-2">
								<div class="card-content collapse show c">
									<div class="card-body">

										<div class="row justify-content-end mt-1">
											<div class="mt-2 mr-1 font-weight-bold" style="height:35px;">
												Buscar:
											</div>
											<input type="text" [(ngModel)]="filter" class="mt-1" style="height:35px;width:25%;"
												placeholder="Comercial, Analista Jurídico, Empresa, Cliente, Folio"
												(keyup)="quickFilter($event)" />

											<button [routerLink]="['/comercial/solicitude']" style="height:35px;"
												type="button"
												class="mt-1 ml-2 font-medium-1 text-bold-700 btn btn-info btn-min-width">
												Nueva Solicitud</button>
											<button (click)="createExcel()" type="button"
												class="btn mb-1"><img src="../../../../assets/images/logo/exportar.svg"
													alt="exportar" height="35" width="35"></button>

										</div>

										<article class="card mb-2 pointer text-center">
											<div>
												<ngx-datatable class="bootstrap table-bordered" [rows]="items"
													[columnMode]="'flex'" [headerHeight]="'auto'" [footerHeight]="50"
													[limit]="limit" [rowHeight]="50" fxFlex="auto"
													(activate)="detalles($event)">
													<ngx-datatable-column [resizeable]="false" [draggable]="false"
														[resizeable]="false" [draggable]="false" name="Folio"
														prop="folio" [width]="'auto'" [flexGrow]="1">
														<ng-template ngx-datatable-cell-template let-value="value">
															<div class="text-center">{{value}}</div>
														</ng-template>
													</ngx-datatable-column>
													<ngx-datatable-column [resizeable]="false" [draggable]="false"
														name="Fecha Folio" prop="fecha" [flexGrow]="1">
														<ng-template ngx-datatable-cell-template let-value="value">
															<div class="text-center">{{value | date: 'yyyy-MM-dd'}}
															</div>
														</ng-template>
													</ngx-datatable-column>
													<ngx-datatable-column [resizeable]="false" [draggable]="false"
														name="Tipo" prop="tipo-de-solicitud-2" [flexGrow]="2">
														<ng-template let-value="value" ngx-datatable-cell-template>
															<div class="text-center">{{value}}</div>
														</ng-template>
													</ngx-datatable-column>
													<ngx-datatable-column [resizeable]="false" [draggable]="false"
														name="Cliente" prop="ciiente-o-razon-social.title"
														[flexGrow]="1">
														<ng-template let-value="value" ngx-datatable-cell-template>
															<div class="text-center">{{value}}</div>
														</ng-template>
													</ngx-datatable-column>
													<ngx-datatable-column [resizeable]="false" [draggable]="false"
														name="Empresa" prop="cliente" [flexGrow]="3">
														<ng-template let-value="value" ngx-datatable-cell-template>
															<div class="text-center">{{value}}</div>
														</ng-template>
													</ngx-datatable-column>
													<ngx-datatable-column [resizeable]="false" [draggable]="false"
														name="Comercial" prop="usuario.title" [flexGrow]="2">
														<ng-template let-value="value" ngx-datatable-cell-template>
															<div class="text-center">{{value}}</div>
														</ng-template>
													</ngx-datatable-column>
													<ngx-datatable-column [resizeable]="false" [draggable]="false"
														name="Analista Jurídico" prop="abogado.title" [flexGrow]="2">
														<ng-template let-value="value" let-row="row"
															ngx-datatable-cell-template>
															<div class="text-center" (click)="detalles(row)">{{value}}
															</div>
														</ng-template>
													</ngx-datatable-column>
													<ngx-datatable-column [resizeable]="false" [draggable]="false"
														name="Estatus" prop="estatus-2" [flexGrow]="3">
														<ng-template let-value="value" ngx-datatable-cell-template>
															<div class="text-center">{{value}}</div>
														</ng-template>
													</ngx-datatable-column>
													<ngx-datatable-footer id="id-f">
														<ng-template ngx-datatable-footer-template id="af" style="width: 100vw; position: absolute;">
															<p style="margin-top: 15px; margin-right: 30px; margin-left: 10px; justify-content: right!important;">Total: {{solicitud.filtered}}</p>
															<p style="margin-top: 14px;"> Items por página
																<select id="cantidad" [(ngModel)]="elements" (change)="changeSizePage($event)">
																	<option *ngFor="let dato of [20,50,100]">
																	  {{dato}}
																	</option>
																  </select>
																</p>
															
															<ul class="pagination pagination-separate pagination-flat" style="margin-top: 0px; position:absolute; right:0; margin-right: 10px">
																<ngb-pagination [collectionSize]="paginations" [pageSize]="1" [maxSize]="5" [(page)]="page" (pageChange)="changePage()"  aria-label="Default pagination"></ngb-pagination>
															</ul>
														</ng-template>
													  </ngx-datatable-footer>
												</ngx-datatable>
											</div>

										</article>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Striped rows end -->
				</section>
			</div>
		</div>
	</div>
</div>

<!-- MODALES -->


<!-- Modal para errores -->
<ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
	<div class="modal-header">
		<h4 class="modal-title">Algo salió mal, inténtalo nuevamente</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<div class="modal-body icon-alert mt-3">
			<img src="../../assets/custom/advertencia.svg" alt="alerta" width="60">
		</div>
		<h4 class="icon-alert mb-3">{{Mensaje}}</h4>

	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-info" (click)="d('Close modal')">Aceptar</button>
	</div>
</ng-template>