import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ValidatorFn, FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';



@Component({
  selector: 'app-tabs-legal',
  templateUrl: './tabs-legal.component.html',
  styleUrls: ['./tabs-legal.component.css']
})
export class TabsLegalComponent implements OnInit {

  
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;

  Mensaje: any;
  conversacion: any;
  detalles_solicitud: any;
  abogado: any;
  comercial: any;
  check = false;
  doc_final: any 
  user: any;
  newRevisionForm: FormGroup;
  submitted: any

  constructor(private router: Router, 
    private service: ApiService,
    private modalService: NgbModal,
    private renderer: Renderer2
    ) {
      this.doc_final = {
        "archivo":"",
        "nombre-archivo":"",
        "fecha": ""
      }
    localStorage.getItem("detalles_solicitud") == null ? router.navigate(["/legal/dashboard"]) : "";
    this.detalles_solicitud = JSON.parse(localStorage.getItem("detalles_solicitud"));
  }

  ngOnInit(): void {
    this.user = localStorage.getItem('nombre')
    setTimeout(() => {
      if(
        (this.detalles_solicitud['tipo-de-solicitud-2']=='Contrato' && this.detalles_solicitud['accion']=='Aprobar Solicitud')
          || 
        (this.detalles_solicitud['tipo-de-solicitud-2']!='Contrato' && this.detalles_solicitud['abogado']!=undefined))
      {
      }  
      this.getRevisiones(this.contenidoDelModal)
      }, 500);
    
  }
  showSidebar(event){}
  showCompose(event){
    const toggleIcon = document.getElementById('compose-sidebar');
    const toggleOverlay = document.getElementById('app-content-overlay');
    if (event.currentTarget.className === 'close close-icon' || 'app-content-overlay') {
      this.renderer.removeClass(toggleIcon, 'show');
      this.renderer.removeClass(toggleOverlay, 'show');
    }
  }
  showComposeSidebar(event){
    const toggleIcon = document.getElementById('compose-sidebar');
    const toggleSidebar = document.getElementById('sidebar-left');
    const toggleOverlay = document.getElementById('app-content-overlay');
    if (event.currentTarget.className === 'btn btn-info btn-glow btn-block my-2 compose-btn') {
      this.renderer.addClass(toggleIcon, 'show');
     this.renderer.removeClass(toggleSidebar, 'show');
      this.renderer.addClass(toggleOverlay, 'show');
    } else if (event.currentTarget.className === 'btn btn-danger btn-glow btn-block my-2 compose-btn show') {
      this.renderer.removeClass(toggleIcon, 'show');
     }
  }

  getRevisiones(DefaultModalContent){
    this.comercial = this.detalles_solicitud["usuario"]
    const data ={
      "id_solicitud": this.detalles_solicitud["item_id"]
    }
    this.service.getRevision(data).subscribe(
      (data: any) =>{
        this.conversacion = data;
        data.forEach(
          element => {
            if(element["archivo"]!=undefined){
              this.doc_final = element;
            }
        });
        console.log(data)
      },
      err => {
        console.log("error al consumir peticion", err);
        this.Mensaje = "Error al consumir servicio"
      this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }
  
  consultaFirma(){
    this.detalles_solicitud["abogado-firma"] == "Si" 
      ? 
        ((<HTMLInputElement>document.getElementById("abogado")).checked = true)
      :
      ((<HTMLInputElement>document.getElementById("abogado")).checked = false)
    
      this.detalles_solicitud["comercial-firma"] == "Si"
      ? 
        ((<HTMLInputElement>document.getElementById("comercial")).checked = true)
      :
      ((<HTMLInputElement>document.getElementById("comercial")).checked = false)
  }

  consulta(){
    this.check = !this.check
    this.detalles_solicitud["abogado-2"] == "Si" 
      ? 
        ((<HTMLInputElement>document.getElementById("colorCheck2")).checked = true)
      :
      ((<HTMLInputElement>document.getElementById("colorCheck2")).checked = false)
    
      this.detalles_solicitud["comercial"] == "Si" 
      ? 
        ((<HTMLInputElement>document.getElementById("colorCheck1")).checked = true)
      :
      ((<HTMLInputElement>document.getElementById("colorCheck1")).checked = false);
    
      ((<HTMLInputElement>document.getElementById("colorCheck2")).disabled = true);
      ((<HTMLInputElement>document.getElementById("colorCheck1")).disabled = true)
  }

  removeSimbolo(contraprestacion){
    return contraprestacion.replace("$","")
  }
}
