<div class="app-content content">
    <div class="content-wrapper p-0">
        <div class="content-body">
            <div class="container col-12 px-2 ">
                <div class="card mt-2">
                    <div class="card-body">

                        <article class="mb-2">
                            <router-outlet></router-outlet>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

