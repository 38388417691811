<div class="app-content content">
  <div class="content-wrapper px-0 mx-2">
      <div class="content-body">
        <div class="container col-12 px-1 ">
          <section id="minimal-statistics">
            <!-- Aqui inicia el div blanco(general)-->
            <div class="container-lg">
              <!-- Striped rows start -->
              <div class="row">
                <div class="main-card col-sm-12 mt-2">
                    <!-- <div class="card-header">
                      <h4 class="card-title">Striped rows</h4>
                    </div> -->
                    <div class="card-content collapse show p-2">
                      <article class="row justify-content-end mrgtop">
                        <button (click)="createTable()" type="button" class="btn"><img src="../../../../assets/images/logo/exportar.svg" alt="exportar" height="35" width="35"></button>
                    </article>
                              <article class="mb-2 pointer text-center">
                                <ngx-datatable class="bootstrap table-bordered" [rows]="solicitud" [columnMode]="'flex'"
                                [headerHeight]="'auto'" [footerHeight]="50" [limit]="10" [rowHeight]="'auto'" fxFlex="auto">
                                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Folio" prop="folio" [width]="'auto'" [flexGrow]="1">
                                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                     <div class="text-center" (click)="detalles(row)">{{value}}</div>
                                  </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Fecha Folio" prop="fecha" [flexGrow]="2">
                                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                     <div class="text-center" (click)="detalles(row)">{{value | date: 'yyyy-MM-dd'}}</div>
                                  </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Tipo" prop="tipo-de-solicitud-2" [flexGrow]="2">
                                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                     <div class="text-center" (click)="detalles(row)">{{value}}</div>
                                  </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Cliente" prop="ciiente-o-razon-social.title" [flexGrow]="2">
                                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                     <div class="text-center" (click)="detalles(row)">{{value}}</div>
                                  </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Empresa" prop="cliente" [flexGrow]="3">
                                  <ng-template let-value="value" ngx-datatable-cell-template>
                                    <div class="text-center">{{value}}</div>
                                  </ng-template>
                                  </ngx-datatable-column>                                
                                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Comercial" prop="usuario.title" [flexGrow]="2">
                                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                    <div class="text-center" (click)="detalles(row)">{{value}}</div>
                                  </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus-2" [flexGrow]="2">
                                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                     <div class="text-center" (click)="detalles(row)">{{value}}</div>
                                  </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Analista Jurídico" prop="abogado.title" [flexGrow]="2">
                                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                     <div class="text-center" (click)="detalles(row)">{{value}}</div>
                                  </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Acciones" [flexGrow]="1">
                                  <ng-template ngx-datatable-cell-template let-row="row">
                                    <div class="d-flex w-100" style="transform: translateY(-10px);">
                                      <button class="btn btn-lg text-info w-100">
                                        <i class="feather ft-edit" (click)="asignar(AsignarFormContent, row)"></i>
                                      </button>
                                    </div>
                                  </ng-template>
                                </ngx-datatable-column>
                              </ngx-datatable>
                              </article>
                            </div>

                        </div>
                    </div>
                    <!-- Striped rows end -->
                </div>
            </section>
        </div>
      </div>
  </div>
</div>
<!-- modal -->
<section id="form-components">
    <div class="col-12">
        <div class="card-content collapse show">
          <div class="card-body">
            <div class="row my-2">
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <!-- el boton se encuentra en icono editar -->
                  <!-- Modal -->
                  <ng-template class="modal text-left" #AsignarFormContent let-d="dismiss">
                    <div class="modal-content">
                      <div class="modal-header">
                        <label class="modal-title text-text-bold-600" id="myModalLabel33">Asignar Analista Juridico</label>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <form [formGroup]="asignarAnalista" (ngSubmit)="actualizarSolicitud(DefaultModelContent)">
                        <div class="modal-body">
                            <ng-container mCardBody>
                                <div class="card-content collapse show">
                                    <div class="table">
                                        <div class="table-responsive">
                                        <table class="table table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col">Folio</th>
                                            <th scope="col">Fecha Folio</th>
                                            <th scope="col">Tipo</th>
                                            <th scope="col">Cliente</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{{ modal_solicitud['folio'] }}</td>
                                            <td>{{ modal_solicitud['fecha'] }}</td>
                                            <td>{{ modal_solicitud['tipo-de-solicitud-2'] }}</td>
                                            <td>{{ modal_solicitud['ciiente-o-razon-social']['title'] }}</td>
                                        </tr>
                                        </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                    <section class="basic-select">
                                        <label class="ml-2">Analista Juridico</label>
                                        <div class="card-block">
                                            <div class="card-body">
                                                <div class="form-group">
                                                <select formControlName="solicitud" class="form-control">
                                                  <option *ngFor="let data of analistas" [value]="data['usuarios']['item_id']">
                                                  {{ data.titulo }}
                                                  </option>
                                                </select> 
                                                </div>
                                            </div>
                                        </div>
                                      </section>
                                
                              </ng-container>
                         
                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn-clr "> Guardar</button>
                        </div>
                      </form>
                    </div>
                  </ng-template>
                </div>
              </div>


            </div>
          </div>
        </div>
    </div>
  
</section>


      <!-- Modal para errores -->
      <ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
        <div class="modal-header">
          <h4 class="modal-title" >Algo salió mal, inténtalo nuevamente</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="modal-body icon-alert mt-3">
            <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60" >
        </div>
        <h4 class="icon-alert mb-3">{{Mensaje}}</h4>
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info"(click)="d('Close modal')">Aceptar</button>
        </div>
      </ng-template>
 