import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IkeService } from '../../services/ike.service';
import { ApiService } from './../../../_services/api.service';
@Component({
  selector: 'app-dashboard-abogado',
  templateUrl: './dashboard-abogado.component.html',
  styleUrls: ['./dashboard-abogado.component.css']
})
export class DashboardAbogadoComponent implements OnInit {
  
  solicitud: any = {
    total: 0,
    filtered: 0,
    items: []
  };
  items: any;
  master:any;
  filter=null;

  page: any = 1;
  limit: any = 20;
  elements: any = 20;
  paginations: any = 0;

  constructor(
    private service: ApiService, 
    private router: Router,
    private _ikeService: IkeService) {this.getSolicitudes()}

  ngOnInit(): void {
    
  }

  getSolicitudes(){
    const offset = (this.page-1)*this.elements;
    this.service.solicitudes(this.elements,offset).subscribe(
      (data: any) => {
        this.solicitud = data;
        this.items = data.items;
        this.paginations = data.filtered / this.elements;
        this.master=data;
      },
      err => {
        console.log("Error al consumir petición")
      }
    )
  }

  detalles(e){
    if (e.type == 'click'){
      localStorage.setItem("detalles_solicitud",JSON.stringify(e.row));
      this.router.navigate(["/abogado/tabs-abogado"])
    }
  }

  changePage(event){
    this.getSolicitudes();
  }
  
  changeSizePage(event){
    this.page = 1;
    this.limit = event.target.value;
    this.getSolicitudes();
  }

  quickFilter(e) {
    
    if( !e.target.value ){
      this.elements = 20;
      this.limit = 20;
      this.page = 1;

      this.items = this.master.items.map( x => { return x });
      this.solicitud = this.master;
      this.paginations = this.master.filtered / this.elements;
      return;
    }

    this.items = this.master.items.filter((x) =>
      
      x.usuario?.title?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x.abogado?.title?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x['ciiente-o-razon-social']?.title?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x['estatus-2'].toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x.cliente.toLowerCase()?.includes(e.target.value.toLowerCase())||
      x.folio.toLowerCase()?.includes(e.target.value.toLowerCase())
    
    );

    const size_text = e.target.value.length;
    if( this.items.length === 0 || size_text > 3){
      this.elements = 100;
      this.limit = 100;
      this.page = 1;
      const data = {"filter": e.target.value}
      this.service.filtroSolicitudesPage(data,500,0).subscribe(
        (response: any) =>{
          this.items = response.items;
          this.solicitud = response;
          this.paginations = response.filtered / this.elements;
        },
        err =>{
          console.log("Error al consumir petición")
        }
      );
    }

  }

  createExcel() {
    const headers = ["Folio","Fecha Folio","Tipo","Cliente","Empresa","Comercial","Estatus" ]
    const excelObj = {
      title:'Solicitudes',
      header: headers,
      data: this.items,
      hasLawyer: false
    }
    this._ikeService.createExcel(excelObj)
  }

}
