
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule,NgbCarouselConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AlertComponent } from './_helpers/alert.component';
import { AlertService } from './_services/alert.service';
import { AuthService } from './_services/auth.service';
import { ChartApiService } from './_services/chart.api';
import { TableApiService } from './_services/table-api.service';
import { ApplicationApiService } from './_services/application-api.service';
import { QuillInitializeServiceService } from './_services/quill-initialize-service.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
// Routing
import { routing } from './app.routing';
// Components
import { AppComponent } from './app.component';
import { SettingsModule } from './_layout/settings/settings.module';
import { ThemeSettingsConfig } from './_layout/settings/theme-settings.config';
import { MenuSettingsConfig } from './_layout/settings/menu-settings.config';
import { HeaderComponent } from './_layout/header/header.component';
import { VerticalComponent as HeaderVerticalComponent } from './_layout/header/vertical/vertical.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './_layout/header/horizontal/horizontal.component';
import { FullLayoutNavbarComponent } from './_layout/header/full-layout-navbar/full-layout-navbar.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { NavigationComponent as AppNavigationComponent } from './_layout/navigation/navigation.component';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { NavbarService } from './_services/navbar.service';
import { VerticalnavComponent } from './_layout/navigation/verticalnav/verticalnav.component';
import { HorizontalnavComponent } from './_layout/navigation/horizontalnav/horizontalnav.component';
// perfect scroll bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { PartialsModule } from './content/partials/partials.module';
import { BreadcrumbModule } from './_layout/breadcrumb/breadcrumb.module';
import { BlockTemplateComponent } from './_layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { MatchHeightModule } from './content/partials/general/match-height/match-height.module';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { UserService } from './_api/user/user.service';
import { IkeLayoutComponent } from './_layout/ike-layout/ike-layout.component';
import { IkeHeaderComponent } from './_layout/header/ike-header/ike-header.component';
import { DashboardAbogadoComponent } from './ike-content/abogado/dashboard-abogado/dashboard-abogado.component';
import { TabsAbogadoComponent } from './ike-content/abogado/tabs-abogado/tabs-abogado.component';
//PERFIL COMERCIAL
import { DashboardComercialComponent } from './ike-content/comercial/dashboard/dashboard.component'
import { TabComercialComponent } from './ike-content/comercial/tab/tab.component'
import { SolicitudeComercialComponent } from './ike-content/comercial/solicitude/solicitude.component'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './../app/_services/token.interceptor';
//PERFIL DIR. LEGAL
import { DashboardLegalComponent } from './ike-content/dirlegal/dashboard-legal/dashboard-legal.component';
import { SolicitudesLegalComponent } from './ike-content/dirlegal/solicitudes-legal/solicitudes-legal.component';
import { SolicitudProcesoComponent } from './ike-content/dirlegal/solicitudes-pendientes/solicitud-proceso/solicitud-proceso.component';
import { SolicitudFirmaComponent } from './ike-content/dirlegal/solicitudes-pendientes/solicitud-firma/solicitud-firma.component';
import { SolicitudDraftComponent } from './ike-content/dirlegal/solicitudes-pendientes/solicitud-draft/solicitud-draft.component';
import { SolicitudesFinalizadasComponent } from './ike-content/dirlegal/solicitudes-finalizadas/solicitudes-finalizadas.component';
import { CatalogoAnalistasComponent } from './ike-content/dirlegal/catalogo-analistas/catalogo-analistas.component';
import { TabsLegalComponent } from './ike-content/dirlegal/tabs-legal/tabs-legal.component';
//PERFIL ADMINISTRADOR
import { SolicitudesAdminComponent } from './ike-content/administrador/solicitudes-admin/solicitudes-admin.component';
import { ClientesComponent } from './ike-content/administrador/catalogos/clientes/clientes.component';
import { AnalistasComponent } from './ike-content/administrador/catalogos/analistas/analistas.component';
import { DashboardAdminComponent } from './ike-content/administrador/dashboard-admin/dashboard-admin.component';
import { SolicitudesFinalizadasAdminComponent } from './ike-content/administrador/solicitudes-finalizadas-admin/solicitudes-finalizadas-admin.component';
import { SolicitudProcesoAdminComponent } from './ike-content/administrador/solicitudes-pendientes/solicitud-proceso-admin/solicitud-proceso-admin.component';
import { SolicitudFirmaAdminComponent } from './ike-content/administrador/solicitudes-pendientes/solicitud-firma-admin/solicitud-firma-admin.component';
import { SolicitudDraftAdminComponent } from './ike-content/administrador/solicitudes-pendientes/solicitud-draft-admin/solicitud-draft-admin.component';
import { AdminLayoutComponent } from './ike-content/administrador/admin-layout/admin-layout.component';
import { AdminTabsComponent } from './ike-content/administrador/admin-tabs/admin-tabs.component';
import { CatLayoutComponent } from './ike-content/administrador/catalogos/catalogo-layout';
import { CurrencyPipe ,DatePipe } from '@angular/common';

import { NewTabComponent } from './ike-content/new-tab/new-tab.component'
import { EmailChatComponent } from './ike-content/new-tab/email-chat/email-chat.component'
@NgModule({
    imports: [
        BrowserModule,
        PartialsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ChartsModule,
        BrowserAnimationsModule,
        MatchHeightModule,
        BreadcrumbModule,
        NgbModule,
        FormsModule,
        routing,
        NgxDatatableModule,
        // Settings modules
        SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
        PerfectScrollbarModule,
        NgxSpinnerModule,
        LoadingBarRouterModule,
        BlockUIModule.forRoot({
          template: BlockTemplateComponent
        })
    ],
    declarations: [
        AppComponent,
        PublicLayoutComponent,
        HeaderComponent,
        FullLayoutNavbarComponent,
        HeaderHorizontalComponent,
        HeaderVerticalComponent,
        FooterComponent,
        NewTabComponent,
        EmailChatComponent,
        AppNavigationComponent,
        AlertComponent,
        LoginComponent,
        ChangelogComponent,
        VerticalnavComponent ,
        HorizontalnavComponent ,
        BlockTemplateComponent,
        FullLayoutComponent,
        IkeLayoutComponent,
        IkeHeaderComponent,
        DashboardAbogadoComponent,
        TabsAbogadoComponent,
        DashboardComercialComponent,
        TabComercialComponent,
        SolicitudeComercialComponent,
        DashboardLegalComponent,
        SolicitudesLegalComponent,
        SolicitudProcesoComponent,
        SolicitudFirmaComponent,
        SolicitudDraftComponent,
        SolicitudesFinalizadasComponent,
        CatalogoAnalistasComponent,
        SolicitudesAdminComponent,
        ClientesComponent,
        AnalistasComponent,
        DashboardAdminComponent,
        SolicitudesFinalizadasAdminComponent,
        SolicitudProcesoAdminComponent,
        SolicitudFirmaAdminComponent,
        SolicitudDraftAdminComponent,
        TabsLegalComponent,
        AdminLayoutComponent,
        AdminTabsComponent,
        CatLayoutComponent
],
    providers: [
        ChartApiService,
        AlertService,
        NavbarService,
        TableApiService,
        ApplicationApiService,
        QuillInitializeServiceService,
        AuthService,
        UserService,
        DatePipe,
        CurrencyPipe,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        NgbCarouselConfig,
        NgbModalConfig,
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})
export class AppModule {}
