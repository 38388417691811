<div class="app-content content">
  <div class="content-wrapper p-0">
    <div class="content-body">
      <section id="minimal-statistics" class="px-2">
        <section class="card mx-0 mt-2">
          <div class="card-body">


            <div class="row justify-content-end mrgtop mb-2">
              <div class="mt-2 mr-1 font-weight-bold" style="height:35px;">
                Buscar:
                </div>
              <input type="text" [(ngModel)]="filter" class="mt-1" style="height:35px;width:25%;"
                placeholder="Comercial, Analista Jurídico, Empresa, Cliente, Folio" (keyup)="quickFilter($event)" />

              <button (click)="createExcel()" type="button" class="btn"><img
                  src="../../../../assets/images/logo/exportar.svg" alt="exportar" height="35" width="35"></button>

            </div>
            <!-- TABLE -->
            <article class="mb-2 mrgtop pointer text-center">
              <ngx-datatable class="bootstrap table-bordered" [rows]="items" [columnMode]="'flex'"
                [headerHeight]="'auto'" [footerHeight]="50" [limit]="limit" [rowHeight]="'auto'" fxFlex="auto">
                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Folio" prop="folio"
                  [width]="'auto'" [flexGrow]="1">
                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                    <div class="text-center" (click)="detalles(row)">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Fecha Folio" minWidth="110"
                  prop="fecha" [flexGrow]="2">
                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                    <div class="text-center" (click)="detalles(row)">{{value | date: 'yyyy-MM-dd'}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Tipo" prop="tipo-de-solicitud-2"
                  [flexGrow]="2">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <div class="text-center" (click)="detalles(row)">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Cliente"
                  prop="ciiente-o-razon-social.title" [flexGrow]="2">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <div class="text-center" (click)="detalles(row)">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Empresa" prop="cliente"
                  [flexGrow]="3">
                  <ng-template let-value="value" ngx-datatable-cell-template>
                    <div class="text-center">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="País" prop="pais" [flexGrow]="1">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <div class="text-center" (click)="detalles(row)">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Comercial" prop="usuario.title"
                  [flexGrow]="2">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <div class="text-center" (click)="detalles(row)">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus-2"
                  [flexGrow]="2">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <div class="text-center" (click)="detalles(row)">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Analista Jurídico"
                  prop="abogado.title" [flexGrow]="2">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <div class="text-center" (click)="detalles(row)">{{value}}</div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Acciones" [flexGrow]="2">
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex w-100" style="transform: translateY(-10px);">
                      <button class="btn btn-lg text-info w-50" (click)="asignar(AsignarFormContent, row)">
                        <i class="feather ft-edit"></i>
                      </button>
                      <button class="btn btn-lg text-danger w-50" (click)="borrar(borrarSolicitud, row)">
                        <i class="feather ft-trash"></i>
                      </button>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-footer id="id-f">
                  <ng-template ngx-datatable-footer-template id="af" style="width: 100vw; position: absolute;">
                      <p style="margin-top: 15px; margin-right: 30px; margin-left: 10px; justify-content: right!important;">Total: {{solicitud.filtered}}</p>
                      <p style="margin-top: 14px;"> Items por página
                          <select id="cantidad" [(ngModel)]="elements" (change)="changeSizePage($event)">
                              <option *ngFor="let dato of [20,50,100]">
                                {{dato}}
                              </option>
                            </select>
                          </p>
                      
                      <ul class="pagination pagination-separate pagination-flat" style="margin-top: 0px; position:absolute; right:0; margin-right: 10px">
                          <ngb-pagination [collectionSize]="paginations" [pageSize]="1" [maxSize]="5" [(page)]="page" (pageChange)="changePage()"  aria-label="Default pagination"></ngb-pagination>
                      </ul>
                  </ng-template>
                </ngx-datatable-footer>
              </ngx-datatable>
            </article>
          </div>
        </section>
      </section>
    </div>
  </div>
</div>
<ng-template #borrarSolicitud let-c="close" let-d="dismiss" id="borrarSolicitud">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <h4 class="text-center">¿Estas seguro que quieres borrar esta solicitud?</h4>
    <div class="modal-body icon-alert mt-3">
      <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60">
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="d('Close modal')">Rechazar</button>
    <button type="button" class="btn btn-danger" (click)="clickBorrar(row)">Aceptar</button>
  </div>
</ng-template>

<!-- modal -->

<section id="form-components">
  <div class="col-12">
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row my-2">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="form-group">
              <!-- el boton se encuentra en icono editar -->
              <!-- Modal -->
              <ng-template class="modal text-left" #AsignarFormContent let-d="dismiss">
                <div class="modal-content">
                  <div class="modal-header">

                    <h4 class="modal-title" id="myModalLabel33">Asignar Analista Jurídico</h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <form [formGroup]="asignarAnalista" (ngSubmit)="actualizarSolicitud(DefaultModelContent)">
                    <div class="modal-body">
                      <ng-container mCardBody>
                        <div class="card-content collapse show">
                          <div class="table">
                            <div class="table-responsive">
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Folio</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Cliente</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{{ modal_solicitud['folio'] }}</td>
                                    <td>{{ modal_solicitud['tipo-de-solicitud-2'] }}</td>
                                    <td>{{ modal_solicitud['ciiente-o-razon-social']['title'] }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <section class="basic-select">
                          <label class="ml-2">Analista Jurídico</label>
                          <div class="card-block">
                            <div class="card-body">
                              <div class="form-group">
                                <select formControlName="solicitud" class="form-control">
                                  <option *ngFor="let analista of analistas" [value]="analista['usuarios']['item_id']">
                                    {{ analista.titulo }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </section>

                      </ng-container>

                    </div>
                    <div class="modal-footer">
                      <button type="submit" class="btn btn-info "> Guardar</button>
                    </div>
                  </form>
                </div>
              </ng-template>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

</section>

<!-- Modal Faltan datos generales -->
<ng-template #ErrorModal let-c="close" let-d="dismiss" id="myModalLabel1">
  <div class="modal-header">
    <h4 class="modal-title">Algo salió mal, inténtalo nuevamente</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="modal-body icon-alert mt-3">
      <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60">
    </div>
    <h4 class="icon-alert mb-3">{{Mensaje}}</h4>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="d('Close modal')">Aceptar</button>
  </div>
</ng-template>