import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AutenticacionService } from './autenticacion';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private countRequest = 0;
  constructor(private router: Router,public auth: AutenticacionService,private spinnerService: NgxSpinnerService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });

    if (!this.countRequest) {
      this.spinnerService.show();
    }

    this.countRequest++;
    return next.handle(request).pipe(

      catchError(error => {

        if (error instanceof HttpErrorResponse && error.status === 403 || error.status === 401) {

          this.router.navigateByUrl("/login");

        } else if(error.status === 422){
          localStorage.removeItem("perfil");
          localStorage.removeItem("nombre");
          localStorage.removeItem("general");
          localStorage.removeItem("token");
          this.router.navigateByUrl("/login");
        } else {
            console.info('ErrorHG', error);
            return throwError(error);
        }

      }), finalize( () => {
        this.countRequest--;
          if (!this.countRequest) {
            this.spinnerService.hide()
          }
      })

    );
  }
}