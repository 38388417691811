import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from './../../../_services/api.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
const $id = (id) => (<HTMLInputElement>document.getElementById(id))


@Component({
  selector: 'app-solicitude',
  templateUrl: './solicitude.component.html',
  styleUrls: ['./solicitude.component.css']
})
export class SolicitudeComercialComponent implements OnInit {


  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;

  selectTipo: any;
  selectClient: any;
  tipoSolicitud: any;
  selectSolicitud: any;

  ine: any;
  rfc: any;
  acta: any;
  poder: any;
  comprobante: any;

  tipo: any;
  moneda: any;
  cliente: any;
  vigencia: any;
  porcentaje: any;
  instrucciones: any;
  archivoTerminos: any;
  contraprestacion: any;

  //NUEVOS CAMPOS
  clientesSelect: any;
  revenue: any;
  empresa: any;
  porcentajeRevenueCliente: any;
  porcentajeRevenueEmpresa: any;

  indexTipoSolicitud: any
  indexTipo: any
  indexCliente: any
  indexVigencia: any
  indexMoneda: any
  indexClientesSelect: any

  clientLenght: any

  //formulario de nuevo cliente
  newClientForm: FormGroup
  sendForm: boolean
  Mensaje: any;

  periodosArr = ['6 meses', '1 año', '3 años', 'Indefinido', 'Otro']
  clientesArr = []
  clientesObj = {
    mexico: [
      'SERVICIOS TECNOLOGICOS APRISSA S.A.P.I DE C.V.',
      'ARSA ASESORIA  INTEGRAL PROFESIONAL, S. DE  R.L. DE  C.V.',
      'PROTECCION S.O.S. JURIDICO AUTOMOLISTICO  LAS  VEINTICUATRO',
      'INFOASIST  INFORMACION Y  ASISTENCIA, S. DE  R.L. DE  C.V',
      'Iké Grupo Empresarial S. de R.L. de C.V.',
      'SEA Servicios Especializados de Asistencia S. de R.L. de C.V.',
      'Vive Plus  S.A. de C.V.',
      'Servicios Técnicos de Asistencia S. de R.L. de C.V.',
      'Infocredit S. de R.L. de C.V.'],
    argentina: ['EFICASIA  ARGENTINA, S.A.', 'IKE  ASISTENCIA  ARGENTINA, S.A.'],
    brasil: ['IKÉ  ASISTENCIA  BRASIL, S.A.'],
    colombia: ['IKÉ  ASISTENCIA  COLOMBIA, S.A.', 'KOME  CARE IPS, S.A.S.']
  }
  fecha = false
  fechaActual = "10/10/2020"
  altoCard = 'height: 450px;'
  formTemp: any;
  processing: boolean

  documentAvailable = false;
  constructor(
    private service: ApiService,
    private router: Router,
    private modalService: NgbModal,
    private _builder: FormBuilder) {

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.newClientForm = this._builder.group({
        name: new FormControl('', [Validators.required]),
        razon: new FormControl('', [Validators.required]),
        empresa: new FormControl('Ingresar Nombre de la Empresa', []),
        rfc: new FormControl('', [Validators.required])
      })

      this.getCatalogoSolicitud(this.contenidoDelModal)
    }, 500);
    let local = localStorage.getItem('general')
    local = JSON.parse(local)
    let pais = local['pais'].toLowerCase()
    pais = this.removeAccents(pais)
    this.clientesArr = this.clientesObj[pais]
    console.log('log:', this.clientesArr)
  }

  removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  chargeTab() {
    setTimeout(() => {
      const $tipoSolicitud = $id("solicitud")
      const $tipo = $id("tipo")
      const $cliente = $id("cliente")
      const $vigencia = $id("vigencia")
      const $moneda = $id("moneda")
      const $clienteSelect = $id("clientesSelect");
      const $revenue = $id("revenue")
      const elementArray = [$tipoSolicitud, $tipo, $vigencia, $moneda, $clienteSelect]
      const indexArray = [
        this.indexTipoSolicitud,
        this.indexTipo,
        this.indexVigencia,
        this.indexMoneda,
        this.indexClientesSelect
      ]
      this.revenue == 'si'
        ? $revenue['selectedIndex'] = 1
        : $revenue['selectedIndex'] = 2
      elementArray.forEach((element, i) => {
        if (element != null)
          element['selectedIndex'] = indexArray[i]
      });
    }, 50)
  }
  get fClient() {
    return this.newClientForm.controls
  }
  ocultarTabs() {
    this.tipo = undefined;
    this.moneda = (<HTMLInputElement>document.getElementById("moneda")).value;
    this.altoCard = 'height: 450px;'
    const element = (<HTMLInputElement>document.getElementById("solicitud"))
    this.tipoSolicitud = element.value;
    this.indexTipoSolicitud = element['options'].selectedIndex
    if (this.tipoSolicitud == "Contrato") {
      this.altoCard = 'height: 520px;'
      this.getCatalogoTipo(this.contenidoDelModal);
    }


    if (this.tipoSolicitud == 'Anexo' || this.tipoSolicitud == 'Otro Sí' || this.tipoSolicitud == 'Convenio Modificatorio' || this.tipoSolicitud == 'Adendum') {
      this.documentAvailable = true
    } else {
      this.documentAvailable = false
    }
  }

  getTipo() {
    const element = (<HTMLInputElement>document.getElementById("tipo"))
    this.tipo = element.value;
    this.indexTipo = element['options'].selectedIndex;
    this.tipoSolicitud == 'Contrato'
      ? (this.tipo == 'Prestación de Servicios'
        ? this.altoCard = 'height: 600px'
        : this.altoCard = 'height: 500px')
      : this.altoCard = 'height: 450px'
    this.vigencia == 'Otro' ? this.fecha = true : false;
    if (this.tipo == 'Prestación de Servicios' && this.tipoSolicitud == 'Contrato') {
      this.documentAvailable = true
    } else {
      this.documentAvailable = false
    }

  }

  getMoneda() {
    const element = (<HTMLInputElement>document.getElementById("moneda"))
    this.moneda = element.value;
    this.indexMoneda = element['options'].selectedIndex;
  }
  getCliente() {
    const element = (<HTMLInputElement>document.getElementById("cliente"))
    this.cliente = element.value;
    this.indexCliente = element['options'].selectedIndex;

  }
  getVigencia() {
    const element = (<HTMLInputElement>document.getElementById("vigencia"))
    this.vigencia = element.value;
    if (this.vigencia != 'Otro') {
      this.indexVigencia = element['options'].selectedIndex;
    }
    this.vigencia == 'Otro' ? this.fecha = true : false;
  }
  getPorcentaje() {
    let porcentajeVal = (<HTMLInputElement>document.getElementById("porcentaje")).value;
    this.porcentaje = porcentajeVal
  }
  getInstrucciones() {
    this.instrucciones = (<HTMLInputElement>document.getElementById("instrucciones")).value;
  }
  getContraprestacion() {
    this.contraprestacion = (<HTMLInputElement>document.getElementById("contraprestacion")).value;
  }

  getClienteSelect() {
    const element = (<HTMLInputElement>document.getElementById("clientesSelect"))
    this.clientesSelect = element.value;
    this.cliente = null
    this.indexClientesSelect = element['options'].selectedIndex;
  }

  getRevenue(e) {
    const value = e.target.value
    this.revenue = value == 'Si' ? 'Si' : 'No'
  }
  getPorcentajeCliente(e) {
    this.porcentajeRevenueCliente = e.target.value
  }

  getPorcentajeEmpresa(e) {
    this.porcentajeRevenueEmpresa = e.target.value
  }

  onFileRfc(event) {
    if (event.target.files.length > 0) {
      this.rfc = event.target.files[0]
    } else {
      this.rfc = null;
    }
  }

  onFileIne(event) {
    if (event.target.files.length > 0) {
      this.ine = event.target.files[0]
    } else {
      this.ine = null;
    }
  }

  onFileActa(event) {
    if (event.target.files.length > 0) {
      this.acta = event.target.files[0]
    } else {
      this.acta = null;
    }
  }

  onFilePoder(event) {
    if (event.target.files.length > 0) {
      this.poder = event.target.files[0]
    } else {
      this.poder = null;
    }
  }

  validateExt(fileName, ext) {
    const availableExt = ext
    for (let ext of availableExt) {
      const validate = fileName.includes(ext)
      if (validate) {
        return true;
      }
    }
    return false
  }

  onFileterminos(event) {
    this.documentAvailable = true;
    if (event.target.files.length > 0) {
      this.archivoTerminos = event.target.files[0]
    } else {
      this.archivoTerminos = null;
    }
  }

  onFileComprobante(event) {
    if (event.target.files.length > 0) {
      this.comprobante = event.target.files[0]
    } else {
      this.comprobante = null;
    }
  }

  getCatalogoSolicitud(DefaultModalContent) {
    this.service.getCatalogoTipoSolicitud({ "tipo": 1 }).subscribe(
      (data: any) => {
        this.selectSolicitud = data;
      },
      err => {
        this.Mensaje = "Error al consumir servicio"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    );

  }

  getCatalogoTipo(DefaultModalContent) {
    this.service.getCatalogoTipoSolicitud({ "tipo": 2 }).subscribe(
      (data: any) => {
        this.selectTipo = data
      },
      err => {
        this.Mensaje = "Error al consumir servicio"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    );
  }

  getClientes(DefaultModalContent) {
    this.empresa = (<HTMLInputElement>document.getElementById("clientesSelect")).value;
    this.service.getClientes({ "empresa": this.empresa }).subscribe(
      data => {
        this.cliente = null;
        this.selectClient = data["data"]
        if (this.selectClient.length > 0) {
          this.clientLenght = true
        } else {
          this.clientLenght = false
        }
      },
      err => {
        this.Mensaje = "Error al consumir servicio clientes"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }

  openClientForm(ClientFormContent) {
    this.modalService.open(ClientFormContent, {
      windowClass: "animated fadeInDown",
    });
    this.newClientForm.reset()
  }

  crearCliente(DefaultModelContent, LoaderEnviarContent) {
    this.sendForm = true
    if (this.newClientForm.invalid) {
      return;
    }
    const data = {
      "nombre-comercial": this.fClient.name.value,
      "razon-social": this.fClient.razon.value,
      "rfc": this.fClient.rfc.value,
      "empresa": this.fClient.empresa.value,
      "pais": JSON.parse(localStorage.getItem("general"))["pais"],
    };


    this.service.crearCliente(data).subscribe(
      (data) => {
        this.modalService.dismissAll();
        this.modalService.open(LoaderEnviarContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      },
      (err) => {
        this.Mensaje = "Error en la petición"
        this.modalService.dismissAll();
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    );
  }

  crearSolcitud(DefaultModalContent) {
    this.processing = true
    const formData = new FormData();
    console.log(
      this.moneda,
      this.cliente,
      this.vigencia,
      this.porcentaje,
      this.instrucciones,
      this.tipoSolicitud,
      this.contraprestacion,
      this.revenue,
      this.empresa,
      this.porcentajeRevenueCliente,
      this.porcentajeRevenueEmpresa
    )
    if (
      this.moneda != null &&
      this.cliente != null &&
      this.vigencia != null &&
      this.instrucciones != null &&
      this.tipoSolicitud != null &&
      this.contraprestacion != null
    ) {
      formData.append("moneda", this.moneda);
      formData.append("vigencia", this.vigencia);
      formData.append("id_cliente", this.cliente);
      formData.append("tipo-de-solicitud", this.tipoSolicitud)
      formData.append("contraprestacion", this.contraprestacion)
      formData.append("cliente", this.empresa)
      formData.append("revenue-share", this.revenue)
      if (this.revenue == "Si") {
        if (this.porcentajeRevenueCliente != null && this.porcentajeRevenueEmpresa != null) {
          formData.append("porcentaje-revenue-share", this.porcentajeRevenueCliente)
          formData.append("porcentaje-empresa", this.porcentajeRevenueEmpresa)
        } else {
          this.Mensaje = "Faltan datos generales"
          this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
          return;
        }
      } else {
        formData.append("porcentaje-revenue-share", '0')
        formData.append("porcentaje-empresa", '0')
      }
      if (this.documentAvailable == true && this.archivoTerminos == null) {
        this.Mensaje = "Falta archivo de terminos y condiciones"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
      if (this.tipoSolicitud == 'Contrato' && this.tipoSolicitud == 'Mediación Mercantil') {
        if (this.porcentaje != null) {
          formData.append("porcentaje-de-comision", this.porcentaje)
        }
        else {
          formData.append("porcentaje-de-comision", '0')
        }
      }
      else {
        formData.append("porcentaje-de-comision", '0')
      }
      formData.append("instrucciones-de-solicitud", this.instrucciones)
    } else {
      if ( !this.cliente || !this.clientLenght ) {
        this.Mensaje = "Este cliente no está en la base de datos";
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
        this.ngOnInit()
        return
      } else {
        this.Mensaje = "Faltan datos generales"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
        // console.log("Faltan datos Generales")
        return;
      }
    }

    if (this.tipoSolicitud == "Contrato" || (this.tipoSolicitud=='Convenio de Confidencialidad')) {
      if (
        this.ine != null &&
        this.rfc != null &&
        this.acta != null &&
        this.poder != null &&
        this.comprobante != null 
      ) {
        formData.append("tipo", this.tipo)
        const formulario1 = new FormData()
        formulario1.append("file", this.rfc)
        this.service.uploadFile(
          formulario1
        ).subscribe(
          data => {
            formData.append("rfc", data["url"]);
            const formulario2 = new FormData()
            formulario2.append("file", this.acta)
            this.service.uploadFile(
              formulario2
            ).subscribe(
              data => {
                formData.append("acta-constitutiva", data["url"]);
                const formulario3 = new FormData()
                formulario3.append("file", this.poder)
                this.service.uploadFile(
                  formulario3
                ).subscribe(
                  data => {
                    formData.append("poder-del-rep-legal", data["url"]);
                    const formulario4 = new FormData()
                    formulario4.append("file", this.ine)
                    this.service.uploadFile(
                      formulario4
                    ).subscribe(
                      data => {
                        formData.append("identificacion-oficial", data["url"]);
                        const formulario5 = new FormData()
                        formulario5.append("file", this.comprobante)
                        this.service.uploadFile(
                          formulario5
                        ).subscribe(
                          data => {
                            formData.append("comprobante-de-domicilio", data["url"]);
                            if (this.tipo == "Prestación de Servicios") {
                              const formulario6 = new FormData()
                              formulario6.append("file", this.archivoTerminos)
                              this.service.uploadFile(
                                formulario6
                              ).subscribe(
                                data => {
                                  this.processing = true
                                  formData.append("terminos-condiciones", data["url"])

                                  this.service.crearSolicitud(formData).subscribe(
                                    data => {
                                      this.processing = true
                                      this.router.navigate(["/comercial/dashboard"])
                                    },
                                    err => {
                                      console.log(err);
                                    }
                                  )
                                },
                                err => {
                                  console.log("Error en la peticion", err);
                                  this.Mensaje = "Error en la petición";
                                  this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
                                }
                              )
                            } else {
                              this.service.crearSolicitud(formData).subscribe(
                                data => {
                                  this.processing = true
                                  this.router.navigate(["/comercial/dashboard"])
                                },
                                err => {
                                  console.log(err);
                                  this.Mensaje = "Error en la petición";
                                  this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
                                }
                              )
                            }
                          },
                          err => {
                            console.log("Error en la peticion", err);
                            this.Mensaje = "Error en la petición"
                            this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
                          }
                        )
                      },
                      err => {
                        console.log("Error en la peticion", err);
                        this.Mensaje = "Error en la petición"
                        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
                      }
                    )
                  },
                  err => {
                    console.log("Error en la peticion", err);
                    this.Mensaje = "Error en la petición";
                    this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
                  }
                )
              },
              err => {
                console.log("Error en la peticion", err);
                this.Mensaje = "Error en la petición";
                this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
              }
            )
          },
          err => {
            console.log("Error en la peticion", err);
            this.Mensaje = "Error en la petición";
            this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
          }
        )
      } else {
        console.log("Falta documentacion")
        this.Mensaje = "Falta documentación"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
        return;
      }
    } else {
      if(this.tipoSolicitud=="Anexo" || this.tipoSolicitud=="Otro Sí" || this.tipoSolicitud=="Convenio Modificatorio" || this.tipoSolicitud=="Adendum"){
        const formulario6 = new FormData()
        formulario6.append("file",this.archivoTerminos)
        this.service.uploadFile(
          formulario6
        ).subscribe(
          data => {
            this.processing = true
            formData.append("terminos-condiciones", data["url"])

            this.service.crearSolicitud(formData).subscribe(
              data => {
                this.processing = true
                this.router.navigate(["/comercial/dashboard"])
              },
              err => {
                console.log(err);
              }
            )
          },
          err => {
            console.log("Error en la peticion",err);
            this.Mensaje = "Error en la petición";
            this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
          }
        )
      }else{
        this.service.crearSolicitud(formData).subscribe(
          data => {
            this.router.navigate(["/comercial/dashboard"])
          },
          err => {
            console.log(err);
          }
        )        
      }      

    }
  }

  getFormTemp() {
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById("solicitud")).value = this.tipoSolicitud;
      (<HTMLInputElement>document.getElementById("moneda")).value = this.moneda;
      (<HTMLInputElement>document.getElementById("vigencia")).value = this.vigencia;
      (<HTMLInputElement>document.getElementById("cliente")).value = this.cliente;
      (<HTMLInputElement>document.getElementById("porcentaje")).value = this.porcentaje;
      (<HTMLInputElement>document.getElementById("instrucciones")).value = this.instrucciones;
      (<HTMLInputElement>document.getElementById("contraprestacion")).value = this.contraprestacion;
      (<HTMLInputElement>document.getElementById("clientesSelect")).value = this.clientesSelect;
    }, 500);
  }
}
