import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { ValidatorFn, FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})



export class TabComercialComponent implements OnInit {

  ine: any;
  rfc: any;
  acta: any;
  poder: any;
  comprobante: any;
  Mensaje: any;
  motivo="";
  
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  
  @ViewChild('DefaultModelContent') contenidoDelModal;

  formularioRevision: FormGroup;
  detalles_solicitud: any;
  conversacion: any;

  archivo: any;
  doc_final: any;
  check = false;

  forArr: any[]

user: any;
  newRevisionForm: FormGroup;
  submitted: any
  constructor(
    private router: Router,
    private service: ApiService,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private formBuilder: FormBuilder
  ) {

    this.doc_final = {
      "archivo":"",
      "nombre-archivo":"",
      "fecha": ""
    }
    
    localStorage.getItem("detalles_solicitud") == null ? router.navigate(["/comercial/dashboard"]) : "";
    this.detalles_solicitud = JSON.parse(localStorage.getItem("detalles_solicitud"))
    if(this.detalles_solicitud['motivo-rechazo']!=null && this.detalles_solicitud['motivo-rechazo']!=''){
      this.motivo=this.detalles_solicitud['motivo-rechazo'].replace(/<[^>]+>/g, '');
    }
    console.log(this.detalles_solicitud)

  }


  ngOnInit(): void {
    this.user = localStorage.getItem('nombre')
    this.formularioRevision = this.formBuilder.group({
      archivo: ['', [Validators.required, ] ],
      comentario: ['', Validators.required],
    });
    this.newRevisionForm = this.formBuilder.group({
      comment: ['',Validators.required],
      file: ['',Validators.required]
    })
    setTimeout(() => {
      if(
        (this.detalles_solicitud['tipo-de-solicitud-2']=='Contrato' && this.detalles_solicitud['accion']=='Aprobar Solicitud')
          || 
        (this.detalles_solicitud['tipo-de-solicitud-2']!='Contrato' && this.detalles_solicitud['abogado']!=undefined))
      {
      }
      this.getRevisiones(this.contenidoDelModal)
    }, 500);
  }

  showSidebar(event){}
  showCompose(event){
    const toggleIcon = document.getElementById('compose-sidebar');
    const toggleOverlay = document.getElementById('app-content-overlay');
    if (event.currentTarget.className === 'close close-icon' || 'app-content-overlay') {
      this.renderer.removeClass(toggleIcon, 'show');
      this.renderer.removeClass(toggleOverlay, 'show');
    }
  }
  showComposeSidebar(event){
    const toggleIcon = document.getElementById('compose-sidebar');
    const toggleSidebar = document.getElementById('sidebar-left');
    const toggleOverlay = document.getElementById('app-content-overlay');
    if (event.currentTarget.className === 'btn btn-info btn-glow btn-block my-2 compose-btn') {
      this.renderer.addClass(toggleIcon, 'show');
     this.renderer.removeClass(toggleSidebar, 'show');
      this.renderer.addClass(toggleOverlay, 'show');
    } else if (event.currentTarget.className === 'btn btn-danger btn-glow btn-block my-2 compose-btn show') {
      this.renderer.removeClass(toggleIcon, 'show');
     }
  }
  showEmailMenu(id, emailMenu){}
  validatorExt(arrayExt): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const result = !this.validateExt(control.value, arrayExt)
      return result?{ext: {value: control.value}}:null
    }
  }
  get fNewComment() {
    return this.newRevisionForm.controls
  }


  onFileRfc(event){
    const fileExt = ['pdf','doc','docx']
    if (this.validateExt(event.target.files[0].name, fileExt)) {
      this.rfc = event.target.files[0]
    }else {
      this.rfc = null;
    }
  }

  onFileIne(event){
    const fileExt = ['pdf','jpg','png','jpeg']
    if (this.validateExt(event.target.files[0].name, fileExt)) {
      this.ine = event.target.files[0]
    }else {
      this.ine = null;
    }
  }

  onFileActa(event){
    const fileExt = ['pdf','doc','docx']
    if (this.validateExt(event.target.files[0].name, fileExt)) {
      this.acta = event.target.files[0]
    }else {
      this.acta = null;
    }
  }

  onFilePoder(event){
    const fileExt = ['pdf','doc','docx']
    if (this.validateExt(event.target.files[0].name, fileExt)) {
      this.poder = event.target.files[0]
    }else {
      this.poder = null;
    }
  }

  onFileComprobante(event){
    const fileExt = ['pdf','doc','docx']
    if (this.validateExt(event.target.files[0].name, fileExt)) {
      this.comprobante = event.target.files[0]
    }else {
      this.comprobante = null;
    }
  }

  consultaFirma(){
    this.detalles_solicitud["abogado-firma"] == "Si" 
      ? 
        ((<HTMLInputElement>document.getElementById("abogado")).checked = true)
      :
      ((<HTMLInputElement>document.getElementById("abogado")).checked = false)
    
      this.detalles_solicitud["comercial-firma"] == "Si"
      ? 
        ((<HTMLInputElement>document.getElementById("comercial")).checked = true)
      :
      ((<HTMLInputElement>document.getElementById("comercial")).checked = false)
  }

  consulta(){
    this.detalles_solicitud["comercial"] == "Si" 
      ? 
        ((<HTMLInputElement>document.getElementById("colorCheck1")).checked = true)
      :
      ((<HTMLInputElement>document.getElementById("colorCheck1")).checked = false)
    
      this.detalles_solicitud["abogado-2"] == "Si" 
      ? 
        ((<HTMLInputElement>document.getElementById("colorCheck2")).checked = true)
        
      :
      ((<HTMLInputElement>document.getElementById("colorCheck2")).checked = false);

    ((<HTMLInputElement>document.getElementById("colorCheck2")).disabled = true)

  }
  actualizar(option: any){
    const data = {
      "id_solicitud": this.detalles_solicitud["item_id"],
      "accion": option
    }
    this.service.aprobarDocumentacion(data).subscribe(
      data => {
        this.router.navigate(['/comercial/dashboard'])
      },
      err => {
        console.log("Error al consumir la peticion",err)
      }
    )
  }
  validar(DefaultModelContent){
    this.check = !this.check
    this.detalles_solicitud["comercial"] == "Si"
      ? 
        this.detalles_solicitud["comercial"] = "No"
      :
      this.detalles_solicitud["comercial"] = "Si"

      localStorage.setItem("detalles_solicitud",JSON.stringify(this.detalles_solicitud))
      const data ={
        "id_solicitud": this.detalles_solicitud["item_id"],
        "accion": this.detalles_solicitud["comercial"],
        "usuario": this.detalles_solicitud["abogado-2"]
      }
    console.log(data)
    this.service.aprobarBorrador(data).subscribe(
      data => {
        console.log("validar", data);
      },
      err => {
        this.detalles_solicitud["comercial"] == "Si"
      ? 
        this.detalles_solicitud["comercial"] = "No"
      :
      this.detalles_solicitud["comercial"] = "Si"

      localStorage.setItem("detalles_solicitud",JSON.stringify(this.detalles_solicitud))
        this.Mensaje = "Error al realizar la petición"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }

  validarFirma(DefaultModelContent){
    this.check = !this.check
    this.detalles_solicitud["comercial-firma"] == "Si"
      ? 
        this.detalles_solicitud["comercial-firma"] = "No"
      :
      this.detalles_solicitud["comercial-firma"] = "Si"
    
      localStorage.setItem("detalles_solicitud",JSON.stringify(this.detalles_solicitud))
      const data ={
        "id_item": this.detalles_solicitud["item_id"],
        "checkbox": this.detalles_solicitud["comercial-firma"]
      }
    this.service.checkAbogadoComercial(data).subscribe(
      data => {
        console.log(data);
      },
      err => {
        this.detalles_solicitud["comercial-firma"] == "Si"
      ? 
        this.detalles_solicitud["comercial-firma"] = "No"
      :
      this.detalles_solicitud["comercial-firma"] = "Si"

      localStorage.setItem("detalles_solicitud",JSON.stringify(this.detalles_solicitud))
        console.log("Error al realizar la peticion", err);
        this.Mensaje = "Error al realizar la petición"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }

  SmallModel(SmallModelContent) {
    this.modalService.open(SmallModelContent, { windowClass: 'animated fadeInDown', size: 'sm' });
  }
  Revisiones(RevisionContent) {
    this.modalService.open(RevisionContent, { windowClass: 'animated fadeInDown', size: 'sm' });
  }
  getRevisiones(DefaultModelContent){
    console.log('Getrevisiones')
    const data ={
      "id_solicitud": this.detalles_solicitud["item_id"]
    }
    this.service.getRevision(data).subscribe(
      (data: any) =>{
        this.conversacion = data;
        data.forEach(
          element => {
            if(element["archivo"]!=undefined){
              this.doc_final = element;
            }
        });
        if(this.doc_final==null || this.doc_final==undefined){
          this.doc_final = {
            "archivo":"",
            "nombre-archivo":"",
            "fecha": ""
          }
        }
        console.log(data)
      },
      err => {
        console.log("error al consumir peticion", err);
        this.Mensaje = "Error al realizar la petición"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }

  get f() {
    return this.formularioRevision.controls;
  }

  CrearRevisiones(RevisionContent, LoaderEnviarContent) {
    this.submitted = true
    
    if( this.archivo==null ){
      //this.modalService.open(RevisionContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      const formData = new FormData();
      formData.append('mensaje', this.fNewComment.comment.value)
      formData.append('id_solicitud', this.detalles_solicitud["item_id"])

      this.service.crearRevision(formData).subscribe(
        data => {
          window.location.reload()
        },
        err => {
          console.log("Error al petir peticion", err);
        }
      )

    } else {
      const formData = new FormData();
      formData.append('archivo', this.archivo);
      formData.append('mensaje', this.fNewComment.comment.value)
      formData.append('id_solicitud', this.detalles_solicitud["item_id"])

      this.service.crearRevision(formData).subscribe(
        data => {
          window.location.reload()
        },
        err => {
          console.log("Error al petir peticion", err);
        }
      )
    }
  }

  onFileSelect(event) {
    if (event.target.files.length>0) {
      this.archivo = event.target.files[0]
    }
    else {
      this.archivo = null;
    }
  }

  validateExt(fileName, ext) {
    console.log(fileName)
    const availableExt = ext
    for(let ext of availableExt) {
      const validate = fileName.includes(ext)
      if (validate) {
        return  true;  
      }
    }
    return false
  }

   onCheck() {
    this.check = !this.check
    console.log(this.check)
  }

  refrescar(){
    this.modalService.dismissAll();
    window.location.reload();
  }

  subirDocumentacion(){
    console.log(this.acta,this.poder,this.ine,this.comprobante,this.rfc)
    if(
      this.acta==null ||
      this.poder==null ||
      this.ine==null ||
      this.comprobante==null ||
      this.rfc==null
    ){
      console.log("falta documentacion")
      return;
    }
    const formData = new FormData();
    formData.append("item_id",this.detalles_solicitud["item_id"])
    const formulario1 = new FormData()
    formulario1.append("file",this.rfc)
    this.service.uploadFile(
      formulario1
    ).subscribe(
      data =>{
        formData.append("rfc",data["url"]);
        const formulario2 = new FormData()
        formulario2.append("file",this.acta)
        this.service.uploadFile(
          formulario2
        ).subscribe(
          data => {
            formData.append("acta-constitutiva",data["url"]);
            const formulario3 = new FormData()
            formulario3.append("file",this.poder)
            this.service.uploadFile(
              formulario3
            ).subscribe(
              data => {
                formData.append("poder-del-rep-legal",data["url"]);
                const formulario4 = new FormData()
                formulario4.append("file",this.ine)
                this.service.uploadFile(
                  formulario4
                ).subscribe(
                  data =>{
                    formData.append("identificacion-oficial",data["url"]);
                    const formulario5 = new FormData()
                    formulario5.append("file",this.comprobante)
                    this.service.uploadFile(
                      formulario5
                    ).subscribe(
                      data => {
                        formData.append("comprobante-de-domicilio",data["url"]);
                        this.service.subir_documento_rechazado(formData).subscribe(
                          data => {
                            //window.location.reload()
                            this.router.navigate(["/comercial/dashboard"])
                          },
                          err => {
                            this.Mensaje = "Error en la petición";
                            this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' })
                          }
                        )
                      },
                      err => {
                        this.Mensaje = "Error en la petición"
                        this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' }) 
                      }
                    )
                  },
                  err => {
                    this.Mensaje = "Error en la petición"
                    this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' })
                  }
                )
              },
              err => {
                this.Mensaje = "Error en la petición";
                this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' })
              }
            )
          },
          err => {
            this.Mensaje = "Error en la petición";
            this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' })
          }
        )
      },
      err => {
        this.Mensaje = "Error en la petición";
        this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }

  removeSimbolo(contraprestacion){
    return contraprestacion.replace("$","")
  }
}
