<div class="app-content content">
  <div class="content-wrapper p-0">
      <div class="content-body">
          <section id="minimal-statistics">

<div class="container-lg">

    <!-- Basic tabs start -->
        <section id="basic-tabs-components">
            <div class="col-12">
            <div class="col-lg-12 px-1">
                <div class="card cnt-main my-2">
                <div class="card-content" style="altoCard">
                    <div class="card-body">
                      <article>
                        <div class="group-button row justify-content-end align-items-center">
                          <button type="button" class="mr-2 font-medium-1 text-bold-700 btn btn-info btn-min-width" (click)="openClientForm(ClientFormContent)">
                            Nuevo Cliente</button>
                        </div>
                      </article>
                        <ngb-tabset>
                          <!-- <div class="nav nav-tabs"> -->
                          <ngb-tab>
                            <ng-template ngbTabTitle>
                              <span class="tab-title" (click)="chargeTab()">Detalles</span>
                            </ng-template>
                            <ng-template ngbTabContent>
                              <div class="card-body">
                                
                                  <div  >
                                    <!-- TIPO DE SOLICITUD -->
                                    <div class="form-group row align-items-center mx-auto">
                                      <label class="col-md-3 label-control font-medium-2 text-bold-400" for="eventRegInput1">Tipo de Solicitud<span class="red">*</span></label>
                                      <div class="col-md-9">
                                        <div class="input-group" *ngIf="selectSolicitud!=null">
                                          <!-- COLOCAR SELECT -->
                                          <select class="select"  [ngClass]="tipoSolicitud == undefined ? 'gris':'' " id="solicitud"   placeholder="Ingresa tipo de solicitud" (change)="ocultarTabs()">
                                          <option hidden selected >Ingresar Tipo de Solicitud</option>
                                            <option class="negro" *ngFor="let data of selectSolicitud" [value]="data.title">
                                            {{ data.title }}
                                            </option>
                                          </select>  
                                        </div>
                                      </div>
                                    </div>
                                    <!-- TIPO -->
                               		<div class="form-group row align-items-center mx-auto" *ngIf="tipoSolicitud=='Contrato' && selectTipo!=null">
                                      <label class="col-md-3 label-control font-medium-2 text-bold-400" for="eventRegInput1">Tipo<span class="red">*</span></label>
                                      <div class="col-md-9">
                                        <div class="input-group">
                                          <select class="select" [ngClass]="tipo == undefined ? 'gris':'' " id="tipo" (change)="getTipo()">
                                            <option hidden selected>Ingresa Tipo</option>
                                            
                                            <option class="negro" *ngFor="let data of selectTipo" [value]="data.title">
                                              {{ data.title }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div> 
                                    <!-- DOCUMENTO -->
                              		<div class="form-group row align-items-center mx-auto" *ngIf="(tipo=='Prestación de Servicios' && tipoSolicitud=='Contrato') || (tipoSolicitud=='Adendum' || tipoSolicitud=='Convenio Modificatorio' || tipoSolicitud=='Anexo' || tipoSolicitud=='Otro Sí')">
                                      <label class="col-md-3 label-control font-medium-2 text-bold-400">Selecciona Archivo de Términos y Condiciones<span class="red">*</span></label>
                                      <div class="col-md-9">
                                        <label id="projectinput8" class="file center-block ">
                                          <input class="input" type="file" id="file" accept=".docx,.doc,.pdf" style="margin-left: 3px;" (change)="onFileterminos($event)">
                                          <span class="file-custom"></span>
                                        </label>
                                      </div>
                                    </div> 
                                    <!-- CATALOGO DE CLIENTES -->
                                    <div class="form-group row align-items-center mx-auto">
                                      <label class="col-md-3 label-control font-medium-2 text-bold-400" for="eventRegInput1"> Empresa del Grupo <span class="red">*</span></label>
                                      <div class="col-md-9">
                                        <div class="input-group"> 
                                          <select class="select" [ngClass]="empresa == undefined ? 'gris':'' " id="clientesSelect" (change)="getClientes(DefaultModalContent)">
                                            <option hidden selected> Ingresar Nombre de la Empresa </option>
                                            <option class="negro" *ngFor="let clienteVal of clientesArr" [value]="clienteVal">
                                            {{ clienteVal }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- CLIENTE -->
                                    <div class="form-group row align-items-center mx-auto"  *ngIf="selectClient!=null && clientLenght">
                                        <label class="col-md-3 label-control font-medium-2 text-bold-400" for="eventRegInput1">Cliente o Razón Social<span class="red">*</span></label>
                                        <div class="col-md-9">
                                          <div class="input-group">
                                            <select class="select" [ngClass]="cliente == undefined ? 'gris':'' " id="cliente" (change)="getCliente()">
                                              <option  hidden selected> Ingresar Cliente o Razón Social</option>
                                              <option class="negro" *ngFor="let data of selectClient" [value]="data.item_id">
                                              {{ data['nombre-comercial'] }}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                    </div>     
                                    <!-- CONTRAPRESTACION -->
                                    <div class="form-group row no-wrap align-items-center mx-auto">
                                      <label class="col-xl-3 label-control font-medium-2 text-bold-400" for="eventRegInput2">Contraprestación<span class="red">*</span></label>
                                      <div class="col-xl-9">
                                        <div class="input-group col-12 p-0">
                                          <div class="d-inline-block input-group-append col-1 p-0">
                                            <span class="bg-ike justify-content-center px-0 input-group-text" id="basic-addon2">
                                              <select class="badge-select form-select size" id="moneda" aria-label="Default select example" (change)="getMoneda()">
                                                <option selected class="size">MXN</option>
                                                <option value="USD" class="size">USD</option>
                                              </select>
                                            </span>
                                          </div>
                                          <input class="contraprestacion col-11" [(ngModel)]="contraprestacion" type="text" id="contraprestacion"  placeholder="Ingresar Contraprestación" (change)="getContraprestacion()"
                                            aria-describedby="basic-addon2">
                                        </div>
                                      </div>
                                    </div>
                                    <!-- VIGENCIA -->
                                    <div class="form-group row align-items-center mx-auto">
                                      <label class="col-md-3 label-control font-medium-2 text-bold-400" for="eventRegInput3">Vigencia<span class="red">*</span></label>
                                      <div class="col-md-9">
                                        <div class="input-group">
                                          <!-- COLOCAR SELECT -->
                                          <select *ngIf="!fecha" class="select"  [ngClass]="vigencia == undefined ? 'gris':'' " id="vigencia"  (change)="getVigencia()">
                                            <option  hidden selected> Ingresar Vigencía</option>
                                            <option class="negro" *ngFor="let periodo of periodosArr" [value]="periodo">
                                            {{ periodo }}
                                            </option>
                                          </select>
                                          <input [(ngModel)]="vigencia" *ngIf="fecha" id="vigencia" class="input"  type="date" (change)="getVigencia()" onkeydown="return false">
                                        </div>
                                      </div>
                                    </div>
 
                                    <!-- REVENUE SHARE -->
                                    <div class="form-group row align-items-center mx-auto">
                                      <label class="col-md-3 label-control font-medium-2 text-bold-400" for="eventRegInput1">Revenue Share</label>
                                      <div class="col-md-9">
                                        <div class="input-group"> 
                                          <select class="select" [ngClass]="revenue == undefined ? 'gris':'' " id="revenue" (change)="getRevenue($event)">
                                            <option  hidden selected> Ingresar Valor</option>
                                            <option class="negro" value='Si'>Si</option>
                                            <option class="negro" value='No'>No</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div> 
                                    <!-- COMISION REVENUE -->
                                    <div *ngIf="(revenue == 'Si')" class="form-group row align-items-center mx-auto">
                                      <label class="col-md-3 label-control font-medium-2 text-bold-400" for="eventRegInput4">Porcentaje del Cliente</label>
                                      <div class="col-md-9">
                                        <input class="input" [(ngModel)]="porcentajeRevenueCliente"  type="number" id="porcentajeRevenueCliente" placeholder="Ingresar Porcentaje de Comisión" (change)="getPorcentajeCliente($event)">
                                      </div>
                                    </div>
                                    <div *ngIf="(revenue == 'Si')" class="form-group row align-items-center mx-auto">
                                      <label class="col-md-3 label-control font-medium-2 text-bold-400" for="eventRegInput4">Porcentaje de la Empresa</label>
                                      <div class="col-md-9">
                                        <input class="input" [(ngModel)]="porcentajeRevenueEmpresa"  type="number" id="porcentajeRevenueEmpresa" placeholder="Ingresar Porcentaje de Comisión" (change)="getPorcentajeEmpresa($event)">
                                      </div>
                                    </div>
                                    <!-- COMISION -->
                                    <div *ngIf="(tipoSolicitud == 'Contrato' && tipo == 'Mediación Mercantil')" class="form-group row align-items-center mx-auto">
                                      <label class="col-md-3 label-control font-medium-2 text-bold-400" for="eventRegInput4">Porcentaje de Comisión</label>
                                      <div class="col-md-9">
                                        <input class="input" [(ngModel)]="porcentaje"  type="text" id="porcentaje" placeholder="Ingresar Porcentaje de Comisión" (change)="getPorcentajeRevenue($event)">
                                      </div>
                                    </div>                                   
                                    <!-- INSTRUCCIONES -->
                                    <div class="form-group row align-items-center mx-auto">
                                      <label class="col-md-3 label-control font-medium-2 text-bold-400" for="eventRegInput5">Instrucciones de Solicitud<span class="red">*</span></label>
                                      <div class="col-md-9">
                                        <textarea [(ngModel)]="instrucciones" id="instrucciones" rows="1"  placeholder="Ingresar Instrucciones de Solicitud"(change)="getInstrucciones()"></textarea>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </ng-template>
                          </ngb-tab>
                          <ngb-tab *ngIf="(tipoSolicitud=='Contrato') || (tipoSolicitud=='Convenio de Confidencialidad') ">
                            <ng-template ngbTabTitle><span class="font-medium-3">Documentación</span></ng-template>
                            <ng-template ngbTabContent>
                               <!-- Basic form layout section start -->
                              <section id="basic-form-layouts">
                                <div class="row">
                                  <div class="col-md-12 p-2">

                                        <!--Acta-->
                                        <div class="row form-group px-2 row">
                                          <label class="col-2">Acta Constitutiva<span class="red">*</span></label>
                                          <label id="projectinput9" class="col-4 file center-block ">
                                            <input type="file" id="acta" accept=".pdf" (change)="onFileActa($event)" style="margin-left: 3px;">
                                            <span class="file-custom"></span>
                                          </label>
                                        </div>

                                        <!--Poder-->
                                        <div class="row form-group px-2 row">
                                          <label class="col-2">Poder del Rep. Legal<span class="red">*</span></label>
                                          <label id="projectinput9" class="col-4 file center-block ">
                                            <input type="file" id="poder" accept=".pdf" (change)="onFilePoder($event)"style="margin-left: 3px;">
                                            <span class="file-custom"></span>
                                          </label>
                                        </div>

                                        <!--INE-->
                                        <div class="row form-group px-2 row">
                                          <label class="col-2">INE/Pasaporte (Identificación Oficial)<span class="red">*</span></label>
                                          <label id="projectinput9" class="col-4 file center-block">
                                            <input type="file" id="ine" accept=".pdf" (change)="onFileIne($event)"style="margin-left: 3px;">
                                            <span class="file-custom"></span>
                                          </label>
                                        </div>
                                        
                                        <!--Comprobante-->
                                        <div class="row form-group px-2 row">
                                          <label class="col-2">Comprobante de Domicilio<span class="red">*</span></label>
                                          <label id="projectinput9" class="col-4 file center-block">
                                            <input type="file" id="comprobante" accept=".pdf" (change)="onFileComprobante($event)"style="margin-left: 3px;">
                                            <span class="file-custom"></span>
                                          </label>
                                        </div>

                                        <!--RFC-->
                                        <div class="row form-group px-2 row">
                                          <label class="col-2">R.F.C.<span class="red">*</span></label>
                                          <label id="projectinput9" class="col-4 file center-block">
                                            <input type="file" id="rfc" accept=".pdf" (change)="onFileRfc($event)"style="margin-left: 3px;">
                                            <span class="file-custom"></span>
                                          </label>
                                        </div>

                                  </div>
                                </div>       
                              </section>
                            </ng-template>
                          </ngb-tab>
                          <!-- </div> -->
                      </ngb-tabset>
                    </div>
                </div>
                </div>
                
            </div>
            <div class="alert alert-info text-white text-center text-align-items-center" *ngIf="processing">
              <i class="la la-coffee"></i> Cargando Archivos, este proceso puede tardar varios minutos... <i class="la la-coffee"></i>
          </div>
            <div class="col-12">
              <button type="button" (click)="crearSolcitud(DefaultModelContent)" class="ml-4 float-right mb-2 font-medium-2 text-bold-700 btn btn-info btn-min-width btn-mrg">
                Enviar Solicitud</button>
            </div>

            </div>
        </section>
</div>
</section>
      </div>
  </div>
</div>

<ng-template class="modal text-left" #ClientFormContent let-d="dismiss">
	<div class="modal-header">
			<!-- TODO REVISAR ORTOGRAFIA -->
			<label class="modal-title text-bold-600" id="myModalLabel33">Agregar Cliente</label>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
	</div>
  <form class="form-horizontal" [formGroup]="newClientForm" (ngSubmit)="crearCliente(DefaultModelContent,LoaderEnviarContent)">
	<div class="modal-content py-2 px-3">  
      <div class="form-group row " >
          <label class="col-12 text-bold-400 font-medium-2">
          Nombre Comercial<span class="red">*</span></label>
          <input [ngClass]="{ 'is-invalid': sendForm&&fClient.name.errors}" type="text" formControlName="name" id="nombre" placeholder="Nombre Comercial" class="form-control" required >
          <div *ngIf="sendForm&&fClient.name.errors"  class="invalid-feedback">
              <div *ngIf="fClient.name.errors.required">Este campo es obligatorio*</div>
          </div>
       </div>
       <div class="form-group row " >
          <label class="col-12 text-bold-400 font-medium-2">
          Razón Social<span class="red">*</span></label>
          <input [ngClass]="{ 'is-invalid': sendForm&&fClient.razon.errors}" type="text" formControlName="razon" id="razon" placeholder="Razón Social" class="form-control" required >
          <div *ngIf="sendForm&&fClient.razon.errors"  class="invalid-feedback">
              <div *ngIf="fClient.razon.errors.required">Este campo es obligatorio*</div>
          </div>
       </div>
<!--        <div class="form-group row " >
        <label class="col-4 text-bold-400 font-medium-2">
        Empresa<span class="red">*</span></label>
        <input [ngClass]="{ 'is-invalid': sendForm&&fClient.name.errors}" type="text" formControlName="razon" id="razon" placeholder="Empresa del grupo" class="form-control">
     </div> -->
       <div class="form-group row " >
          <label class="col-12 text-bold-400 font-medium-2">
          Empresa del grupo</label>
          <div class="input-group"> 
              <select class="select" [ngClass]="fClient.empresa.value == 'Ingresar Nombre de la Empresa' ? 'gris':'' " formControlName="empresa" id="clientesSelect">
                <option hidden selected> Ingresar Nombre de la Empresa </option>
                <option class="negro" *ngFor="let clienteVal of clientesArr" [value]="clienteVal">
                {{ clienteVal }}
                </option>
              </select>
            </div>
       </div> 
       <div class="form-group row " >
          <label class="col-12 text-bold-400 font-medium-2">
              R.F.C.<span class="red">*</span></label>
          <input [ngClass]="{ 'is-invalid': sendForm&&fClient.rfc.errors}" type="text" formControlName="rfc" id="rfc" placeholder="R.F.C." class="form-control" required >
          <div *ngIf="sendForm&&fClient.rfc.errors"  class="invalid-feedback">
              <div *ngIf="fClient.rfc.errors.required">Este campo es obligatorio*</div>
          </div>
       </div>
	</div>
  <div class="modal-footer">
    <button type="submit" class="font-medium-1 text-bold-600 btn btn-info">Agregar Cliente</button>
  </div>
</form>
</ng-template>

      <!-- Modal Faltan datos generales -->
      <ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
        <div class="modal-header">
          <h4 class="modal-title" >Algo salió mal, inténtalo nuevamente</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="modal-body icon-alert mt-3">
            <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60" >
        </div>
        <h4 class="icon-alert mb-3">{{Mensaje}}</h4>
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info"(click)="d('Close modal')">Aceptar</button>
        </div>
      </ng-template>


  <!-- Modal para tab loader -->
  <section id="modal-sizes">
    <div class="col-12">
       <div class="row mb-2">
          <div class="col-lg-4 col-md-6 col-sm-12">
             <div class="form-group">
                <!-- Modal -->
                <ng-template class="modal text-left" #LoaderEnviarContent let-c="close" let-d="dismiss">
                   <div class="modal-sm">
                      <div class="modal-header">
                         <h5 id="myModalLabel21">Cliente Creado</h5>
                         <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                         <span aria-hidden="true">&times;</span>
                         </button>
                      </div>
                      <div class="card-content">
                         <div class="card-body text-center mt-1">
                            <h4>Proceso Exitoso</h4>
                         </div>
                      </div>
                      <div class="modal-footer">
                         <button type="button" class="btn btn-info btn-outline-secondary"
                            (click)="d('Close modal')">Aceptar</button>
                      </div>
                   </div>
                </ng-template>
             </div>
          </div>
       </div>
    </div>
 </section>

      
 