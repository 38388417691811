<div class="mi-container">
    <div class="app-content content" *ngIf="isPageLoaded">
    <div class="content-wrapper p-0">
        <div class="login content-body">
            <!-- HEADER -->
            <div class="mb-2 main-header"></div>
                <div class="col-12 d-flex align-items-center justify-content-center formulario">
                    <!-- box-shadow-2 -->
                    <div class="col-lg-4 col-md-8 col-10 box effect7  p-0 mb-2">
                        <article class="card  p-1 m-0 ">
                            <header  class="card-header py-0">
                                <div class="card-title text-center">
                                    <img src="../../assets/custom/ike.png" alt="branding logo" width="85" height="auto">
                                </div>
                                <p class=" text-center text-bold-500 sesion">Iniciar Sesión</p>
                                <hr>
                            </header>
                            <main class="card-content mrg-tp">
                                <div class="card-body py-0 ">
                                    <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                                        <label class="my-2 font-medium-3 text-bold-600" for="user-name">Usuario</label>
                                        <fieldset class="mb-0 form-group position-relative has-icon-left">
                                            <input type="text" formControlName="email" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="user-name"
                                                placeholder="Nombre de Usuario" required>
                                            <div class="form-control-position">
                                                <i class="la la-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Usuario requerido*</div>
                                            </div>
                                             <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.pattern">Correo invalido*</div>
                                            </div> 
                                        </fieldset>
                                            <label class="my-2 font-medium-3 text-bold-600" for="password">Contraseña</label>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="password" placeholder="Contraseña"
                                                formControlName="password" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Contraseña requerida*</div>
                                            </div>
                                        </fieldset>
                                        <div class="text-right text-sm-right info "><a (click)="openDefaultModel(DefaultModelContent)"
                                            class="card-link">Olvide mi contraseña</a>
                                            
                                        </div>
                                        

                                        <button type="submit" class="btn my-1 btn-info btn-block mt-2 mb-4" (click)="SmallModel(SmallModelContent)">
                                            <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                            <i class="feather ft-unlock" *ngIf="!submitted"></i> Acceder
                                        </button>
                                    </form>
                                </div>
                            </main>
                        </article>
                        </div>
                    </div>
           
        </div>
    </div>
</div>
</div>

<!-- Modal para usuario o contraseña incorrecta -->
<section id="modal-sizes">
      <div class="col-12">
              <div class="row my-2">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <!-- Modal -->
                    <ng-template class="modal text-left" #SmallModelContent let-c="close" let-d="dismiss">
                      <div class="modal-sm">
                        <div class="modal-header">
                          <h5 id="myModalLabel21">Acceso Incorrecto</h5>
                          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body icon-alert mt-3">
                            <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60" >
                        </div>
                        <h4 class="icon-alert mb-3">{{Mensaje}}</h4>
                        
                        <div class="modal-footer">
                          <button type="button" class="btn btn-info btn-outline-secondary"
                            (click)="d('Close modal')">Aceptar</button>
                        </div>
                      </div>
                    </ng-template>
                </div>
              </div>
   
      </div>
  </section>

      <!-- Modal para recuperar contraseña -->
      <ng-template #DefaultModelContent let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel1">Te enviaremos tu contraseña</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
            <form class="form-horizontal" (ngSubmit)="recuperar" [formGroup]="rememberForm">
                <div class="form-group row mb-3">
                <label class="font-medium-2 ml-1 mt-2 mb-1">Correo Electrónico <span class="red">*</span></label>
                <input formControlName="email" id="mail" type="text" placeholder="Ingresa tu correo electrónico" class="col-11 form-control ml-2"
                [ngClass]="{ 'is-invalid': validate && remember.email.errors}" required>
                <div  *ngIf="validate && remember.email.errors " class="ml-2 invalid-feedback">
                    <div *ngIf="remember.email.errors?.pattern" >Correo invalido*</div>
                </div>
                <div  *ngIf="validate && remember.email.errors " class="ml-2 invalid-feedback">
                    <div *ngIf="remember.email.errors?.required">Este Campo es Obligatorio*</div>
                </div> 
             </div>
          </form>
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger"
            (click)="d('Close modal')" ngbAutofocus>Cancelar</button>
          <button type="button" (click)="recuperar()" class="btn btn-info">Enviar</button>
        </div>
      </ng-template>
 