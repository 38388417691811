import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../../_services/api.service';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { IkeService } from 'src/app/ike-content/services/ike.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-analistas',
  templateUrl: './analistas.component.html',
  styleUrls: ['./analistas.component.css']
})
export class AnalistasComponent implements OnInit {

  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;

  
  analistas: any;
  userAnalista: any
  dataAnalista: any;
  paises: any;
  formularioAnalista: FormGroup;
  Mensaje: any;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private service: ApiService,
    private _ikeService: IkeService

  ) {
    
  }

  ngOnInit(): void {
    this.formularioAnalista = this.formBuilder.group({
      pais: ['', Validators.required],
      analista: ['', Validators.required],
      correo: ['', Validators.required],
      estatus: ['', Validators.required],
    });
    setTimeout(() => {
      this.service.getAllAnalistas().subscribe(
        data => {
          this.service.getPaises().subscribe(
            data_paises => {
              this.paises = data_paises;
              this.analistas = data;
            },
            err => {
              console.log("Error al consumir peticion", err)
              this.Mensaje = "Error al consumir servicio"
                this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' }) 
            }
          )
        },
        err => {
          console.log("Error al consumir la peticion",err);
          this.Mensaje = "Error al consumir servicio"
          this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' }) 
        }
      )
    }, 500);
  }

  createExcel() {
    const headers = ["País","Analista Jurídico","Correo","Estatus" ]
    const excelObj = {
      title:'Catalogo',
      header: headers,
      data: this.createData(this.analistas),
      isClient: false
    }
    this._ikeService.createTable(excelObj)
  }

  createData(array) {
    const dataArray = []
    array.forEach(row => {
      const newRow = [row.pais,row.titulo, row.email[0].value, row.estatus ]
      dataArray.push(newRow)
    });
    return dataArray
  }

  asignar(AsignarFormContent) {
    this.modalService.open(AsignarFormContent, { windowClass: 'animated fadeInDown' });
  }

  eliminar(EliminarFormContent, data) {
    this.userAnalista = data;
    this.modalService.open(EliminarFormContent, { windowClass: 'animated fadeInDown' });
  }

  editar(EditarFormContent,data) {
    this.modalService.open(EditarFormContent, { windowClass: 'animated fadeInDown' });
    setTimeout(() => {
      if((<HTMLInputElement>document.getElementById("pais"))) {
        this.dataAnalista = data;
        (<HTMLInputElement>document.getElementById("pais")).value = data["pais"];
        (<HTMLInputElement>document.getElementById("analista")).value = data["titulo"];
        (<HTMLInputElement>document.getElementById("correo")).value = data["email"][0]["value"];
        (<HTMLInputElement>document.getElementById("estatus")).value = data["estatus"]; 
      }
    }, 1000);
  }

  editarAnalista(DefaultModalContent){
    if(
      (<HTMLInputElement>document.getElementById("pais")).value !="" &&
      (<HTMLInputElement>document.getElementById("analista")).value !="" &&
      (<HTMLInputElement>document.getElementById("correo")).value !="" &&
      (<HTMLInputElement>document.getElementById("estatus")).value !=""
    ){
      const data = {
        "titulo": (<HTMLInputElement>document.getElementById("analista")).value,
        "email": (<HTMLInputElement>document.getElementById("correo")).value,
        "estatus": (<HTMLInputElement>document.getElementById("estatus")).value,
        "pais": (<HTMLInputElement>document.getElementById("pais")).value,
        "item_id": this.dataAnalista["item_id"]
      }
      console.log(data);
      this.service.editAnalista(data).subscribe(
        data => {
          this.modalService.dismissAll()
          window.location.reload()
        },
        err => {
          console.log("Error en la peticion", err);
          this.Mensaje = "Error al consumir la petición"
          this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
        }
      )
    }
  }

  crearAbogado(DefaultModalContent){
    if(
      (<HTMLInputElement>document.getElementById("pais")).value !="" &&
      (<HTMLInputElement>document.getElementById("analista")).value !="" &&
      (<HTMLInputElement>document.getElementById("correo")).value !="" &&
      (<HTMLInputElement>document.getElementById("estatus")).value !=""
    ){
      const data = {
        "titulo": (<HTMLInputElement>document.getElementById("analista")).value,
        "email": (<HTMLInputElement>document.getElementById("correo")).value,
        "estatus": (<HTMLInputElement>document.getElementById("estatus")).value,
        "pais": (<HTMLInputElement>document.getElementById("pais")).value
      }
      console.log(data);
      this.service.crearAnalista(data).subscribe(
        data => {
          this.modalService.dismissAll()
          window.location.reload()
        },
        err => {
          console.log("Error en la peticion", err);
          this.Mensaje = "Error al consumir la petición"
          this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
        }
      )
    }
  }

  borrarAnalista(DefaultModalContent){
    this.service.deleteAnalistas(this.userAnalista).subscribe(
      data => {
        console.log(data);
        this.modalService.dismissAll()
        window.location.reload()
      },
      err => {
        console.log("Error en la peticion", err);
        this.Mensaje = "Error al consumir la petición"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }

}
