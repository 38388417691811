// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    comercial: {
      items: Partial<MenuItem>[]
    },
    abogado: {
      items: Partial<MenuItem>[]
    },
    legal:{
      items: Partial<MenuItem>[]
    },
    administrador:{
      items: Partial<MenuItem>[]
    }
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
  ordinal_menu: {
    items: Partial<MenuItem>[]
  }
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {  
    comercial: {
      items: [
      {
        title: 'Mis Solicitudes',
        icon: 'la-file-text',
        page: 'dashboard',
      }
     ]
    },
    legal: {
      items: [
        {
          title: 'Dashboard',
          icon: 'la-home',
          page: 'dashboard',
        },
        {
          title: 'Solicitudes',
          icon: 'la-file-text',
          page: 'solicitudes',
        },
        {
          title: 'Solicitudes Pendientes',
          icon: 'feather ft-clipboard',
          page: 'null',
          submenu: {
            items: [
              {
                title: 'Draft',
                page: 'draft'
              },
              {
                title: 'Asignación',
                page: 'draft'
              },
              {
                title: 'Aceptación de la Documentación Recibida',
                page: 'draft'
              },
              {
                title: 'Rechazo de la Documentación Recibida',
                page: 'draft'
              },
              {
                title: 'Revisión Documento',
                page: 'draft'
              },
              {
                title: 'Validación del Documento',
                page: 'draft'
              },
              {
                title: 'Pendiente Por Validar',
                page: 'draft'
              },
              {
                title: 'Recabando Firmas',
                page: 'draft'
              },
              {
                title: 'Corrección de Documentación',
                page: 'draft'
              },
              {
                title: 'En Espera de Firma',
                page: 'draft'
              },
              {
                title: 'Firmas Recaudadas',
                page: 'draft'
              },
              
              
            ]
          }
        },
        {
          title: 'Solicitudes Finalizadas',
          icon: 'feather ft-book',
          page: 'finalizadas',
        },
        {
          title: 'Catálogo de Analistas',
          icon: 'feather ft-book',
          page: 'analistas',
        }
      ]
    },

    abogado: {
      items: [
        {
          title: 'Mis Solicitudes',
          icon: 'la-file-text',
          page: 'dashboard',
        }
      ]
    },
    administrador: {
      items: [
        {
          title: 'Dashboard ',
          icon: 'la-home',
          page: 'dashboard',
        },
        {
          title: 'Mis Solicitudes',
          icon: 'la-file-text',
          page: 'solicitud',
        },
        {
          title: 'Solicitudes Pendientes',
          icon: 'la-clipboard',
          page: 'null',
          submenu: {
            items: [
              {
                title: 'Draft',
                page: 'draft'
              },
              {
                title: 'Asignación',
                page: 'draft'
              },
              {
                title: 'Aceptación de la Documentación Recibida',
                page: 'draft'
              },
              {
                title: 'Rechazo de la Documentación Recibida',
                page: 'draft'
              },
              {
                title: 'Revisión Documento',
                page: 'draft'
              },
              {
                title: 'Validación del Documento',
                page: 'draft'
              },
              {
                title: 'Pendiente Por Validar',
                page: 'draft'
              },
              {
                title: 'Recabando Firmas',
                page: 'draft'
              },
              {
                title: 'Corrección de Documentación',
                page: 'draft'
              },
              {
                title: 'En espera de Validación',
                page: 'draft'
              },
              
              
            ]
          }
        },
        {
          title: 'Solicitudes Finalizadas',
          icon: 'feather ft-book',
          page: 'finalizados',
        },
        {
          title: 'Catálogos',
          icon: 'la-file-text',
          page: 'null',
          submenu: {
            items: [
              {
                title: 'Analistas',
                page: 'catalogo/analistas'
              },
              {
                title: 'Clientes',
                page: 'catalogo/clientes'
              }
            ]
          }
        }
      ]
    }
  },
  vertical_menu: {
    items: []
  },
  ordinal_menu: {
    items: []
  },
};





