<div class="app-content content">
    <div class="content-wrapper p-0">
        <div class="content-body px-1">
            <div class="container col-12">
            <section id="minimal-statistics">
                <!-- Aqui inicia el div blanco(general)-->
                <div class="container-lg">
                    <!-- Striped rows start -->
                    <div class="col-12 p-0">
                        <div class="main-card mt-2">
                        <div class="card-content collapse show p-2">
                         
                          <div class="row justify-content-end mrgtop mb-2">
                            <div class="mr-1 font-weight-bold" style="height:35px;margin-top:6px;">
                              Buscar:
                              </div>                            
                            <input type="text" [(ngModel)]="filter" style="height:35px;width:25%;"
                              placeholder="Comercial, Analista Jurídico, Empresa, Cliente, Folio" (keyup)="quickFilter($event)" />
              
                              <button (click)="createExcel()" type="button" class="btn"><img src="../../../../assets/images/logo/exportar.svg" alt="exportar" height="35" width="35"></button>
                         
                          </div>

                            <article class="mb-2 text-center">
                                  <ngx-datatable class="bootstrap table-bordered pointer" [rows]="items" [columnMode]="'flex'"
                                    [headerHeight]="'auto'" [footerHeight]="50" [limit]="limit" [footerHeight]="50" [rowHeight]="'auto'" fxFlex="auto" (activate)="detalles($event)">
                                    <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Folio" prop="folio" [width]="'auto'" [flexGrow]="1">
                                      <ng-template ngx-datatable-cell-template let-value="value">
                                        <div class="text-center">{{value}}</div>
                                      </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Fecha Folio" prop="fecha"   [flexGrow]="2">
                                      <ng-template ngx-datatable-cell-template let-value="value">
                                        <div class="text-center">{{value | date: 'yyyy-MM-dd'}}</div>
                                      </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Tipo" prop="tipo-de-solicitud-2" [flexGrow]="1">
                                      <ng-template let-value="value" ngx-datatable-cell-template>
                                        <div class="text-center">{{value}}</div>
                                      </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Cliente" prop="ciiente-o-razon-social.title" [flexGrow]="2">
                                      <ng-template let-value="value" ngx-datatable-cell-template>
                                        <div class="text-center">{{value}}</div>
                                      </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Empresa" prop="cliente" [flexGrow]="3">
                                      <ng-template let-value="value" ngx-datatable-cell-template>
                                        <div class="text-center">{{value}}</div>
                                      </ng-template>
                                      </ngx-datatable-column>                                    
                                    <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Comercial" prop="usuario.title" [flexGrow]="2">
                                      <ng-template let-value="value" ngx-datatable-cell-template>
                                        <div class="text-center">{{value}}</div>
                                      </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus-2" [flexGrow]="3">
                                      <ng-template let-value="value" ngx-datatable-cell-template>
                                        <div class="text-center centrar-vertical" >{{value}}</div>
                                      </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-footer id="id-f">
                                      <ng-template ngx-datatable-footer-template id="af" style="width: 100vw; position: absolute;">
                                        <p style="margin-top: 15px; margin-right: 30px; margin-left: 10px; justify-content: right!important;">Total: {{solicitud.filtered}}</p>
                                        <p style="margin-top: 14px;"> Items por página
                                          <select id="cantidad" [(ngModel)]="elements" (change)="changeSizePage($event)">
                                            <option *ngFor="let dato of [20,50,100]">
                                              {{dato}}
                                            </option>
                                            </select>
                                          </p>
                                        
                                        <ul class="pagination pagination-separate pagination-flat" style="margin-top: 0px; position:absolute; right:0; margin-right: 10px">
                                          <ngb-pagination [collectionSize]="paginations" [pageSize]="1" [maxSize]="5" [(page)]="page" (pageChange)="changePage()"  aria-label="Default pagination"></ngb-pagination>
                                        </ul>
                                      </ng-template>
                                    </ngx-datatable-footer>
                                  </ngx-datatable>
                                

                        </article>
                        </div>
                        </div>
                    </div>
                    <!-- Striped rows end -->
                </div>
            </section>
        </div>
    </div>
</div>
