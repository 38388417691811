import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../_services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css']
})
export class DashboardAdminComponent implements OnInit {
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;

  Mensaje:any;
  grafica: any;
  comercial: any;
  abogado: any;

  constructor(private service: ApiService, private modalService: NgbModal) {
  }

  getData(DefaultModalContent){
    this.service.dashboard().subscribe(
      data => {
        
        this.comercial = data["usuarios-comercial"]
        this.abogado = data["usuarios-abogados"]

        this.grafica ={
          type: 'bar',
          labels: data["label"],
          legend: false,
          datasets: [
            {
              data: data["estatus"],
              backgroundColor: data["color"],
              borderColor: data["color"],
              borderWidth: 1
            }
          ],
          color:[{
            pointBackgroundColor: data["color"],
            pointBorderColor: data["color"],
            pointHoverBackgroundColor: data["color"],
            pointHoverBorderColor: data["color"],
          }],
          options: {
            maintainAspectRatio: false,
            responsive: true,
            scaleShowVerticalLines: false,
            xAxes: [{categoryPercentage: 1            
                }],
            'scales': {
              'yAxes': [
                  {
                  'ticks': {
                      'suggestedMin': 0,
                  },
                  },
              ],
            }
          }
        }

        console.log(this.grafica) 
      },
      err => {
        console.log("Error al pedir peticion",err)
        this.Mensaje = "Error al consumir petición"
        this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )    
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getData(this.contenidoDelModal) 
      }, 500);
  }


}
