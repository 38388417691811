import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnInit, ViewChild, Component } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { IkeService } from '../../services/ike.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-solicitudes-admin',
  templateUrl: './solicitudes-admin.component.html',
  styleUrls: ['./solicitudes-admin.component.css']
})
export class SolicitudesAdminComponent implements OnInit {
    

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('ErrorModal') errorModal;
  filter=null;
  master:any;
  Mensaje:any;
  analistas: any;
  solicitud: any = {
    total: 0,
    filtered: 0,
    items: []
  };
  items: any;
  modal_solicitud: any;
  asignarAnalista: FormGroup;
  solicitudActual: any
  
  page: any = 1;
  limit: any = 20;
  elements: any = 20;
  paginations: any = 0;

  constructor(
    private router: Router,
    private service: ApiService,
    private _ikeService: IkeService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ) {
    localStorage.getItem("detalles_solicitud")!=null ? localStorage.removeItem("detalles_solicitud") : "";
  }

  ngOnInit(): void {
    console.info("version de filtro");
    this.asignarAnalista = this.formBuilder.group({
      solicitud: ['', Validators.required],
    });
    setTimeout(() => {
      this.getSolicitudes(this.errorModal)  
      }, 500);
    
  }
  borrar(modal, row){
    this.solicitudActual = row
    this.modalService.open(modal, { windowClass: 'animated fadeInDown', size: 'sm' })
  }
  getSolicitudes(DefaultModalContent){
    const offset = (this.page-1)*this.elements;
    this.service.solicitudes(this.elements,offset).subscribe(
      (data: any) =>{
        this.solicitud = data;
        this.items = data.items;
        this.paginations = data.filtered / this.elements;
        this.master=data;
      },
      err => {
        this.Mensaje = "Error al consumir petición"
      this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    )
  }

  changePage(event){
    this.getSolicitudes(this.errorModal);
  }
  
  changeSizePage(event){
    this.page = 1;
    this.limit = event.target.value;
    this.getSolicitudes(this.errorModal);
  }
  quickFilter(e) {
    
    if( !e.target.value ){
      this.elements = 20;
      this.limit = 20;
      this.page = 1;

      this.items = this.master.items.map( x => { return x });
      this.solicitud = this.master;
      this.paginations = this.master.filtered / this.elements;
      return;
    }
    
    this.items = this.master.items.filter((x) =>
      
      x.usuario?.title?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x.abogado?.title?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x['ciiente-o-razon-social']?.title?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x['estatus-2'].toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x.cliente.toLowerCase()?.includes(e.target.value.toLowerCase())||
      x.folio.toLowerCase()?.includes(e.target.value.toLowerCase())
    
    );
    const size_text = e.target.value.length;
    if( this.items.length === 0 || size_text > 3){
      this.elements = 100;
      this.limit = 100;
      this.page = 1;
      const data = {"filter": e.target.value}
      this.service.filtroSolicitudesPage(data,500,0).subscribe(
        (response: any) =>{
          this.items = response.items;
          this.solicitud = response;
          this.paginations = response.filtered / this.elements;
        },
        err =>{
          this.Mensaje = "Error al consumir petición"
          this.modalService.open(this.errorModal, { windowClass: 'animated fadeInDown', size: 'sm' })
        }
      );
    }
  }
  detalles(e){
    localStorage.setItem("detalles_solicitud",JSON.stringify(e));
    this.router.navigate(["/administrador/tabs"])
  }
  get f() {
    return this.asignarAnalista.controls;
  }

  actualizarSolicitud(DefaultModelContent){

    const data = {
      "id_solicitud": this.modal_solicitud["item_id"],
      "id_abogado": parseInt(this.f.solicitud.value)
    }
    
    this.service.asignaAbogado(data).subscribe(
      data => {
        window.location.reload();
        this.modalService.dismissAll()
        
      },
      err => {
        this.Mensaje = "Error en la petición"
        this.modalService.open(this.errorModal, { windowClass: 'animated fadeInDown', size: 'sm' })

      }
    )

  }

  createExcel() {
    console.info(this.items)

    this.items.forEach(element => {
      if(!element['abogado']){
        element["abogado"] = {
          item_id: null,
          title: ""
        }
      }
    });

    const headers = ["Folio","Fecha Folio","Tipo","Cliente","Empresa","País","Comercial","Estatus","Analista Jurídico" ]
    const excelObj = {
      title:'Solicitudes',
      header: headers,
      data: this.items,
      hasLawyer: true
    }
    this._ikeService.createExcel(excelObj)
  }

  asignar(AsignarFormContent, rowData) {
    this.service.getAnalistas( { "estatus": 1 } ).subscribe(
      data => {
        this.analistas = data;
        this.modal_solicitud = rowData;
        this.modalService.open(AsignarFormContent, { windowClass: 'animated fadeInDown' });
      },
      err => {
        this.Mensaje = "Error al cosumir el servicio"
        this.modalService.open(this.errorModal, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    );
    //this.modalService.open(AsignarFormContent, { windowClass: 'animated fadeInDown' });
  }
  clickBorrar(){
    const data = { "item_id": Number.parseInt(this.solicitudActual['item_id']) }
    console.log(data)
    this.service.deleteSolicitud(data).subscribe( res=>{
      this.modalService.dismissAll() 
    })
  }
}
