import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-ike-header',
  templateUrl: './ike-header.component.html',
  styleUrls: ['./ike-header.component.css']
})
export class IkeHeaderComponent implements OnInit {

  public selectedHeaderNavBarClass: string;
  showNavbar = false;
  public _themeSettingsConfig: any = { brand: { brand_name: null } };
  company = { name: null };
  profile = { name: null };

  constructor(@Inject(DOCUMENT) private document: Document,
  private router: Router, private _renderer: Renderer2,public authService: AuthService) { }

  ngOnInit(): void {
    
  }

  logout() {
    
  }

  toggleNavigation(e) {
    const sidenav = document.getElementById('sidenav-overlay');
    const sidebarLeft = document.getElementById('sidebar-left') || document.getElementById('email-app-menu') ||
      document.getElementById('sidebar-todo');
    const contentOverlay = document.getElementById('content-overlay');

    if (this.document.body.classList.contains('menu-open') && (this.router.url === '/todos' || this.router.url === '/contacts' ||
      this.router.url === '/email' || this.router.url === '/chats' || this.router.url === '/chats/static-chat')) {
      this.document.body.classList.remove('menu-open');
      this._renderer.removeClass(sidenav, 'd-block');
      this._renderer.removeClass(contentOverlay, 'show');
      this.document.body.classList.add('menu-close');
      this._renderer.addClass(sidenav, 'd-none');
      this.showNavbar = false;
    } else if (this.document.body.classList.contains('menu-open')) {
      this.document.body.classList.remove('menu-open');
      this._renderer.removeClass(sidenav, 'd-block');
      this.document.body.classList.add('menu-close');
      this._renderer.addClass(sidenav, 'd-none');
      this.showNavbar = false;
    } else {
      this._renderer.removeClass(sidenav, 'd-none');
      this.document.body.classList.remove('menu-close');
      this.document.body.classList.add('menu-open');
      this._renderer.addClass(sidenav, 'd-block');
      this.showNavbar = false;
    }

    if (sidebarLeft) {
      this._renderer.removeClass(sidebarLeft, 'show');
    }
    if (contentOverlay) {
      this._renderer.removeClass(contentOverlay, 'show');
    }
  }

  toggleNavbar(e) {
    if (this.showNavbar) {
      this.showNavbar = false;
    } else {
      this.showNavbar = true;
    }
  }


}
