<div class="app-content content">
    <div class="content-wrapper p-0">
        <div class="content-body">
            <section id="minimal-statistics" class="px-2">
                <section class="card mx-0 mt-2">
                    <div class="card-body">
                      <article class="row justify-content-end mb-2 mrgtop">
                        <button (click)="createExcel()" type="button" class="btn"><img src="../../../../assets/images/logo/exportar.svg" alt="exportar" height="35" width="35"></button>
                      </article>
                        <!-- TABLE -->
                        <article class="mb-2 mrgtop pointer text-center">
                          <ngx-datatable class="bootstrap table-bordered"  [rows]="solicitudes" [columnMode]="'flex'"
                          [headerHeight]="'auto'" [footerHeight]="50" [limit]="10" [rowHeight]="'auto'" fxFlex="auto">
                          <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Folio" prop="folio" [width]="'auto'" [flexGrow]="1">
                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                              <div class="text-center" (click)="detalles(row)">{{value}}</div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Fecha Folio" minWidth="110" prop="fecha" [flexGrow]="2">
                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                              <div class="text-center" (click)="detalles(row)">{{value | date: 'yyyy-MM-dd'}}</div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Tipo" prop="tipo-de-solicitud-2" [flexGrow]="2">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <div class="text-center" (click)="detalles(row)">{{value}}</div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Cliente" prop="ciiente-o-razon-social.title" [flexGrow]="2">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <div class="text-center" (click)="detalles(row)">{{value}}</div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Empresa" prop="cliente" [flexGrow]="3">
														<ng-template let-value="value" ngx-datatable-cell-template>
															<div class="text-center">{{value}}</div>
														</ng-template>
													  </ngx-datatable-column>                          
                          <ngx-datatable-column [resizeable]="false" [draggable]="false" name="País" prop="pais" [flexGrow]="1">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <div class="text-center" (click)="detalles(row)">{{value}}</div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Comercial" prop="usuario.title" [flexGrow]="2">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <div class="text-center" (click)="detalles(row)">{{value}}</div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus-2" [flexGrow]="2">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <div class="text-center" (click)="detalles(row)">{{value}}</div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Analista Jurídico" prop="abogado.title" [flexGrow]="2">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <div class="text-center" (click)="detalles(row)">{{value}}</div>
                            </ng-template>
                          </ngx-datatable-column>
                        </ngx-datatable>
                          </article>
                    </div>
                </section>
            </section>
        </div>
    </div>
</div>

<!-- Modal Faltan datos generales -->
<ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
  <div class="modal-header">
    <h4 class="modal-title" >Algo salió mal, inténtalo nuevamente</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="modal-body icon-alert mt-3">
      <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60" >
  </div>
  <h4 class="icon-alert mb-3">{{Mensaje}}</h4>
    
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info"(click)="d('Close modal')">Aceptar</button>
  </div>
</ng-template>


