<div class="app-content content">
  <div class="content-wrapper mx-2">
      <div class="content-body">
        <div class="container col-12">
          <section id="minimal-statistics">
            <div class="container-lg">
              <div class="row">
                <div class="card col-sm-12 mt-2">
                    <div class="card-body collapse show py-2">
                      <article class="row justify-content-end align-items-center mrgtop">
                        <button  (click)="asignar(AsignarFormContent)" type="button" class="ml-2 font-medium-1 text-bold-700 btn btn-info btn-min-width" >
                          Nuevo Analista</button>
                          <button (click)="createExcel()" type="button" class="btn"><img src="../../../../assets/images/logo/exportar.svg" alt="exportar" height="35" width="35"></button>
                      </article>
                            <article class="mb-2 text-center">
                              <div>
                                    <div>
                                      <ngx-datatable class="bootstrap table-bordered" [limit]="7" [rows]="analistas" [columnMode]="'flex'"
                                        [headerHeight]="50" [footerHeight]="50" [limit]="10" [rowHeight]="50" fxFlex="auto">
                                        <ngx-datatable-column [resizeable]="false" [draggable]="false" name="País" prop="pais" [flexGrow]="1">
                                          <ng-template ngx-datatable-cell-template let-value="value">
                                            <div class="text-center">{{value}}</div>
                                          </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Analista Jurídico" prop="titulo" [flexGrow]="1">
                                          <ng-template ngx-datatable-cell-template let-value="value">
                                            <div class="text-center">{{value}}</div>
                                          </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Correo Electrónico" prop="email" [flexGrow]="1">
                                          <ng-template let-value="value" ngx-datatable-cell-template>
                                            <div class="text-center">{{value[0].value}}</div>
                                          </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus" [flexGrow]="1">
                                          <ng-template let-value="value" ngx-datatable-cell-template>
                                            <div class="text-center">{{value}}</div>
                                          </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Acciones" [flexGrow]="1">
                                          <ng-template ngx-datatable-cell-template let-column="column"  let-row="row">
                                              <div class="d-flex justify-content-center w-100 px-2" style="transform: translateY(-15px);">
                                                <div class="w-50">
                                                  <button class="btn btn-lg text-info w-50">
                                                    <i class="feather ft-trash " (click)="eliminar(EliminarFormContent,row)"></i>
                                                  </button>
                                                  <button class="btn btn-lg text-info w-50" >
                                                    <i class="feather ft-edit " (click)="editar(EditarFormContent,row)"></i>
                                                  </button>
                                                </div>
                                              </div>
                                          </ng-template>
                                        </ngx-datatable-column>
                                      </ngx-datatable>
                                    </div>
                              </div>
                            </article>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
      </div>
  </div>
</div>

<!-- modal -->
              <!-- el boton se encuentra en icono editar -->
              <!-- Modal -->
              <ng-template class="modal text-left" #EliminarFormContent let-d="dismiss">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel1">Eliminar Analista Jurídico</h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <form>
                    <div class="modal-body">
                        <ng-container mCardBody>
                          <div class="modal-body icon-alert mt-3">
                            <img src="../../../../assets/custom/advertencia.svg" alt="alerta" width="70" >
                        </div>
                        <h3 class="icon-alert mb-3">¿Desea eliminar el analista?</h3>
                        </ng-container>
                     
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger"(click)="d('Close modal')">Cancelar</button>
                      <button type="button" class="btn btn-info " (click)="borrarAnalista(DefaultModelContent)">Eliminar Analista</button>
                    </div>
                  </form>
                </div>
              </ng-template>

<!-- modal para editar al analista-->
              <!-- Modal -->
<ng-template class="modal text-left" #EditarFormContent let-d="dismiss">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel1">Editar Analista Jurídico</h4>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <form [formGroup]="formularioAnalista" (ngSubmit)="editarAnalista(DefaultModalContent)">
                    <div class="modal-body">
                        <ng-container mCardBody>
                          <div class="form-group">
                            <label>País<span class="red">*</span></label>
                            <select class="form-control" id="pais" formControlName="pais"
                              [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                              <option>Selecciona una Opción</option>
                              <option [value]="data['title']" *ngFor="let data of paises">{{data['title']}}</option>
                            </select>
                            <small class="form-text text-muted danger" *ngIf="submitted && f.pais.errors" class="invalid-feedback">
                              <div *ngIf="f.pais.errors.required">Campo Requerido</div>
                            </small>
                          </div>
                          <div class="form-group">
                            <label>Analista Jurídico<span class="red">*</span></label>
                            <input type="text"  class="form-control" id="analista"
                               [ngClass]="{ 'is-invalid': submitted && f.analista.errors }"/>
                            <small class="form-text text-muted danger" *ngIf="submitted && f.analista.errors" class="invalid-feedback">
                              <div *ngIf="f.analista.errors.required">Campo Requerido</div>
                            </small>
                          </div>
                          <div class="form-group">
                            <label>Correo Electrónico<span class="red">*</span></label>
                            <input type="text"   class="form-control" id="correo"
                               [ngClass]="{ 'is-invalid': submitted && f.correo.errors }"/>
                            <small class="form-text text-muted danger" *ngIf="submitted && f.correo.errors" class="invalid-feedback">
                              <div *ngIf="f.correo.errors.required">Campo Requerido</div>
                            </small>
                          </div>
                          <div class="form-group">
                            <label>Estatus<span class="red">*</span></label>
                            <select class="form-control" id="estatus" formControlName="estatus"
                               [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                              <option>Con Rol</option>
                              <option>Sin Rol</option>
                            </select>
                            <small class="form-text text-muted danger" *ngIf="submitted && f.estatus.errors" class="invalid-feedback">
                              <div *ngIf="f.estatus.errors.required">Campo Requerido</div>
                            </small>
                          </div>
                        </ng-container>                     
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger"(click)="d('Close modal')">Cancelar</button>
                      <button type="submit" class="btn btn-info" >Guardar Cambios</button>
                    </div>
                  </form>
                </div>
</ng-template>

              <!-- modal para asignar analista -->
<ng-container>
  <ng-template class="modal text-left" #AsignarFormContent let-d="dismiss">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Nuevo Analista Jurídico</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="formularioAnalista" (ngSubmit)="crearAbogado(DefaultModalContent)">
        <div class="modal-body">
            <ng-container mCardBody>
              <div class="form-group">
                <label>País<span class="red">*</span></label>
                <select class="form-control" id="pais" formControlName="pais"
                  [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                  <option>Selecciona una Opción</option>
                  <option [value]="data['title']" *ngFor="let data of paises">{{data['title']}}</option>
                </select>
                <small class="form-text text-muted danger" *ngIf="submitted && f.pais.errors" class="invalid-feedback">
                  <div *ngIf="f.pais.errors.required">Campo Requerido</div>
                </small>
              </div>
              <div class="form-group">
                <label>Analista Jurídico<span class="red">*</span></label>
                <input type="text"  class="form-control" id="analista"
                   [ngClass]="{ 'is-invalid': submitted && f.analista.errors }"/>
                <small class="form-text text-muted danger" *ngIf="submitted && f.analista.errors" class="invalid-feedback">
                  <div *ngIf="f.analista.errors.required">Campo Requerido</div>
                </small>
              </div>
              <div class="form-group">
                <label>Correo Electrónico<span class="red">*</span></label>
                <input type="text"   class="form-control" id="correo"
                   [ngClass]="{ 'is-invalid': submitted && f.correo.errors }"/>
                <small class="form-text text-muted danger" *ngIf="submitted && f.correo.errors" class="invalid-feedback">
                  <div *ngIf="f.correo.errors.required">Campo Requerido</div>
                </small>
              </div>
              <div class="form-group">
                <label>Estatus<span class="red">*</span></label>
                <select class="form-control" id="estatus" formControlName="estatus"
                   [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                  <option>Con Rol</option>
                  <option>Sin Rol</option>
                </select>
                <small class="form-text text-muted danger" *ngIf="submitted && f.estatus.errors" class="invalid-feedback">
                  <div *ngIf="f.estatus.errors.required">Campo Requerido</div>
                </small>
              </div>
            </ng-container>                     
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger"(click)="d('Close modal')">Cancelar</button>
          <button type="submit" class="btn btn-info" >Guardar</button>
        </div>
      </form>
    </div>
  </ng-template>
</ng-container>


<!-- Modal Faltan datos generales -->
<ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
  <div class="modal-header">
    <h4 class="modal-title" >Algo salió mal, inténtalo nuevamente</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="modal-body icon-alert mt-3">
      <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60" >
  </div>
  <h4 class="icon-alert mb-3">{{Mensaje}}</h4>
    
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info"(click)="d('Close modal')">Aceptar</button>
  </div>
</ng-template>