<div class="app-content content w-100">
  <div class="sidebar-left" id="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar d-flex">
        <!-- sidebar close icon -->
        <span class="sidebar-close-icon" (click)="showSidebar($event)">
          <i class="ficon feather ft-x"></i>
        </span>
        <!-- sidebar close icon -->
        <div class="email-app-menu">
          <div class="form-group form-group-compose">
            <!-- compose button  -->
            <button type="button" class="btn btn-info btn-glow btn-block my-2 compose-btn" id="compose-btn"
              (click)="showComposeSidebar($event)">
              <i class="ficon feather ficon feather ft-plus"></i>
              Nueva Revisión
            </button>
          </div>
          <div class="sidebar-menu-list" fxFlex="auto">
            <!-- sidebar menu  -->
            <article class="col-12 px-0">
              <div class="row">
                <div class="col-12 p-0">
                  <div class="container d-flex flex-column mt-2">
                    <div class="d-flex justify-content-center text-center align-items-center ">
                                                            
                      <div class="documento">
                        <img src="../../../../assets/archivo-azul.svg" alt="documento " height="40" width="40" >
                      </div>
                      <div class="ml-1 text-info">
                        <p class="mb-0" ><a class="text-black" href="#">Contrato de Seguro.pdf</a></p>
                        <label class="mb-0">03/07/2021</label>
                      </div>                                                         
                   </div>
                  </div>
                  <div class="list-group-messages mt-4">
                      <div class="documento">
                       <p class="text-center text-black">VoBo </p>
                     </div>
                     <ul class="list-group">
                         
                         <li class="list-group-item">
                           <input type="checkbox" id="colorCheck2"><label for="colorCheck2">Analista Jurídico</label>
                         </li>
                         <li class="list-group-item">
                            <input type="checkbox" id="colorCheck1"><label for="colorCheck1">Comercial</label>
                          </li>
                       </ul><!-- aqui va el nuevo chat -->
                  </div>
                  
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <!-- User new mail right area -->
      <div class="compose-new-mail-sidebar" id="compose-sidebar" fxFlex="auto">
        <div class="card mb-0 shadow-none quill-wrapper p-0">
          <div class="card-header">
            <h3 class="card-title" id="emailCompose">Nueva Revision</h3>
            <button type="button" class="close close-icon" id="showCompose" (click)="showCompose($event)">
              <i class="ficon feather ft-x"></i>
            </button>
          </div>
          <!-- form start -->
          <form action="" id="compose-form" [formGroup]="newRevisionForm" (ngSubmit)="onSubmit()">
            <div class="card-content">
              <div class="card-body pt-0">
                <div class="form-group pb-50">
                  <label for="emailfrom">Para</label>
                  <input type="text" id="emailfrom" class="form-control" placeholder="user@example.com" disabled>
                </div>
                <div class="form-label-group mb-1">
                  <textarea  [ngClass]="{ 'is-invalid': submitted && fNewComment.comment.errors }" formControlName="comment" class="form-control" placeholder="Comentario de la revision" rows=10 required></textarea>
                </div>
                <div  *ngIf="submitted && fNewComment.comment.errors " class="ml-2 invalid-feedback">
                  <div *ngIf="remember.comment.errors?.required" >Este campo es requerido*</div>
              </div>
              
                <div class="form-group mt-2">
                  <div class="custom-file">
                    <input  formControlName="file" type="file" class="custom-file-input" id="emailAttach">
                    <label class="custom-file-label" for="emailAttach">Cargar Archivo</label>
                    
                  <div  *ngIf="submitted && fNewComment.comment.errors " class="ml-2 invalid-feedback">
                    <div *ngIf="fNewComment.file.errors?.ext" >Archivo invalido**</div>
                </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer border-0 d-flex justify-content-end pt-0">
              <button type="reset" class="btn btn-danger cancel-btn mr-1" (click)="showCompose($event)">
                <i class='ficon feather ft-x mr-25'></i>
                <span class="d-sm-inline d-none">Cancelar</span>
              </button>
              <button type="submit" class="btn-send btn btn-info btn-glow">
                <i class='ficon feather ft-play mr-25'></i> <span class="d-sm-inline d-none">Enviar</span>
              </button>
            </div>
          </form>
          <!-- form start end-->
        </div>
      </div>
      <!--/ User Chat profile right area -->
    </div>
  </div>
  <div class="content-right">
    <div class="content-header row">
    </div>
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-body">
        <!-- email app overlay -->
        <div class="app-content-overlay" id="app-content-overlay" (click)="showCompose($event)"
          (click)="showSidebar($event)"></div>
        <div class="email-app-area">
          <!-- Email list Area -->
          <div class="email-app-list-wrapper">
            <div class="email-app-list">
              <div class="email-action">
                <h3 class="text-center w-100">Historial de Revisiones</h3>
              </div>
              <div class="email-user-list list-group">
                <ul class="users-list-wrapper media-list">
                  <li class="media align-items-center py-2">
                    <div class="media-body d-flex align-items-center">
                      <div class="d-inline-block col-2">
                        <div class="d-flex justify-content-center">
                          <div class="avatar">
                            <img src="../../../../assets/usuario-chat.svg" alt="avtar img holder">
                          </div>
                        </div>
                        <div class=" text-center">
                          <div class="mail-items">
                            <span class="list-group-item-text text-truncate">Abraham Borja</span>
                          </div>
                        </div>
                        <div class=" text-center">
                          <p class="list-group-item-text truncate mb-0">
                            Lorem ipsum dolor sit amet.
                          </p>
                        </div>
                      </div>
                      <div class="d-inline-block text-black">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut pariatur, eos tempora autem maiores quidem provident
                        sapiente explicabo beatae ipsam sit, nulla impedit voluptatem totam ex vero eveniet nobis suscipit.
                      </div>
                      <div class="col-2 px-0">
                        <div class="col-md-12 col-sd-12 d-flex justify-content-center">
                          <img src="../../../../assets/archivo-azul.svg" alt="documento " height="45" width="43">
                        </div>
                        <div class="col-md-12 col-sd-12">
                          <p><a href="#" class="text-info">Lorem ipsum dolor sit amet.pdf</a></p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li [ngClass]="user2==true?'bg-gray':''" class="media align-items-center py-2">
                    <div [ngClass]="user2==true?'flex-row-reverse':''" class="media-body d-flex align-items-center">
                      <div class="chat-item__avatar d-inline-block col-2">
                        <div class="d-flex justify-content-center">
                          <div class="avatar">
                            <img src="../../../../assets/usuario-chat.svg" alt="avtar img holder">
                          </div>
                        </div>
                        <div class=" text-center">
                          <div class="mail-items">
                            <span class="list-group-item-text text-truncate">Abraham Borja</span>
                          </div>
                        </div>
                        <div class=" text-center">
                          <p class="list-group-item-text truncate mb-0">
                            Lorem ipsum dolor sit amet.
                          </p>
                        </div>
                      </div>
                      <div class="chat-item__comment d-inline-block">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut pariatur, eos tempora autem maiores quidem provident
                        sapiente explicabo beatae ipsam sit, nulla impedit voluptatem totam ex vero eveniet nobis suscipit.
                      </div>
                      <div class="chat-item__file col-2 px-0">
                        <div class="col-md-12 col-sd-12 d-flex justify-content-center">
                          <img src="../../../../assets/archivo-azul.svg" alt="documento " height="45" width="43">
                        </div>
                        <div class="col-md-12 col-sd-12">
                          <p><a href="#" class="text-info">Lorem ipsum dolor sit amet.pdf</a></p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <!-- email user list end -->

                <!-- no result when nothing to show on list -->
                <div class="no-results">
                  <i class="ficon feather ft-info font-large-2"></i>
                  <h5>No Items Found</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
  <div class="modal-header">
    <h4 class="modal-title">Algo salió mal, inténtalo nuevamente</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body icon-alert mt-3">
      <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60">
    </div>
    <h4 class="icon-alert mb-3"></h4>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="d('Close modal')">Aceptar</button>
  </div>
</ng-template>