import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IkeService } from 'src/app/ike-content/services/ike.service';
import { ApiService } from '../../../../_services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-solicitud-draft-admin',
  templateUrl: './solicitud-draft-admin.component.html',
  styleUrls: ['./solicitud-draft-admin.component.css']
})
export class SolicitudDraftAdminComponent implements OnInit {
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;

  Mensaje:any;
  modal_solicitud: any;
  public solicitud: any;
  analistas: any;
  asignarAnalista: FormGroup;

  constructor(private service: ApiService,
    private router: Router,
    private _ikeService: IkeService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder
    ) {
      localStorage.getItem("detalles_solicitud") != null 
      ?
        localStorage.removeItem("detalles_solicitud")
      :
        ""
    }

  ngOnInit(): void {
    this.asignarAnalista = this.formBuilder.group({
      solicitud: ['', Validators.required],
    });
    setTimeout(() => {
      localStorage.getItem("estatus") != null ? this.getSolicitudes(this.contenidoDelModal) : ""
      }, 500);
  }

  getSolicitudes(DefaultModalContent){
    this.service.filtroSolicitudes({"estatus": localStorage.getItem("estatus")}).subscribe(
      data => { 
        this.solicitud = data
        this.getAnalista(this.contenidoDelModal)
      },
      err => {
      this.Mensaje = "Error al consumir servicio"
      this.modalService.open(DefaultModalContent, { windowClass: 'animated fadeInDown', size: 'sm' })
    }
    )
  }

  getAnalista(DefaultModelContent){

    this.service.getAnalistas( { "estatus": 1 } ).subscribe(
      data => {
        this.analistas = data;
      },
      err => {
        this.Mensaje = "Error al cosumir el servicio"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    );
  }
  
  detalles(e){
    localStorage.setItem("detalles_solicitud",JSON.stringify(e));
    this.router.navigate(["/administrador/tabs"])
  }

  createExcel() {
    this.solicitud.forEach(element => {
      if(!element['abogado']){
        element["abogado"] = {
          item_id: null,
          title: ""
        }
      }
    });
    
    const headers = ["Folio","Fecha Folio","Tipo","Cliente","Empresa","País","Comercial","Estatus","Analista Jurídico" ]
    const excelObj = {
      title:'Solicitudes',
      header: headers,
      data: this.solicitud,
      hasLawyer: true
    }
    this._ikeService.createExcel(excelObj)
  }
  asignar(AsignarFormContent, data) {
    this.modal_solicitud = data;
    this.modalService.open(AsignarFormContent, { windowClass: 'animated fadeInDown' });
  }

  get f() {
    return this.asignarAnalista.controls;
  }

  actualizarSolicitud(DefaultModelContent){

    const data = {
      "id_solicitud": this.modal_solicitud["item_id"],
      "id_abogado": parseInt(this.f.solicitud.value)
    }
    
    this.service.asignaAbogado(data).subscribe(
      data => {
        this.modalService.dismissAll();
        window.location.reload();
        
      },
      err => {
        this.Mensaje = "Error en la petición"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })

      }
    )

  }
}
