import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})

export class ApiService {
  
  public server: string
  constructor(public http: HttpClient) {
    //this.server = "http://localhost:5000";
    //this.server = "https://asistencia-ike.backend.admonlegal.com:8000";
    this.server = "https://asistencia-ike.backend.admonlegal.com";
  }


  public getAnalistas(data){
    return this.http.post(this.server+'/get-analistas',data);
  }

  public getAllAnalistas(){
    return this.http.get(this.server+'/get-analistas-all');
  }

  public deleteAnalistas(data){
    return this.http.post(this.server+'/eliminar-analista',data);
  }
  public deleteCliente(data){
    return this.http.post(this.server+'/eliminar-cliente',data);
  }

  public asignaAbogado(data){
    return this.http.post(this.server+'/asigna-abogado',data);
  }
  
  public login(data) {
    return this.http.post(this.server+'/login',data);
  }

  public recuperarContrasenia(data){
    return this.http.post(this.server+'/password-recover',data);
  }

  public solicitudes(limit,page) {
    return this.http.get(this.server+'/get-solicitudes?limit='+limit.toString()+'&page='+page.toString());
  }

  public filtroSolicitudesPage(data,limit,page) {
    return this.http.post(this.server+'/get-solicitudes-filter?limit='+limit.toString()+'&page='+page.toString(),data);
  }

  public filtroSolicitudes(data){
    return this.http.post(this.server+'/get-solicitudes/filtro',data);
  }

  public crearCliente(data){
    return this.http.post(this.server+'/create-clientes',data);
  }

  public getCatalogoTipoSolicitud(data){
    return this.http.post(this.server+'/catalogo-tipo-solicitud',data);
  }

  public getClientes(data){
    return this.http.post(this.server+'/get-clientes',data);
  }
  //COLOCAR ENDPOINT
  public deleteSolicitud(data){
    return this.http.post(this.server+'/eliminar-solicitud',data);
  }
  public getClientes_all(){
    return this.http.get(this.server+'/get-clientes/all');
  }

  public aprobarDocumentacion(data){
    return this.http.post(this.server+'/aprobar-solicitud',data);
  }

  public getRevision(data){
    return this.http.post(this.server+'/get-revisones',data);
  }

  public crearRevision(data){
    return this.http.post(this.server+'/create-revision',data);
  }

  public crearSolicitud(data){
    return this.http.post(this.server+'/create-solicitud',data);
  }

  public uploadFile(data){
    return this.http.post(this.server+'/subir_archivo',data);
  }

  public aprobarBorrador(data){
    return this.http.post(this.server+'/solicitudes/aprobar-borrador',data);
  }

  public dashboard(){
    return this.http.get(this.server+'/get-solicitudes/graph');
  }

  public getPaises(){
    return this.http.get(this.server+"/get-paises")
  }

  public editClient(data){
    return this.http.post(this.server+'/editar-catalogo-clientes', data)
  }

  public editAnalista(data){
    return this.http.post(this.server+'/editar-analistas',data)
  }

  public marcaAgua(data){
    return this.http.post(this.server+'/solicitudes-marca-de-agua', data);
  }

  public firmaElectronica(data){
    return this.http.post(this.server+'/solicitudes-firma-electronica',data);
  }

  public checkAbogadoComercial(data){
    return this.http.post(this.server+'/firma-abogado-comercial',data);
  }

  public subirDocumentoFinal(data){
    return this.http.post(this.server+'/subir-documento-firmando',data);
  }

  public createFirmantes(data){
    return this.http.post(this.server+"/agregar-firmantes",data);
  }

  public getFirmantes(data){
    return this.http.post(this.server+"/consultar-firmantes",data);
  }

  public editarFirmantes(data){
    return this.http.post(this.server+"/editar-firmantes",data);
  }

  public borrarFirmantes(data){
    return this.http.post(this.server+"/borrar-firmantes",data);
  }

  public reguardo(data){
    return this.http.post(this.server+"/resguardar-documento",data);
  }

  public subir_documento_rechazado(data){
    return this.http.post(this.server+"/subir-documentacion-rechazada",data);
  }

  public crearAnalista(data){
    return this.http.post(this.server+"/create-analistas",data);
  }
}