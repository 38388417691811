<div class="app-content content">
    <div class="content-wrapper p-0">
        <div class="content-body mx-2">
          <div class="container col-12 px-1 ">
            <section id="minimal-statistics">
              <!-- Aqui inicia el div blanco(general)-->
              <div class="container-lg">
                <!-- Striped rows start -->
                <div class="row">
                  <div class="main-card col-sm-12 mt-2">
                      <!-- <div class="card-header">
                        <h4 class="card-title">Striped rows</h4>
                      </div> -->
                      <div class="card-content collapse show p-2">
                        <article class="row justify-content-end mrgtop">
                          <button (click)="createExcel()" type="button" class="btn"><img src="../../../../assets/images/logo/exportar.svg" alt="exportar" height="35" width="35"></button>
                      </article>
                                <article class="mb-2 pointer text-center">
                                  <ngx-datatable class="bootstrap table-bordered" [rows]="solicitudes" [columnMode]="'flex'"
                                  [headerHeight]="'auto'" [footerHeight]="50" [limit]="10" [rowHeight]="'auto'" fxFlex="auto" (activate)="detalles($event)">
                                  <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Folio" prop="folio" [width]="'auto'" [flexGrow]="1">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                      <div class="text-center">{{value}}</div>
                                    </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Fecha Folio" prop="fecha" [flexGrow]="2">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                      <div class="text-center">{{value | date: 'yyyy-MM-dd'}}</div>
                                    </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Tipo" prop="tipo-de-solicitud-2" [flexGrow]="2">
                                    <ng-template let-value="value" ngx-datatable-cell-template>
                                      <div class="text-center">{{value}}</div>
                                    </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Cliente" prop="ciiente-o-razon-social.title" [flexGrow]="2">
                                    <ng-template let-value="value" ngx-datatable-cell-template>
                                      <div class="text-center">{{value}}</div>
                                    </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Empresa" prop="cliente" [flexGrow]="3">
                                    <ng-template let-value="value" ngx-datatable-cell-template>
                                      <div class="text-center">{{value}}</div>
                                    </ng-template>
                                    </ngx-datatable-column>                                  
                                  <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Comercial" prop="usuario.title" [flexGrow]="2">
                                    <ng-template let-value="value" ngx-datatable-cell-template>
                                      <div class="text-center">{{value}}</div>
                                    </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Estatus" prop="estatus-2" [flexGrow]="2">
                                    <ng-template let-value="value" ngx-datatable-cell-template>
                                      <div class="text-center">{{value}}</div>
                                    </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column [resizeable]="false" [draggable]="false" name="Analista Jurídico" prop="abogado.title" [flexGrow]="2">
                                    <ng-template let-value="value" ngx-datatable-cell-template>
                                      <div class="text-center">{{value}}</div>
                                    </ng-template>
                                  </ngx-datatable-column>
                                </ngx-datatable>
                                </article>
                              </div>
  
                          </div>
                      </div>
                      <!-- Striped rows end -->
                  </div>
              </section>
          </div>
        </div>
    </div>
  </div>

  
      <!-- Modal para errores -->
      <ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
        <div class="modal-header">
          <h4 class="modal-title" >Algo salió mal, inténtalo nuevamente</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="modal-body icon-alert mt-3">
            <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60" >
        </div>
        <h4 class="icon-alert mb-3">{{Mensaje}}</h4>
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info"(click)="d('Close modal')">Aceptar</button>
        </div>
      </ng-template>
 
