import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../../_services/api.service';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { IkeService } from 'src/app/ike-content/services/ike.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @ViewChild('DefaultModelContent') contenidoDelModal;
  
  clientes: any;
  paises: any;
  userCliente: any
  formularioClientes: FormGroup;
  formularioNuevoClientes: FormGroup;
  dataClient: any;
  Mensaje:any;
  clientesArr = []
  clientesObj = {
    mexico: ['ARSA ASESORIA  INTEGRAL PROFESIONAL, S. DE  R.L. DE  C.V.',
             'PROTECCION S.O.S. JURIDICO AUTOMOLISTICO  LAS  VEINTICUATRO',
             'INFOASIST  INFORMACION Y  ASISTENCIA, S. DE  R.L. DE  C.V',

            'Iké Grupo Empresarial S. de R.L. de C.V.',
          'SEA Servicios Especializados de Asistencia S. de R.L. de C.V.',
            'Vive Plus  S.A. de C.V.',
            'Servicios Técnicos de Asistencia S. de R.L. de C.V.',
          'Infocredit S. de R.L. de C.V.'],
    argentina: ['EFICASIA  ARGENTINA, S.A.','IKE  ASISTENCIA  ARGENTINA, S.A.'],
    brasil: ['IKÉ  ASISTENCIA  BRASIL, S.A.'],
    colombia: ['IKÉ  ASISTENCIA  COLOMBIA, S.A.','KOME  CARE IPS, S.A.S.']
  }

  empresaActual: any


  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private service: ApiService,
    private _ikeService: IkeService
  ) {
      
    }

  ngOnInit(): void {
    this.formularioClientes = this.formBuilder.group({
      nombre: ['', Validators.required],
      razon: ['', Validators.required],
      pais: ['', Validators.required],
      empresa: ['',[]],
      rfc: ['', Validators.required],

    });

    this.formularioNuevoClientes = this.formBuilder.group({
      nombre: ['', Validators.required],
      razon: ['', Validators.required],
      pais: ['', Validators.required],
      rfc: ['', Validators.required],

    });

    setTimeout(() => {
      this.service.getClientes_all().subscribe(
        data_clientes => {
          this.service.getPaises().subscribe(
            data_paises => {
              this.paises = data_paises;
              this.clientes = data_clientes;
            },
            err => {
              console.log("Error al consumir peticion", err)
              this.Mensaje = "Error al consumir petición"
              this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' }) 
            }
          )
        },
        err => {
          console.log("Error al consumir la peticion",err);
          this.Mensaje = "Error al consumir petición"
          this.modalService.open(this.contenidoDelModal, { windowClass: 'animated fadeInDown', size: 'sm' }) 
        }
      )
    },500);
  }
  selectPais(e){
    let paisSelected = this.removeAccents(e.target.value.toLowerCase())
    this.clientesArr = this.clientesObj[paisSelected]

    console.log(this.clientesArr)
  }
  removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  } 
  get f() {
    return this.formularioClientes.controls;
  }

  asignar(AsignarFormContent) {
    this.modalService.open(AsignarFormContent, { windowClass: 'animated fadeInDown' });
  }
  createExcel() {
    const headers = ["Nombre Comercial","Razón Social","R.F.C.","País" ]
    const excelObj = {
      title:'Catalogo',
      header: headers,
      data: this.createData(this.clientes),
      isClient: true
    }
    this._ikeService.createTable(excelObj)
  }

  createData(array) {
    const dataArray = []
    array.forEach(row => {
      const newRow = [row['nombre-comercial'],row['razon-social'], row.rfc, row.pais ]
      dataArray.push(newRow)
    });
    return dataArray
  }

  eliminar(EliminarFormContent, data) {
    this.userCliente = data;
    this.modalService.open(EliminarFormContent, { windowClass: 'animated fadeInDown' });
  }

  editar(EditarFormContent,data) {
    this.modalService.open(EditarFormContent, { windowClass: 'animated fadeInDown' });
    
    console.log('data',data)
    setTimeout(() => {
      if((<HTMLInputElement>document.getElementById("pais"))) {
        this.dataClient = data;
        
        (<HTMLInputElement>document.getElementById("pais")).value = data["pais"];
        (<HTMLInputElement>document.getElementById("nombre")).value = data["nombre-comercial"];
        (<HTMLInputElement>document.getElementById("razon")).value = data["razon-social"];
        (<HTMLInputElement>document.getElementById("rfc")).value = data["rfc"];
        this.empresaActual = data["empresa"]
          let paisSelected = this.removeAccents(data['pais'].toLowerCase())
        this.clientesArr = this.clientesObj[paisSelected] 
        console.log(paisSelected)   
      }
    }, 1000);
    this.f.empresa.setValue(data["empresa"])
  }

  editarClientes(){
    if(
      (<HTMLInputElement>document.getElementById("nombre")).value !="" &&
      (<HTMLInputElement>document.getElementById("razon")).value !="" &&
      (<HTMLInputElement>document.getElementById("rfc")).value !="" &&
      (<HTMLInputElement>document.getElementById("pais")).value !=""
    ){
      
        const data = {
        "nombre-comercial": (<HTMLInputElement>document.getElementById("nombre")).value,
        "razon-social": (<HTMLInputElement>document.getElementById("razon")).value,
        "rfc": (<HTMLInputElement>document.getElementById("rfc")).value,
        "pais": (<HTMLInputElement>document.getElementById("pais")).value,
        "item_id": this.dataClient["item_id"],
        "empresa": (<HTMLInputElement>document.getElementById("clientesSelect")).value
      }
      console.log(data);
      this.service.editClient(data).subscribe(
        data => {
          this.modalService.dismissAll()
        }
      )
    }
  }


  crearCliente(LoaderEnviarContent) {
    let nombre = (<HTMLInputElement>document.getElementById("nombre")).value;
    let razon = (<HTMLInputElement>document.getElementById("razon")).value;
    let rfc = (<HTMLInputElement>document.getElementById("rfc")).value;
    let pais = (<HTMLInputElement>document.getElementById("pais")).value;
    let empresa = (<HTMLInputElement>document.getElementById("clientesSelect")).value;
    if(nombre!=null && razon!=null && razon!=null && pais!=null){
      const data = {
        "nombre-comercial": nombre,
        "razon-social": razon,
        "rfc": rfc,
        "pais": pais,
        "empresa": empresa
      };    
      this.service.crearCliente(data).subscribe(
        (data) => {
          this.modalService.dismissAll();
          this.modalService.open(LoaderEnviarContent, { windowClass: 'animated fadeInDown' });
          this.formularioNuevoClientes.reset()
        },
        (err) => {
          console.log("Error en la peticion");
        }
      );
    }else{
      console.log("faltan datos")
    }
  }

  borrarCliente(modal, row){
    const data = { "item_id": this.userCliente['item_id'] }
    this.service.deleteCliente(data).subscribe(
      data => {
        console.log(data);
        this.modalService.dismissAll()
        window.location.reload()
      },
      err => {
        console.log("Error en la peticion", err);
        this.Mensaje = "Error al consumir la petición"
        this.modalService.open(modal, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
     )
  }

}
