<div class="app-content content">
  <div class="content-wrapper p-0">
    <div class="content-body px-1">
      <section id="minimal-statistics">
        <!-- Aqui inicia el div blanco(general)-->
        <div class="container-lg col-12 py-2">
          <!-- Basic tabs start -->
          <section id="basic-tabs-components">
            <div class="col-12 p-0">
              <div>
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <ngb-tabset>
                        <!-- <div class="nav nav-tabs"> -->
                        <ngb-tab>
                          <!--  <span class="font-medium-3"></span>Detalles</span> -->
                          <ng-template ngbTabTitle>
                            <span class="tab-title">Detalles</span>
                          </ng-template>
                          <ng-template ngbTabContent>
                            <div class="content-body scroll height-450">
                              <div class="">
                                <div class="col-lg-12 col-md-12 border-grey" style="margin-top: 1.5em">
                                  <label class="padding"><strong>Folio: </strong>{{ detalles_solicitud['folio']
                                    }}</label>
                                </div>
                              </div>
                              <div class="">
                                <div class="col-lg-12 col-md-12 border-grey">
                                  <label class="padding"><strong>Fecha Folio: </strong>{{ detalles_solicitud['fecha']
                                    }}</label>
                                </div>
                              </div>
                              <div class="">
                                <div class="col-lg-12 col-md-12 border-grey">
                                  <label class="padding"><strong>Tipo: </strong>{{
                                    detalles_solicitud['tipo-de-solicitud-2'] }}</label>
                                </div>
                              </div>
                              <div class="">
                                <div class=" col-lg-12 col-md-12 border-grey">
                                  <label class="padding"><strong>Nombre de la Empresa: </strong>{{
                                    detalles_solicitud['cliente'] }}</label>
                                </div>
                              </div>
                              <div class="">
                                <div class="col-lg-12 col-md-12 border-grey">
                                  <label class="padding"><strong>Razón Social: </strong>{{
                                    detalles_solicitud['ciiente-o-razon-social']['title'] }}</label>
                                </div>
                              </div>
                              <div class="">
                                <div class="col-lg-12 col-md-12 border-grey">
                                  <label class="padding"><strong>Contraprestación: </strong>{{
                                    removeSimbolo(detalles_solicitud['contraprestacion-2'] |
                                    currency:'CAD':'symbol-narrow':'.2-2')}} {{detalles_solicitud['contraprestacion-2-currency']}}</label>
                                </div>
                              </div>
                              <div class="">
                                <div class="col-lg-12 col-md-12 border-grey">
                                  <label class="padding"><strong>Vigencia: </strong>{{ detalles_solicitud['vigencia-2']
                                    }}</label>
                                </div>
                              </div>
                              <div class="">
                                <div class=" col-lg-12 col-md-12 border-grey">
                                  <label class="padding"><strong>Instrucciones: </strong>{{
                                    detalles_solicitud['instrucciones-de-solicitud'] }}</label>
                                </div>
                              </div>
                              <div *ngIf="detalles_solicitud['terminos-y-condiciones']!=undefined">
                                <div class=" col-lg-12 col-md-12 border-grey">
                                                                                    <label class="padding" ><strong>Archivo de Términos y Condiciones: </strong>
                                                  <a target="_blank" download [href]="detalles_solicitud['terminos-y-condiciones']">
                                                    {{ detalles_solicitud['terminos-y-condiciones'].slice(57) }}
                                                  </a>
                                                </label>
                                </div>
                             </div>
                              <div class="">
                                <div class=" col-lg-12 col-md-12 border-grey">
                                  <label class="padding"><strong>Revenue Share: </strong>{{
                                    detalles_solicitud['revenue-share'] }}</label>
                                </div>
                              </div>
                              <div *ngIf="detalles_solicitud['revenue-share'] == 'Si'">
                                <div class="">
                                  <div class=" col-lg-12 col-md-12 border-grey">
                                    <label class="padding"><strong> Porcentaje de la Empresa: </strong>{{
                                      detalles_solicitud['porcentaje-empresa'] }}</label>
                                  </div>
                                </div>
                                <div class="">
                                  <div class=" col-lg-12 col-md-12 border-grey">
                                    <label class="padding"><strong>Porcentaje del Cliente: </strong>{{
                                      detalles_solicitud['porcentaje-revenue-share'] }}</label>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </ng-template>
                        </ngb-tab>
                        <ngb-tab *ngIf="
                        (detalles_solicitud['tipo-de-solicitud-2']=='Contrato') || 
                        (detalles_solicitud['tipo-de-solicitud-2']=='Convenio de Confidencialidad')
                        ">
                          <ng-template ngbTabTitle>
                            <span class="tab-title">Documentación</span>
                          </ng-template>
                          <ng-template ngbTabContent>
                            <!--borderless table start-->
                            <div class="row">
                              <div class="col-12">
                                <div class="card mt-2">
                                  <div class="card-content">
                                    <div class="table-responsive">
                                      <table class="table table-borderless mb-0">
                                        <tbody>
                                          <tr class="border-grey">
                                            <td class="padding">Acta constitutiva<span class="red">*</span> : <u><a
                                                  href="{{detalles_solicitud['acta-constitutiva']}}">
                                                  {{detalles_solicitud['acta-constitutiva'].slice(57)}}</a></u> </td>
                                            <td class="doc padding"></td>
                                          </tr>
                                          <tr class="border-grey">
                                            <td class="padding">Poder del Rep. Legal<span class="red">*</span>: <u><a
                                                  href="{{detalles_solicitud['poder-del-rep-legal']}}">
                                                  {{detalles_solicitud['poder-del-rep-legal'].slice(57)}}</a></u></td>
                                            <td class="doc padding"></td>
                                          </tr>
                                          <tr class="border-grey">
                                            <td class="padding">INE/Pasaporte (identificación oficial)<span
                                                class="red">*</span>: <u><a
                                                  href="{{detalles_solicitud['identificacion-oficial']}}">
                                                  {{detalles_solicitud['identificacion-oficial'].slice(57)}}</a></u></td>
                                            <td class="doc padding"></td>
                                          </tr>
                                          <tr class="border-grey">
                                            <td class="padding">Comprobante de domicilio<span class="red">*</span>:
                                              <u><a href="{{detalles_solicitud['comprobante-de-domicilio']}}">
                                                {{detalles_solicitud['comprobante-de-domicilio'].slice(57)}}</a></u></td>
                                            <td class="doc padding"></td>
                                          </tr>
                                          <tr class="border-grey">
                                            <td class="padding">R.F.C.<span class="red">*</span>: <u><a
                                                  href="{{detalles_solicitud['rfc']}}">{{detalles_solicitud['rfc'].slice(57)}}</a></u></td>
                                            <td class="doc padding"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <form action="" id="compose-form" [formGroup]="formularioMotivo">
                                        <div class="form-group" *ngIf="submitRechazar">
                                        <label for="donationinput7" class="sr-only">Motivo</label>
                                        <textarea [ngClass]="{ 'is-invalid': submitRechazar && formMotivo.motivo.errors }" id="donationinput7" rows="5" class="form-control square"
                                          formControlName="motivo" placeholder="Motivo de rechazo." required></textarea>
                                      </div>

                                      <div class="form-actions text-center" *ngIf="detalles_solicitud['accion']!='Aprobar Solicitud'">
                                        <button *ngIf="!submitRechazar" (click)="actualizar('Rechazar')" type="button"
                                          class="btn btn-danger mt-2 mr-1 ml-1">
                                          <i class="feather ft-x"></i> Rechazar
                                        </button>
                                        <button *ngIf="submitRechazar" (click)="actualizar('Cancelar')" type="button"
                                          class="btn btn-danger mt-2 mr-1 ml-1">
                                          <i class="feather ft-x"></i> Cancelar
                                        </button>
                                        <button *ngIf="submitRechazar" (click)="actualizar('Rechazar')" type="button"
                                          class="btn btn-info mt-2 ml-2">
                                          <i class="la la-check"></i> Confirmar
                                        </button>                                        
                                        <button *ngIf="!submitRechazar" (click)="actualizar('Aprobar')" type="button"
                                          class="btn btn-info mt-2 ml-2">
                                          <i class="la la-check"></i> Aceptar
                                        </button>
                                      </div>
                                    </form> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Borderless table end -->
                          </ng-template>
                        </ngb-tab>
                        <ngb-tab
                          *ngIf="((detalles_solicitud['tipo-de-solicitud-2']=='Contrato' || 
                          detalles_solicitud['tipo-de-solicitud-2']=='Convenio de Confidencialidad') 
                          && detalles_solicitud['accion']=='Aprobar Solicitud') || ((detalles_solicitud['tipo-de-solicitud-2']!='Contrato' || 
                          detalles_solicitud['tipo-de-solicitud-2']=='Convenio de Confidencialidad') && detalles_solicitud['abogado']!=undefined)">
                          <ng-template ngbTabTitle>
                            <span class="tab-title">Revisiones</span>
                          </ng-template>
                          <ng-template ngbTabContent>
                            <section id="basic-form-layouts">
                               <div class="row h-100 p-2 email-application">
                                   <div class="w-100">
                           <div class="app-content content w-100">
                               <div class="sidebar-left" id="sidebar-left">
                                 <div class="sidebar">
                                   <div class="sidebar-content email-app-sidebar d-flex">
                                     <!-- sidebar close icon -->
                                     <span class="sidebar-close-icon" (click)="showSidebar($event)">
                                       <i class="ficon feather ft-x"></i>
                                     </span>
                                     <!-- sidebar close icon -->
                                     <div class="email-app-menu">
                                       <div class="form-group form-group-compose">
                                         <!-- compose button  -->
                                         <button type="button" class="btn btn-info btn-glow btn-block my-2 compose-btn" id="compose-btn"
                                           (click)="showComposeSidebar($event)">
                                           <i class="ficon feather ficon feather ft-plus"></i>
                                           Nueva Revisión
                                         </button>
                                       </div>
                                       <div class="sidebar-menu-list" fxFlex="auto">
                                                       <!-- sidebar menu  -->
                                                       <article class="col-12 px-0">
                                                        <div class="row">
                                                          <div class="col-12 p-0">
                                                            <div class="container d-flex flex-column mt-2">
                                                              <div class="d-flex flex-column justify-content-center text-center align-items-center ">
                                                                                                      
                                                                <div class="documento">
                                                                  <img src="../../../../assets/archivo-azul.svg" alt="documento " height="40" width="40" >
                                                                </div>
                                                                <div class="ml-1 col-12 px-0">
                                                                  <p class="mb-0" ><a download href="{{doc_final['archivo']}}">{{ doc_final['nombre-archivo']}}</a></p>
                                                                  <label class="mb-0">{{doc_final['fecha']}}</label>
                                                                </div>                                                         
                                                             </div>
                                                            </div>
                                                            <div class="list-group-messages mt-4">
                                                                <div class="documento">
                                                                 <p class="text-center text-black">VoBo </p>
                                                               </div>
                                                               <ul class="list-group">
                                                                   
                                                                   <li class="list-group-item">
                                                                    <input type="checkbox" id="colorCheck2"
                                                                    [ngClass]="detalles_solicitud['abogado-2']=='Si'?'check':''" (change)="validar(DefaultModelContent)"><label
                                                                    for="colorCheck2">Analista Jurídico{{consulta()}}</label>                           </li>
                                                                   <li class="list-group-item">
                                                                    <input type="checkbox" id="colorCheck1"><label
                                                                    for="colorCheck1">Comercial</label>                        </li>
                                                                 </ul><!-- aqui va el nuevo chat -->
                                                            </div>
                                                            
                                                          </div>
                                                        </div>
                                                      </article>
                                       </div>
                                     </div>
                                   </div>
                                   <!-- User new mail right area -->
                                   <div class="compose-new-mail-sidebar" id="compose-sidebar" fxFlex="auto">
                                     <div class="card mb-0 shadow-none quill-wrapper p-0">
                                       <div class="card-header">
                                         <h3 class="card-title" id="emailCompose">Nueva Revision</h3>
                                         <button type="button" class="close close-icon" id="showCompose" (click)="showCompose($event)">
                                           <i class="ficon feather ft-x"></i>
                                         </button>
                                       </div>
                                       <!-- form start -->
                                       <form action="" id="compose-form" [formGroup]="newRevisionForm" (ngSubmit)="CrearRevisiones(RevisionContent)">
                                         <div class="card-content">
                                           <div class="card-body pt-0">
                                             <div class="form-group pb-50">
                                               <label for="emailfrom">De</label>
                                               <input type="text" id="emailfrom" class="form-control" placeholder="{{user}}" disabled>
                                             </div>
                                             <div class="form-label-group mb-1">
                                               <textarea  [ngClass]="{ 'is-invalid': submitted && fNewComment.comment.errors }" formControlName="comment" class="form-control" placeholder="Comentario de la revision" rows=10 required></textarea>
                                             </div>
                                             <div  *ngIf="submitted && fNewComment.comment.errors " class="ml-2 invalid-feedback">
                                               <div *ngIf="fNewComment.comment.errors?.required" >Este campo es requerido*</div>
                                           </div>
                                           
                                             <div class="form-group mt-2">
                                               <div class="custom-file">
                                                 <input  formControlName="file" type="file" class="custom-file-input" id="emailAttach" (change)="onFileSelect($event)">
                                                 <label *ngIf="fNewComment.file.value!=''" class="custom-file-label" for="emailAttach">{{fNewComment.file.value.slice(12)}}</label>
                                                 <label *ngIf="fNewComment.file.value==''" class="custom-file-label" for="emailAttach">Cargar Archivo</label>

                                               </div>
                                             </div>
                                           </div>
                                         </div>
                                         <div class="card-footer border-0 d-flex justify-content-end pt-0">
                                           <button type="reset" class="btn btn-danger cancel-btn mr-1" (click)="showCompose($event)">
                                             <i class='ficon feather ft-x mr-25'></i>
                                             <span class="d-sm-inline d-none">Cancelar</span>
                                           </button>
                                           <button type="submit" class="btn-send btn btn-info btn-glow">
                                             <i class='ficon feather ft-play mr-25'></i>
                                             <span class="d-sm-inline d-none">Enviar</span>
                                           </button>
                                         </div>
                                       </form>
                                       <!-- form start end-->
                                     </div>
                                   </div>
                                   <!--/ User Chat profile right area -->
                                 </div>
                               </div>
                               <div class="content-right">
                                 <div class="content-header row">
                                 </div>
                                 <div class="content-overlay"></div>
                                 <div class="content-wrapper">
                                   <div class="content-body">
                                     <!-- email app overlay -->
                                     <div class="app-content-overlay" id="app-content-overlay" (click)="showCompose($event)"
                                       (click)="showSidebar($event)"></div>
                                     <div class="email-app-area">
                                       <!-- Email list Area -->
                                       <div class="email-app-list-wrapper">
                                         <div class="email-app-list">
                                           <div class="email-action">
                                             <h3 class="text-center w-100">Historial de Revisiones</h3>
                                           </div>
                                           <div class="email-user-list scroll list-group">
                                             <ul class="media-list">
                                               <li *ngFor="let data of conversacion; index as i" [ngClass]="data['usuario']['title']!=user?'bg-gray':''" class="media align-items-center py-2">
                                                 <div [ngClass]="data['usuario']['title']!=user?'flex-row-reverse':''" class="media-body d-flex align-items-center">
                                                   <div class="chat-item__avatar d-inline-block col-2">
                                                     <div class="d-flex justify-content-center">
                                                       <div class="avatar">
                                                         <img src="../../../../assets/usuario-chat.svg" alt="avtar img holder">
                                                       </div>
                                                     </div>
                                                     <div class=" text-center">
                                                       <div class="mail-items">
                                                         <span class="list-group-item-text">{{data['usuario']['title']}}</span>
                                                       </div>
                                                     </div>
                                                     <div class=" text-center">
                                                       <p class="list-group-item-text truncate mb-0">
                                                           {{ data['fecha']}}
                                                       </p>
                                                     </div>
                                                   </div>
                                                   <div class="col-8 chat-item__comment d-inline-block">
                                                       {{ data['titulo']}}
                                                   </div>
                                                   <div class="chat-item__file col-2 px-0">
                                                     <div class="col-md-12 col-sd-12 d-flex justify-content-center">
                                                       <img src="../../../../assets/archivo-azul.svg" alt="documento " height="45" width="43">
                                                     </div>
                                                     <div class="col-md-12 col-sd-12">
                                                       <p><a download href="{{data['archivo']}}" class="text-info">{{data['nombre-archivo']}}</a></p>
                                                     </div>
                                                   </div>
                                                 </div>
                                               </li>
                                             </ul>
                                             <!-- email user list end -->
                             
                                             <!-- no result when nothing to show on list -->
                                             <div class="no-results">
                                               <i class="ficon feather ft-info font-large-2"></i>
                                               <h5>No Items Found</h5>
                                             </div>
                                           </div>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           
                                   </div>
                               </div>
                            </section>
                           </ng-template>
                        </ngb-tab>

                        <ngb-tab *ngIf="detalles_solicitud['comercial']=='Si' && detalles_solicitud['abogado-2']=='Si'">
                          <ng-template ngbTabTitle>
                            <span class="tab-title">Firmas</span>
                          </ng-template>
                          <ng-template ngbTabContent>
                            <section id="basic-form-layouts">
                              <div class="row" class="borde mt-2" matchHeight="card">
                                <div class="col-md-12 text-center mb-2 " *blockUI="'projectInfo'; message: 'Loading'">
                                  <div *ngIf="detalles_solicitud['archivo-firma']==undefined && listaFirmantes==null">
                                    <div *ngIf="!firmCheck1 && !firmCheck2">
                                      <label class="mt-2 ml-2">Tipo de firma</label>
                                      <br>
                                      <div class="d-inline-block  ml-2 mt-2">
                                        <input [(ngModel)]="firmCheck1" type="checkbox" id="colorCheck1"
                                          class="ml-2"><label for="colorCheck1">Firma Manual</label>
                                      </div>
                                      <div class="d-inline-block ml-2 mt-2">
                                        <input [(ngModel)]="firmCheck2" type="checkbox" id="colorCheck2"
                                          class="ml-2"><label for="colorCheck2">Firma Electrónica</label>
                                      </div>
                                    </div>
                                    <!-- FLUJO DE FIRMAS -->
                                    <div *ngIf="(firmCheck1 || firmCheck2) && !isUpload" class="col-12 mt-1 px-2">
                                      <p *ngIf="firmCheck1">Firma Manual</p>
                                      <p *ngIf="!firmCheck1">Firma Electrónica</p>
                                      <!-- Esto es para firma electronica, firma electronica no lleva la opcion de marca de agua -->
                                    </div>
                                  </div>
                                  <div *ngIf="!isUpload && detalles_solicitud['archivo-firma']==undefined"
                                    class="col-12">
                                    <div class="col-12">
                                      <div class="text-center mt-2">
                                        <div class="form-group" *ngIf="firmCheck1 || (listaFirmantes)">
                                          <label>Seleccionar Archivo</label>
                                          <label id="projectinput7" class="file center-block ml-1">
                                            <input type="file" id="firma" class="form-control"
                                              (change)="onFileFirma($event)">
                                            <span class="file-custom"></span>
                                          </label>
                                        </div>
                                        <small class="form-text text-muted danger"
                                          *ngIf="submitted && f.selectFile.errors" class="invalid-feedback">
                                          <div *ngIf="f.selectFile.errors.required">Archivo Requerido*</div>
                                        </small>
                                      </div>

                                      <div *ngIf="firmCheck1">
                                        <div class="d-inline-block  mb-2">
                                          <div class="documento">
                                            <input type="checkbox" class=" ml-2" name="colorCheck" id="colorCheck2">

                                            <label class="" for="colorCheck2">Marca de Agua</label>
                                          </div>
                                        </div>
                                      </div>
                                      <div *ngIf="firmCheck2 && listaFirmantes==null">
                                        <div class="documento mb-2">
                                          <button type="button" (click)="AgregarFirmantes(FirmantesContent)"
                                            class="d-block font-medium-2 text-bold-700 btn btn-info px-3 mrgl">
                                            Agregar Firmantes
                                          </button>
                                        </div>
                                      </div>
                                      <div *ngIf="firmCheck2 && listaFirmantes==null">
                                        <div class="documento mb-2">
                                          <button type="button" (click)="guardarFirmantes(DefaultModelContent)"
                                            class="d-block font-medium-2 text-bold-700 btn btn-info px-3 mrgl">
                                            Guardar
                                          </button>
                                        </div>
                                      </div>
                                      <div *ngIf="firmCheck1 || (linkDocusing==null && listaFirmantes!=null)"
                                      type="button" (click)="uploadClick(DefaultModelContent)">
                                      <div class="documento mb-2">
                                        <button class="d-block font-medium-2 text-bold-700 btn btn-info px-3 mrgl">
                                          Subir
                                        </button>
                                      </div>
                                    </div>
                                      <div *ngIf="linkDocusing!=null">
                                        <div class="documento mb-2">
                                          <a target="_blank"
                                            href="https://asistencia-ike.esign.admonlegal.com:8443/autenticacion">
                                            <button type="button"
                                              class="d-block font-medium-2 text-bold-700 btn btn-info px-3 mrgl">
                                              Autenticarse
                                            </button>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="agregarFirmantes!=null" class="col-6 d-inline-block">
                                      <div class="mb-5 mrg-top-1">
                                        <div class="table-responsive">
                                          <table class="table-sm  table-striped">
                                            <thead>
                                              <tr>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Correo</th>
                                                <th scope="col">Codigo Firma</th>
                                                <th scope="col">Acciones</th>
                                                <th></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let data of agregarFirmantes; let i = index">
                                                <td>{{data["titulo"]}}</td>
                                                <td>{{data["correo"]}}</td>
                                                <td>{{data["id"]}}</td>
                                                <td>
                                                  <button style="transform: translateY(-15px);"
                                                    class="btn btn-lg text-info">
                                                    <i class="feather ft-trash " (click)="Borrar(i)"></i>
                                                  </button>
                                                  <button style="transform: translateY(-15px);"
                                                    class="btn btn-lg text-info">
                                                    <i class="feather ft-edit "
                                                      (click)="EditarFirmantes(EditarFirmantesContent,data,i)"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div *ngIf="detalles_solicitud['archivo-firma']!=undefined"
                                    class="mt-1 col-12 size firm">
                                    <label class="mt-1 mb-2 titulo"> Archivo a firmar</label>
                                    <img class="note1 mb-2 img-fluid rounded mx-auto d-block"
                                      src="../../../../assets/images/logo/firmado.svg" alt="documento " height="40"
                                      width="40">
                                    <div class="row documento mt-1">
                                      <label class="doc"><a
                                          href="{{detalles_solicitud['archivo-firma']}}">{{detalles_solicitud['nombre-archivo-firma']}}</a></label>
                                    </div>

                                    <div class="row justify-content-center pl-2 mb-3 ">
                                      <div class="d-inline-block mx-3 ">
                                        <input disabled type="checkbox" class="" name="comercial" id="comercial">
                                        <label class="" for="comercial">Comercial</label>
                                      </div>

                                      <div class="d-inline-block mx-3">
                                        <div class=" d-inline-block mx-3">
                                          <input type="checkbox" id="abogado"
                                            (change)="validarFirma(DefaultModelContent)">
                                          <label for="abogado">Analista Jurídico{{consultaFirma()}}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </ng-template>
                        </ngb-tab>

                        <ngb-tab
                          *ngIf="detalles_solicitud['comercial-firma']=='Si' && detalles_solicitud['abogado-firma']=='Si'">
                          <ng-template ngbTabTitle>
                            <span class="tab-title">Documentación Final</span>
                          </ng-template>
                          <ng-template ngbTabContent>
                            <!-- Basic form layout section start -->
                            <section id="basic-form-layouts" class="borde mt-2">
                              <div class="row ">
                                <div class="col-md-12 mt-1 mb-1">
                                  <div *ngIf="detalles_solicitud['archivo-final']==undefined"
                                    class="form-group text-center">


                                    <div class="form-group">
                                      <label style="margin-top: 1em">Seleccionar Archivo</label>
                                      <label id="projectinput7" class="file center-block ml-1">
                                        <input type="file" id="file" class="form-control"
                                          style="margin-left: 3px; margin-top: 1.5em" (change)="onDocumentLast($event)">
                                        <span class="file-custom"></span>
                                      </label>
                                    </div>

                                    <small class="form-text text-muted danger" *ngIf="submitted && f.archivo.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.archivo.errors.required">Archivo Requerido*</div>
                                    </small>
                                    <button type="button" (click)="subirDocumentoFinal()"
                                      class="btn border size btn-info img-fluid rounded mx-auto d-block">
                                      Subir
                                    </button>
                                  </div>
                                  <div *ngIf="detalles_solicitud['archivo-final']!=undefined" class=" size col-12">
                                    <label class="mb-2 titulo"> Archivo Firmado</label>
                                    <img class="note1 mb-2  img-fluid rounded mx-auto d-block"
                                      src="../../../../assets/images/logo/final.svg" alt="documento " height="40"
                                      width="40">
                                    <label class="informacion ">{{detalles_solicitud['fecha-archivo-final-2']}}</label>
                                    <div class="row documento  mb-2">
                                      <label class="informacion doc"><a
                                          href="{{detalles_solicitud['archivo-final']}}">{{detalles_solicitud['nombre-archivo-final']}}</a></label>
                                    </div>
                                    <div class="row justify-content-center"
                                      *ngIf="detalles_solicitud['estatus-2']!='Resguardo'">
                                      <button type="button" (click)="resguardarDocumento()"
                                        class="btn mb-3 ml-1 note btn-info">
                                        Aceptar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </ng-template>

                        </ngb-tab>

                        <!-- </div> -->
                      </ngb-tabset>
                    </div>
                  </div>
                </div>
                <div class="alert alert-info text-white text-center text-align-items-center" *ngIf="submitted">
                  <i class="la la-coffee"></i> Cargando Archivos, este proceso puede tardar varios minutos... <i class="la la-coffee"></i>
              </div>
              </div>

            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</div>


<!-- Modal para tab Revisiones (no seleccionó archivo o comentarios) -->
<section id="modal-sizes">
  <div class="col-12">
    <div class="row my-2">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group">
          <!-- Modal -->
          <ng-template class="modal text-left" #RevisionContent let-c="close" let-d="dismiss">
            <div class="modal-sm">
              <div class="modal-header">
                <h5 id="myModalLabel21">Error al enviar</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body icon-alert mt-3">
                <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60">
              </div>
              <h4 class="icon-alert mb-3 ml-1">Ningún archivo seleccionado
                o comentario vacío</h4>

              <div class="modal-footer">
                <button type="button" class="btn btn-info btn-outline-secondary"
                  (click)="d('Close modal')">Aceptar</button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal para tab loader -->
<section id="modal-sizes">
  <div class="col-12">
    <div class="row my-2">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group">
          <!-- Modal -->
          <ng-template class="modal text-left" #LoaderEnviarContent let-c="close" let-d="dismiss">
            <div class="modal-sm">
              <div class="modal-header">
                <h5 id="myModalLabel21">Enviando Mensaje</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="card-content">
                <div class="card-body text-center mt-1">
                  <h4>Su mensaje se ha enviado correctamente</h4>
                  <div class="loader-wrapper">
                    <div class="loader-container">
                      <div class="ball-pulse loader-blue">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-info btn-outline-secondary" (click)="refrescar()">Aceptar</button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- Modal para firmante -->
<section id="modal-sizes">
  <div class="col-12">
    <div class="row my-2">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group">
          <!-- Modal -->
          <ng-template class="modal text-left" #FirmantesContent let-c="close" let-d="dismiss">
            <div class="modal-sm">
              <div class="modal-header">
                <h5 id="myModalLabel21">Agregar Firma</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <section class="basic-inputs">
                  <label class="ml-2">Nombre</label>
                  <div class="card-block">
                    <div class="card-body mrg-top">
                      <div class="form-group">
                        <input type="text" class="form-control" id="nombreFirmante">
                      </div>
                    </div>
                  </div>
                </section>
                <section class="basic-inputs">
                  <label class="ml-2">Correo</label>
                  <div class="card-block">
                    <div class="card-body mrg-top">
                      <div class="form-group">
                        <input type="text" class="form-control" id="correoFirmante">
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="modal-footer">
                <button (click)="save()" type="button" class="btn btn-info btn-outline-secondary">Guardar</button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

  </div>

</section>
<!-- Modal para editar firmante -->
<section id="modal-sizes">
  <div class="col-12">
    <div class="row my-2">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group">
          <!-- Modal -->
          <ng-template class="modal text-left" #EditarFirmantesContent let-c="close" let-d="dismiss">
            <div class="modal-sm">
              <div class="modal-header">
                <h5 id="myModalLabel21">Editar Firma</h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <section class="basic-inputs">
                  <label class="ml-2">Nombre</label>
                  <div class="card-block">
                    <div class="card-body mrg-top">
                      <div class="form-group">
                        <input type="text" class="form-control" id="nombreFirmante">
                      </div>
                    </div>
                  </div>
                </section>
                <section class="basic-inputs">
                  <label class="ml-2">Correo</label>
                  <div class="card-block">
                    <div class="card-body mrg-top">
                      <div class="form-group">
                        <input type="text" class="form-control" id="correoFirmante">
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="modal-footer">
                <button (click)="save()" type="button" class="btn btn-info btn-outline-secondary">Guardar</button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

  </div>

</section>

<!-- Modal para errores -->
<ng-template #DefaultModelContent let-c="close" let-d="dismiss" id="myModalLabel1">
  <div class="modal-header">
    <h4 class="modal-title">Algo salió mal, inténtalo nuevamente</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="modal-body icon-alert mt-3">
      <img src="../../assets/custom/advertencia.svg" alt="alerta" width="60">
    </div>
    <h4 class="icon-alert mb-3">{{Mensaje}}</h4>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="d('Close modal')">Aceptar</button>
  </div>
</ng-template>