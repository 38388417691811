<!-- fixed-top-->
<nav
  class="top-header header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow navbar-static-top navbar-light navbar-brand-center"
  id="top-header" [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header">
      <ul class="mt-1 nav navbar-nav flex-row">
        <li class="nav-item mobile-menu d-md-none mr-auto" ><a
            class="nav-link nav-menu-main menu-toggle hidden-xs11" (click)="toggleNavigation($event)"><i
              class="feather ft-menu font-large-1" ></i></a></li>

        <li class="nav-item d-md-none"><a class="nav-link open-navbar-container" data-toggle="collapse"
            data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i class="la la-ellipsis-v"></i></a></li>
      </ul>
    </div>
    <div class="navbar-container content">
      <div class="collapse navbar-collapse show row align-items-center" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto col-2 pl-2">
          <li class="nav-item d-none d-md-block"><a [routerLink]="" class="mt-1 nav-link nav-menu-main menu-toggle hidden-xs"><img src="../../../../assets/custom/ike.png" height="40" width="auto" class="logo-ike"> </a></li>
         
          
        </ul>
        <ul class="nav navbar-nav mr-auto col-8 px-0">
          <li class="nav-item navbar-brand w-100">
            <h5 class="text-center fontz mb-0">Administración de Documentación Legal</h5>
          </li>
        </ul>
        <ul class="nav navbar-nav col-2 px-0 justify-content-end pr-2">
          <li class="dropdown-user nav-item" ngbDropdown>
            <a class="nav-link dropdown-user-link p-0" ngbDropdownToggle>

              <div class="d-flex align-items-center">
                <div class="d-flex flex-column mt-1">
                  <p *ngIf="currentUser" class="mr-1 user-name text-bold-700">{{currentUser}}</p>
                  <p *ngIf="!currentUser" class="mr-1 user-name text-bold-700">{{currentUser}}</p>
                  <p *ngIf="currentUser.displayName" class="mr-1 rol-name text-bold-700">{{rolUser}}</p>
                  <p *ngIf="!currentUser.displayName" class="mr-1 rol-name text-bold-200 text-right">{{rolUser}}</p>
                </div>
                <div class="d-flex align-items-center">
                  <span class="avatar avatar-online">
                    <img src="../../../../assets/custom/profile.svg "
                      alt="avatar">
                    <i></i>
                  </span>
                </div>
              </div>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-top " aria-labelledby="dropdownProfileMenu">
              <!-- <a class="dropdown-item" [routerLink]="['/chats']"><i class="feather ft-message-square"></i> Chats </a>
              <div class="dropdown-divider"></div> -->
              <a class="dropdown-item logout" (click)="logout()"><i class="feather ft-power"></i>Cerrar Sesión</a>
              <!-- COLOCAR EL LINK DEL VIDEO DENTRO DEL HREF -->
              <a class="dropdown-item logout" href="{{link}}"><i class="feather ft-help-circle"></i>Ayuda</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
