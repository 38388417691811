import { Component, OnInit, Renderer2 } from '@angular/core';
import { EmailChatComponent } from './email-chat/email-chat.component'

@Component({
  selector: 'app-new-tab',
  templateUrl: './new-tab.component.html',
  styleUrls: ['./new-tab.component.css']
})
export class NewTabComponent implements OnInit {

  constructor(  
  ) { }

  ngOnInit(): void {
  }



}
