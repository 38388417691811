<div class="app-content content">
    <div class="content-wrapper p-0">
        <div class="content-body px-1">
            <section id="minimal-statistics">
                <!-- Aqui inicia el div blanco(general)-->
                <div class="container-lg col-12 py-2">
                    <!-- Basic tabs start -->
                        <section id="basic-tabs-components">
                            <div class="col-12 p-0" >
                            <div >
                                <div class="card">
                                <div class="card-content">
                                    <div class="card-body">
                                    <ngb-tabset>
                                        <!-- <div class="nav nav-tabs"> -->
                                        <ngb-tab>
                                        <ng-template ngbTabTitle>
                                          <span class="tab-title">Revisiones</span>
                                        </ng-template>
                                        <ng-template ngbTabContent>
                                           <!-- Basic form layout section start -->
                                          <section id="basic-form-layouts">
                                            <div class="row h-100 p-2 email-application">
                                                <div class="w-100">
                                                    <app-email-chat></app-email-chat>
                                                </div>
                                            </div>
                                          </section>
                                        </ng-template>
                                        </ngb-tab>  
                                        <!-- </div> -->
                                    </ngb-tabset>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </section>
                </div>
            </section>
        </div>
    </div>
</div>









