import { Component, OnInit, ViewChild  } from "@angular/core";
import { ApiService } from "../../../_services/api.service";
import { Router } from "@angular/router";
import { IkeService } from "../../services/ike.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComercialComponent implements OnInit {
  BasictableData: any;
  countries: any;
  solicitud: any = {
    total: 0,
    filtered: 0,
    items: []
  };
  items: any;
  Mensaje: any;
  validateName: any
  validateSocial: any
  validateRFC: any
  master:any;
  filter = null;

  page: any = 1;
  limit: any = 20;
  elements: any = 20;
  paginations: any = 0;

   
  @BlockUI('basicModals') blockUIBasicModals: NgBlockUI;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  
  @ViewChild('DefaultModelContent') contenidoDelModal;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private service: ApiService,
    //private _excelService: TableexcelService,
    private _ikeService: IkeService
  ) {
    localStorage.getItem("detalles_solicitud") != null
      ? localStorage.removeItem("detalles_solicitud")
      : "";
      this.getSolicitudes();
  }

  getSolicitudes(){
    const offset = (this.page-1)*this.elements;
    this.service.solicitudes(this.elements,offset).subscribe(
      (data: any) => {
        this.solicitud = data;
        this.items = data.items;
        this.paginations = data.filtered / this.elements;
        this.master=data;
      },
      (err) => {
        console.log("Error en la peticion");
      }
    );
  }

  changePage(event){
    this.getSolicitudes();
  }
  
  changeSizePage(event){
    this.page = 1;
    this.limit = event.target.value;
    this.getSolicitudes();
  }

  quickFilter(e) {
    
    if( !e.target.value ){
      this.elements = 20;
      this.limit = 20;
      this.page = 1;

      this.items = this.master.items.map( x => { return x });
      this.solicitud = this.master;
      this.paginations = this.master.filtered / this.elements;
      return;
    }

    this.items = this.master.items.filter((x) =>
      
      x.usuario?.title?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x.abogado?.title?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x['ciiente-o-razon-social']?.title?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x['estatus-2'].toLowerCase()?.includes(e.target.value.toLowerCase()) ||
      x.cliente.toLowerCase()?.includes(e.target.value.toLowerCase())||
      x.folio.toLowerCase()?.includes(e.target.value.toLowerCase())
    
    );

    const size_text = e.target.value.length;
    if( this.items.length === 0 || size_text > 3){
      this.elements = 100;
      this.limit = 100;
      this.page = 1;
      const data = {"filter": e.target.value}
      this.service.filtroSolicitudesPage(data,500,0).subscribe(
        (response: any) =>{
          this.items = response.items;
          this.solicitud = response;
          this.paginations = response.filtered / this.elements;
        },
        err =>{
          console.log("Error al consumir petición")
        }
      );
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.crearCliente(this.contenidoDelModal)
      }, 500);
  }

  createExcel() {
    console.info(this.items)

    this.items.forEach(element => {
      if(!element['abogado']){
        element["abogado"] = {
          item_id: null,
          title: ""
        }
      }
    });

    const headers = [
      "Folio",
      "Fecha Folio",
      "Tipo",
      "Cliente",
      "Empresa",
      "Comercial",
      "Estatus",
      "Analista Jurídico"
    ];
    const excelObj = {
      title: "Solicitudes",
      header: headers,
      data: this.items,
      hasLawyer: false,
    };
    this._ikeService.createExcel(excelObj);
  }

  detalles(e) {
    if (e.type == "click") {
      this.router.navigate(["/comercial/tabs"]);
      localStorage.setItem("detalles_solicitud", JSON.stringify(e.row));
    }
  }

  crearCliente(DefaultModelContent) {    
    var nombre = (<HTMLInputElement>document.getElementById("nombre")).value;
    console.log(nombre)
    this.validateName = nombre == '' ? true : false
    var razon = (<HTMLInputElement>document.getElementById("razon")).value;
    console.log(razon)
    this.validateSocial = razon == '' ? true : false
    var rfc = (<HTMLInputElement>document.getElementById("rfc")).value;
    this.validateRFC = rfc == '' ? true : false
    const data = {
      "nombre-comercial": nombre,
      "razon-social": razon,
      rfc: rfc,
      pais: JSON.parse(localStorage.getItem("general"))["pais"],
    };

    if(nombre == "" || razon == "" || rfc == ""){
      return;
    }
    this.service.crearCliente(data).subscribe(
      (data) => {
        console.log("");
        this.modalService.dismissAll();
      },
      (err) => {
        console.log("Error en la peticion");
        this.Mensaje = "Error en la petición"
        this.modalService.open(DefaultModelContent, { windowClass: 'animated fadeInDown', size: 'sm' })
      }
    );
  }
}
